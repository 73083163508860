import React from "react";
import AccordionFooter from "./AccordionFooter";
import rplogo2 from "assets/img/rplogo2.png";
import facebook from "assets/img/facebook.png";
import instagram from "assets/img/instagram.png";
import instagram1 from "assets/img/instagram1.png";
import linkdin from "assets/img/linkedin.png";
import footerbg from "assets/img/footerbg.jpg";

export default function Footer2() {
  {
    const accordionData = [
      {
        title: "Useful Links",
        content: [
          <ul key="list">
            <li className="text-xs font py-2">
              <a href="/About3">About Us</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/Services2">Services</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Contact</a>
            </li>
            <li className="text-xs font py-2">
            <a href="/About3">Careers</a>
      </li>

            <li className="text-xs font py-2">
              <a href="/contact?">Privacy Policy</a>
            </li>

            <li className="text-xs font py-2">
              <a href="/contact?">Pricing </a>
            </li>

            <li className="text-xs font py-2">
              <a href="/contact?">Team</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/contact?">Terms Of Use</a>
            </li>
          </ul>,
        ],
      },

      {
        title: "Address",
        content: [
          <ul key="list">
            <li className="text-xs font py-2">
              <a href="/">Office Address: 42 Mamnoun Street, Saba Carpet and Antiques Store,UK</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Email Address: support.industrie@gmail.com</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Phone Number: +971 551 579 261 / +97 155 596 1659</a>
            </li>
           {/*} <li className="text-xs font py-2">
              <a href="/">Home</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Enquiry Us</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Contact Us</a>
            </li>*/}
          </ul>,
        ],
      },
    ];

    return (
      <>
       

        {/*TAB AND MOBILE VIEW*/}
        <footer className="bg-ivory-black pt-8 pb-6 mb-36 xl:hidden lg:hidden md:block sm:block block">
          <div className="xl:hidden lg:hidden md:block block sm:block ">
            <div className="app px-4">
              {accordionData.map((item, index) => (
                <AccordionFooter
                  key={index}
                  title={item.title}
                  content={item.content}
                />
              ))}
            </div>
          </div>
            <div className="justify-center flex mt-4">
             <p class="text-base text-white font-semibold">© 2024 Industrie. Designed By</p>
             <p class="text-base text-yellow font-semibold ml-2">RSTheme.</p>
            </div>
        </footer>

        <section className="xl:block lg:block md:hidden sm:hidden hidden grotesk">
          <div className="bg-black-overlay">
            <div
              style={{
                backgroundImage: `url(${footerbg})`,
                width: "100%",
                height: "520px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="container mx-auto xl:px-4 xl:pt-6 lg:px-4 lg:pt-6 ">
                <hr className="border-blue-50 "></hr>
                <div className="grid grid-cols-5 xl:pt-16 xl:pb-16 lg:pt-8 lg:pb-8">
                  <div>
                    <h1 class="xl:text-2xl font-bold text-white lg:text-2xl">
                      About Company
                    </h1>
                    <p class="xl:text-base font-semibold text-grey xl:pt-10 lg:text-base lg:pt-10">
                      It was popularised in the 1960s
                    </p>
                    <p class="xl:text-base font-semibold text-grey pt-1 lg:text-base ">
                      with the release of Letraset
                    </p>
                    <p class="xl:text-base font-semibold text-grey pt-1 lg:text-base">
                      sheets containing Lorem Ipsums.
                    </p>
                    <h2 class=" h3 xl:text-3xl font-bold text-yellow xl:pt-12 lg:text-3xl lg:pt-12">
                      Since 2007
                    </h2>
                  </div>

                  <div className="xl:ml-10 lg:ml-10">
                    <h1 class="xl:text-2xl font-bold text-white lg:text-2xl">
                      Useful Links
                    </h1>

                    <div>
                      <a href="/" class="relative inline-block group">
                        <p class="xl:text-base text-grey xl:pt-10  lg:text-base lg:pt-10 yellow-on-hover hover-translate">
                          About Us
                        </p>
                        <span class="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-500 group-hover:h-0.5 group-hover:w-full transition-all duration-300"></span>
                      </a>
                    </div>

                    <div>
                      <a href="/" class="relative inline-block group">
                        <p class="xl:text-base text-grey xl:pt-4 lg:text-base lg:pt-4 yellow-on-hover hover-translate ">
                          Projects
                        </p>
                        <span class="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-500 group-hover:h-0.5 group-hover:w-full transition-all duration-300"></span>
                      </a>
                    </div>

                    <div>
                      <a href="/" class="relative inline-block group">
                        <p class="xl:text-base text-grey xl:pt-4 lg:text-base lg:pt-4 yellow-on-hover hover-translate  ">
                          Careers
                        </p>
                        <span class="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-500 group-hover:h-0.5 group-hover:w-full transition-all duration-300"></span>
                      </a>
                    </div>

                    <div>
                      <a href="/" class="relative inline-block group">
                        <p class="xl:text-base text-grey xl:pt-4 lg:text-base lg:pt-4 yellow-on-hover hover-translate  ">
                          Blog
                        </p>
                        <span class="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-500 group-hover:h-0.5 group-hover:w-full transition-all duration-300"></span>
                      </a>
                    </div>

                    <div>
                      <a href="/" class="relative inline-block group">
                        <p class="xl:text-base text-grey xl:pt-4 lg:text-base lg:pt-4 yellow-on-hover hover-translate  ">
                          Privacy Policy
                        </p>
                        <span class="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-500 group-hover:h-0.5 group-hover:w-full transition-all duration-300"></span>
                      </a>
                    </div>
                  </div>

                  <div>
                    <div>
                      <a href="/" class="relative inline-block group">
                        <p class="xl:text-base text-grey xl:pt-18 lg:text-base lg:pt-18 yellow-on-hover hover-translate">
                          Services
                        </p>
                        <span class="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-500 group-hover:h-0.5 group-hover:w-full transition-all duration-300"></span>
                      </a>
                    </div>

                    <div>
                      <a href="/" class="relative inline-block group">
                        <p class="xl:text-base text-grey xl:pt-4 lg:text-base lg:pt-4 yellow-on-hover hover-translate ">
                          Pricing
                        </p>
                        <span class="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-500 group-hover:h-0.5 group-hover:w-full transition-all duration-300"></span>
                      </a>
                    </div>

                    <div>
                      <a href="/" class="relative inline-block group">
                        <p class="xl:text-base text-grey xl:pt-4 yellow-on-hover hover-translate lg:text-base lg:pt-4  ">
                          Team
                        </p>
                        <span class="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-500 group-hover:h-0.5 group-hover:w-full transition-all duration-300"></span>
                      </a>
                    </div>

                    <div>
                      <a href="/" class="relative inline-block group">
                        <p class="xl:text-base text-grey xl:pt-4 yellow-on-hover hover-translate lg:text-base lg:pt-4 ">
                          Contact
                        </p>
                        <span class="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-500 group-hover:h-0.5 group-hover:w-full transition-all duration-300"></span>
                      </a>
                    </div>

                    <div>
                      <a href="/" class="relative inline-block group">
                        <p class="xl:text-base text-grey xl:pt-4 yellow-on-hover hover-translate lg:text-base lg:pt-4 ">
                          Terms Of Use
                        </p>
                        <span class="absolute inset-x-0 bottom-0 h-0.5 bg-yellow-500 group-hover:h-0.5 group-hover:w-full transition-all duration-300"></span>
                      </a>
                    </div>
                  </div>

                  <div className="xl:ml-0 lg:-ml-12">
                    <h1 class="xl:text-2xl font-bold text-white lg:text-2xl">
                      Office Address
                    </h1>
                    <p class="xl:text-base font-semibold text-grey xl:pt-10 lg:text-base lg:pt-10">
                      42 Mamnoun Street, Saba
                    </p>
                    <p class="xl:text-base font-semibold text-grey pt-1 lg:text-base">
                      Carpet and Antiques Store,
                    </p>
                    <p class="xl:text-base font-semibold text-grey pt-1 lg:text-base">UK</p>
                    <h2 class="xl:text-2xl font-bold text-white xl:pt-8 lg:text-2xl lg:pt-4">
                      Email Address
                    </h2>
                    <p class="xl:text-base font-semibold text-grey xl:pt-8 lg:text-base lg:pt-4">
                      Interested in working with us?
                    </p>
                    <p class="xl:text-base font-semibold text-grey pt-1 yellow-on-hover lg:text-base">
                      support.industrie@gmail.com
                    </p>
                  </div>

                  <div className="xl:ml-16 lg:ml-2">
                    <h1 class="xl:text-2xl font-bold text-white lg:text-2xl">
                      Phone Number
                    </h1>
                    <p class="xl:text-base font-semibold text-grey xl:pt-10 yellow-on-hover lg:text-base lg:pt-10">
                      +971 551 579 261
                    </p>
                    <p class="xl:text-base font-semibold text-grey pt-1 lg:text-base">
                      +97 155 596 1659
                    </p>

                    <h2 class="xl:text-2xl font-bold text-white xl:pt-8 lg:text-2xl lg:pt-8">
                      Follow Us
                    </h2>
                    <div className="flex xl:mt-6 lg:mt-4">
                      <a href="/">
                        <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg ">
                          <i className="fab fa-facebook"></i>
                        </button>
                      </a>

                      <a href="/">
                        <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg ml-2">
                          <i className="fab fa-linkedin"></i>
                        </button>
                      </a>

                      <a href="/">
                        <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg ml-2 ">
                          <i className="fab fa-instagram"></i>
                        </button>
                      </a>

                      <a href="/">
                        <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg ml-2">
                          <i className="fab fa-youtube"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>

                <hr className="border-blue-50 "></hr>
                <div className="flex justify-center xl:pt-6 xl:pb-6 lg:pt-4 ">
                  <p class="xl:text-base text-grey font-semibold lg:text-base">
                    © 2024 Industrie. Designed By
                  </p>
                  <p class="xl:text-base text-yellow font-semibold ml-2 lg:text-base">
                    RSTheme.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
