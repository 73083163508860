import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import rplogo from "assets/img/rplogo.png";
import drop from "assets/img/drop.png";
import thunmnails from "assets/img/thumbnails.png";
import serach from "assets/img/search.png";
import dropdown from "assets/img/dropdown.png";

import industriallogo from "assets/img/industriallogo.png";

export default function Indexnavbar2() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  const [showFirstMenu, setShowFirstMenu] = useState(false);

  const handleFirstMenuClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowFirstMenu(!showFirstMenu);
  };

  const [showFirstMenu1, setShowFirstMenu1] = useState(false);

  const handleFirstMenuClick1 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowFirstMenu1(!showFirstMenu1);
  };

  const [showFirstMenu2, setShowFirstMenu2] = useState(false);

  const handleFirstMenuClick2 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowFirstMenu2(!showFirstMenu2);
  };

  const [showFirstMenu3, setShowFirstMenu3] = useState(false);

  const handleFirstMenuClick3 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowFirstMenu3(!showFirstMenu2);
  };

  const [showFirstMenu4, setShowFirstMenu4] = useState(false);

  const handleFirstMenuClick4 = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setShowFirstMenu4(!showFirstMenu2);
  };

  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsScrolled(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const [dropdownVisible1, setDropdownVisible1] = useState(false);

  const toggleDropdown1 = () => {
    setDropdownVisible1(!dropdownVisible1);
  };

  return (
    <section>
      <div>
        <section
          className={`xl:block lg:block md:hidden sm:hidden hidden grotesk bg-newshade33 ${
            isScrolled ? "hidden" : ""
          }`}
        >
          <div className="xl:pt-4 xl:pb-4 xl:px-4 container mx-auto lg:pt-4 lg:pb-4 lg:px-4">
            <div className="flex">
              <div className="flex">
                <p className="xl:text-base text-grey-600 font-bold ml-2 lg:text-base">
                  Email.
                </p>
                <p className="xl:text-base text-grey-600 xl:ml-4 lg:text-base lg:ml-4">
                  support.industrie@gmail.com
                </p>
              </div>
              <div className="flex xl:ml-8 lg:ml-8">
                <p className="xl:text-base text-grey-600 font-bold lg:text-base">
                  Call.
                </p>
                <p className="xl:text-base text-grey-600 xl:ml-4 lg:text-base lg:ml-4">
                  +971 551 579 261
                </p>
              </div>
              <div className="flex xl:ml-120 lg:ml-68">
                <p className="xl:text-base text-grey-600 font-bold">
                  Schedule.
                </p>
                <p className="xl:text-base text-grey-600 xl:ml-4">
                  Mon - Sat 10.00 - 18.00
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          className={`xl:block lg:block md:hidden sm:hidden hidden  grotesk ${
            isScrolled ? "fixed-on-scroll" : ""
          }`}
        >
          <div className="xl:pt-4 xl:pb-4 xl:px-4 container mx-auto lg:pt-4 lg:pb-4 lg:px-4">
            <div className="grid grid-cols-3">
              <div>
                <div className="grid grid-cols-3 flex">
                  <div>
                    <img
                      className="xl:w-5/12 xl:mt-2 ml-1"
                      src={industriallogo}
                      alt="Industrial Logo"
                    />
                  </div>
                </div>
              </div>
              <div className="flex xl:mt-2 xl:-ml-52 lg:-ml-32 lg:mt-1 ">
                <a href="/">
                  <p className="xl:text-base text-grey-600 yellow-on-hover lg:text-base">
                    Home
                  </p>
                </a>
                <div>
                  <a href="/About3">
                    <p className="xl:text-base text-grey-600 yellow-on-hover xl:ml-5 lg:text-base lg:ml-4">
                      About
                    </p>
                  </a>
                </div>
                <div className="relative inline-block">
                  <div className="relative">
                    <a href="/Services2">
                      <p
                        className="xl:text-base text-grey-600 yellow-on-hover xl:ml-5 lg:text-base lg:ml-4 cursor-pointer"
                        onMouseEnter={() => setShowFirstMenu1(true)}
                        onMouseLeave={() => setShowFirstMenu1(false)}
                      >
                        Service
                      </p>
                    </a>
                    {showFirstMenu1 && (
                      <div
                        className="absolute bg-white shadow-lg rounded-lg z-10 text-grey-600 font-bold"
                        style={{ minWidth: "max-content" }}
                        onMouseEnter={() => setShowFirstMenu1(true)}
                        onMouseLeave={() => setShowFirstMenu1(false)}
                      >
                        <div className="container mx-auto xl:p-6 lg:p-6">
                          <div>
                            <a href="/">
                              <p className="yellow-on-hover">Services</p>
                            </a>
                            <hr className="divider" />
                            <a href="/">
                              <p className="yellow-on-hover">Service Single</p>
                            </a>
                            <hr className="divider" />
                            <a href="/">
                              <p className="yellow-on-hover">Projects</p>
                            </a>
                            <hr className="divider" />
                            <a href="/">
                              <p className="yellow-on-hover">Team</p>
                            </a>
                            <hr className="divider" />
                            <a href="/">
                              <p className="yellow-on-hover">Pricing Plans</p>
                            </a>
                            <hr className="divider" />
                            <a href="/">
                              <p className="yellow-on-hover">Faq</p>
                            </a>
                            <hr className="divider" />
                            <a href="/">
                              <p className="yellow-on-hover">Appointment</p>
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative inline-block">
                  <div className="relative">
                    <a href="/Employer">
                      <p
                        className="xl:text-base text-grey-600 yellow-on-hover xl:ml-5 lg:text-base lg:ml-4 cursor-pointer"
                        onMouseEnter={() => setShowFirstMenu(true)}
                        onMouseLeave={() => setShowFirstMenu(false)}
                      >
                        Process
                      </p>
                    </a>
                    {showFirstMenu && (
                      <div
                        className="absolute bg-white shadow-lg rounded-lg z-10 text-grey-600 font-bold xl:w-5/12"
                        style={{ minWidth: "max-content" }}
                        onMouseEnter={() => setShowFirstMenu(true)}
                        onMouseLeave={() => setShowFirstMenu(false)}
                      >
                        <div className="container mx-auto xl:p-8 lg:p-6">
                          <div className="grid grid-cols-3 gap-4">
                            <div>
                              <a href="/">
                                <p className="yellow-on-hover">Icon Box</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Advanced Tab</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Work Process</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Counter</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">GSAP</p>
                              </a>
                            </div>
                            <div>
                              <a href="/">
                                <p className="yellow-on-hover">Services Box</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Team</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Pricing</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Projects</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Coming soon</p>
                              </a>
                            </div>
                            <div>
                              <a href="/">
                                <p className="yellow-on-hover">Services Tab</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Testimonials</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Partner Logo</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Blog</p>
                              </a>
                              <hr className="divider" />
                              <a href="/">
                                <p className="yellow-on-hover">Coming Soon</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <a href="/cancellation-policy">
                    <p className="xl:text-base text-grey-600 yellow-on-hover xl:ml-5 lg:text-base lg:ml-4">
                      Blog
                    </p>
                  </a>
                </div>
                <div>
                  <a href="/contact?">
                    <p className="xl:text-base text-grey-600 yellow-on-hover xl:ml-5 lg:text-base lg:ml-4">
                      Contact
                    </p>
                  </a>
                </div>
              </div>
              <div className="flex xl:ml-16 lg:ml-6">
                <div className="flex xl:mt-2 lg:mt-1">
                  <div className="flex">
                    <a href="/">
                      <button className="xl:text-2xl  lg:text-lg text-blackgrey">
                        <i className="fab fa-facebook"></i>
                      </button>
                    </a>
                    <a href="/">
                      <button className="xl:text-2xl  lg:text-lg text-blackgrey ml-4">
                        <i className="fab fa-linkedin"></i>
                      </button>
                    </a>
                    <a href="/">
                      <button className="xl:text-2xl  lg:text-lg text-blackgrey ml-4">
                        <i className="fab fa-instagram"></i>
                      </button>
                    </a>
                    <a href="/">
                      <button className="xl:text-2xl  lg:text-lg text-blackgrey ml-4">
                        <i className="fab fa-youtube"></i>
                      </button>
                    </a>
                  </div>
                </div>
                <a>
                  <button className="xl:text-base bg-newshade32 rounded-md xl:px-8 xl:py-3 text-black xl:ml-8 lg:px-6 lg:py-2 lg:ml-6">
                    Get In Touch →
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/*TAB AND MOBILE VIEW*/}
      <section className=" container mx-auto xl:hidden lg:hidden md:block block sm:block ">
        <div className="w-full relative flex justify-between">
          <div className={`px-0 ${navbarOpen ? "hidden" : ""}`}>
            <span>
              <a href="http://localhost:3000/">
                <img
                  src={industriallogo}
                  className="   md:w-40 md:h-45 md:mt-4 w-40   mt-2  cursor-pointer  lg:pr-0 md:pr-0   text-1xl md:ml-4 h-8 ml-4 "
                />
              </a>
            </span>
          </div>
          <button
            className="bg-transparent cursor-pointer text-2xl text-black font-bold leading-none border-transparent rounded  ml-12 block  outline-none focus:outline-none md:mr-4 mr-8 md:mt-4 mt-2"
            type="button  "
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {navbarOpen ? (
              <i className=""></i>
            ) : (
              <i className="fas fa-bars"></i>
            )}
          </button>
        </div>
      </section>

      <div className="xl:hidden lg:hidden md:block sm:block block ">
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none py-3 xl:pt-1 xl:py-0" +
            (navbarOpen ? " block bg-white" : " hidden")
          }
          id="example-navbar-warning"
        >
          <div className="px-0">
            <div className="xl:w-full relative flex justify-between lg:w-auto lg:static lg:justify-start">
              <div className="pr-4 ">
                <span>
                  <a href="/">
                    <img
                      src={industriallogo}
                      alt=""
                      className=" md:w-40 md:h-45 md:mt-0 h-8  cursor-pointer pr-0  md:pr-0   md:ml-12 mt-2 ml-6  w-32"
                    />
                  </a>
                </span>
              </div>
              <button
                className="cursor-pointer text-3xl text-black md:mr-12 font-semibold leading-none block  outline-none focus:outline-none mr-8"
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                x
              </button>
            </div>

            <div
              className="md:px-12 px-8
            "
            >
              <div className="  md:mt-12 pt-8">
                <a href="/homepage1">
                  <p className=" cursor-pointer md:text-base font uppercase text-black bg-white figtree font-semibold text-sm yellow-on-hover ">
                    Home
                  </p>
                </a>
              </div>
              <hr className="border-blue-50 md:mt-4 mt-4"></hr>

              <div className=" md:mt-4 mt-4">
                <a href="/About3">
                  <p className=" cursor-pointer md:text-base font uppercase text-black bg-white figtree font-semibold text-sm yellow-on-hover ">
                    About
                  </p>
                </a>
              </div>
              <hr className="border-blue-50 md:mt-4 mt-4"></hr>

              <div className="flex justify-between md:mt-4 relative mt-4">
                <a href="/Services2">
                  <p className="cursor-pointer md:text-base uppercase text-black bg-white figtree font-semibold text-sm hover-on-yellow">
                    services
                  </p>
                </a>
                <div className="dropdown relative">
                  <button onClick={toggleDropdown}>
                    <img className="w-6" src={drop} alt="Dropdown" />
                  </button>
                  {dropdownVisible && (
                    <div className="absolute bg-white shadow-md mt-2 w-full left-0 md:px-12 z-50 pb-4 px-8">
                      <a href="/" className="block text-black yellow-on-hover">
                        Service
                      </a>
                      <a
                        href="/"
                        className="block text-black yellow-on-hover pt-1"
                      >
                        Service line
                      </a>
                      <a
                        href="/"
                        className="block text-black yellow-on-hover pt-1"
                      >
                        Projects
                      </a>
                      <a
                        href="/"
                        className="block text-black yellow-on-hover pt-1"
                      >
                        Team
                      </a>
                      <a
                        href="/"
                        className="block text-black yellow-on-hover pt-1"
                      >
                        Pricing Plans
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <hr className="border-blue-50 md:mt-4 mt-4"></hr>

              <div className="relative md:mt-4 mt-4">
                <div className="flex justify-between items-center">
                  <div className="relative">
                    <a href="/Employer">
                      <p className="cursor-pointer md:text-base uppercase text-black bg-white figtree font-semibold text-sm hover-on-yellow">
                        Process
                      </p>
                    </a>
                    {dropdownVisible1 && (
                      <div className="absolute bg-white shadow-md mt-2 w-full left-0 md:px-12 z-50 pb-4 px-8">
                        <a
                          href="/"
                          className="block text-black yellow-on-hover"
                        >
                          Service
                        </a>
                        <a
                          href="/"
                          className="block text-black yellow-on-hover pt-1"
                        >
                          Service line
                        </a>
                        <a
                          href="/"
                          className="block text-black yellow-on-hover pt-1"
                        >
                          Projects
                        </a>
                        <a
                          href="/"
                          className="block text-black yellow-on-hover pt-1"
                        >
                          Team
                        </a>
                        <a
                          href="/"
                          className="block text-black yellow-on-hover pt-1"
                        >
                          Pricing Plans
                        </a>
                      </div>
                    )}
                  </div>
                  <button onClick={toggleDropdown1}>
                    <img className="w-6" src={drop} alt="Dropdown" />
                  </button>
                </div>
              </div>

              <hr className="border-blue-50 md:mt-4 mt-4"></hr>

              <div className="md:mt-4 mt-4">
                <a href="/cancellation-policy">
                  <p className=" cursor-pointer  md:text-base font  uppercase text-black  bg-white figtree font-semibold text-sm">
                    Blog
                  </p>
                </a>
              </div>
              <hr className="border-blue-50 md:mt-4 mt-4"></hr>
              <div className="md:mt-4 mt-4">
                <a href="/contact?">
                  <p className=" cursor-pointer  md:text-base font  uppercase text-black  bg-white figtree font-semibold text-sm">
                    Contact
                  </p>
                </a>
              </div>

              <div className="md:mt-20 mt-12 ">
                <a href="/contact?">
                  <button className="md:px-4 md:py-2  bg-newshade32 text-white rounded-md px-4 py-2  ">
                    Get In Touch
                  </button>
                </a>
              </div>
            </div>

            {/*} <div className="flex xl:hidden lg:hidden md:block block md:mt-16 pb-56 pl-0 mt-8 md:ml-0 ml-6 ">
              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-14 ">
                  <i className="fab fa-facebook"></i>
                </button>
              </a>

              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-linkedin"></i>
                </button>
              </a>

              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-instagram"></i>
                </button>
              </a>

              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-youtube"></i>
                </button>
              </a>
            </div>*/}
          </div>
        </div>
      </div>
    </section>
  );
}
