import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import background14 from "assets/img/background14.png";
import companylogo from "assets/img/companylogo.png";
import industrialicon1 from "assets/img/industrialicon1.png";
import industrialicon2 from "assets/img/industrialicon2.png";
import industrialicon3 from "assets/img/industrialicon3.jpg";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-red-500 cursor-pointer`}
      style={{ ...style, display: "block", zIndex: 1 }}
      onClick={onClick}
    >
      {/* <span className="xl:pl-24 ">{"<"}</span> */}
      {/* ﹤ */}
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-blue-500 cursor-pointer`}
      style={{ ...style, display: "block", zIndex: 1 }}
      onClick={onClick}
    >
      ﹥
    </div>
  );
};

const Profile = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    afterChange: (current) => setCurrentSlide(current), // Track the current slide
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section className="grotesk">
      <Slider {...settings}>
        <div>
          <div className="xl:p-12 lg:p-8 rounded-md p-6">
            <div className="flex ">
              <div>
                <img class="w-20 rounded-full" src={industrialicon1} />
              </div>
              <div className="xl:ml-4 lg:ml-4 ml-4">
                <h1 className="xl:text-lg text-white font-bold">
                  BEST COMPANY
                </h1>
                
                 <p class="xl:text-base text-white font-bold xl:pt-4 pt-4 text-base ">Podcasting operational change </p> 
                 <p class="xl:text-base text-white font-bold text-base">management inside of workflows</p> 
                 <p class="xl:text-base text-white font-bold text-base">to establish a framework seamless</p> 
                <p class="xl:text-base text-white font-bold pb-4 text-base">key performance indicators.</p> 
                
                <div className="divider "></div>
                <h1 className="xl:text-lg text-yellow font-semibold pt-4 text-lg">
                  BM ASHIK TOREN
                </h1>
                <p className="xl:text-sm text-yellow text-sm">Founder</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="xl:p-12 lg:p-8 rounded-md p-6">
            <div className="flex ">
              <div>
                <img class="w-20 rounded-full" src={industrialicon2} />
              </div>
              <div className="xl:ml-4 lg:ml-4 ml-4">
                <h1 className="xl:text-lg text-white font-bold">
                  BEST COMPANY
                </h1>
                <p class="xl:text-base text-white font-bold xl:pt-4 text-base pt-4">Podcasting operational change </p> 
                 <p class="xl:text-base text-white font-bold text-base">management inside of workflows</p> 
                 <p class="xl:text-base text-white font-bold text-base">to establish a framework seamless</p> 
                <p class="xl:text-base text-white font-bold pb-4 text-base">key performance indicators.</p> 
                <div className="divider"></div>
                <h1 className="xl:text-lg text-yellow font-semibold pt-4 text-lg">
                  BM ASHIK TOREN
                </h1>
                <p className="xl:text-sm text-yellow text-sm">Founder</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="xl:p-12 lg:p-8 rounded-md p-6">
            <div className="flex ">
              <div>
                <img class="w-20 rounded-full" src={industrialicon3} />
              </div>
              <div className="xl:ml-4 lg:ml-4 ml-4">
                <h1 className="xl:text-lg text-white font-bold text-lg">
                  BEST COMPANY
                </h1>
                <p class="xl:text-base text-white font-bold xl:pt-4 text-base pt-4">Podcasting operational change </p> 
                 <p class="xl:text-base text-white font-bold text-base">management inside of workflows</p> 
                 <p class="xl:text-base text-white font-bold text-base">to establish a framework seamless</p> 
                <p class="xl:text-base text-white font-bold pb-4 text-base">key performance indicators.</p> 
                <div className="divider"></div>
                <h1 className="xl:text-lg text-yellow font-semibold pt-4 text-lg">
                  BM ASHIK TOREN
                </h1>
                <p className="xl:text-sm text-yellow text-sm">Founder</p>
              </div>
            </div>
          </div>
        </div>


        <div>
          <div className="xl:p-12  lg:p-8 rounded-md p-6">
            <div className="flex ">
              <div>
                <img class="w-20 rounded-full" src={industrialicon2} />
              </div>
              <div className="xl:ml-4 lg:ml-4 ml-4">
                <h1 className="xl:text-lg text-white font-bold text-lg">
                  BEST COMPANY
                </h1>
                <p class="xl:text-base text-white font-bold xl:pt-4 pt-4 text-base">Podcasting operational change </p> 
                 <p class="xl:text-base text-white font-bold text-base">management inside of workflows</p> 
                 <p class="xl:text-base text-white font-bold text-base">to establish a framework seamless</p> 
                <p class="xl:text-base text-white font-bold pb-4 text-base">key performance indicators.</p> 
                <div className="divider"></div>
                <h1 className="xl:text-lg text-yellow font-semibold pt-4 text-lg">
                  BM ASHIK TOREN
                </h1>
                <p className="xl:text-sm text-yellow text-sm">Founder</p>
              </div>
            </div>
          </div>
        </div>

      </Slider>
    </section>
  );
};

export default Profile;
