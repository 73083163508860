import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import background14 from "assets/img/background14.png";
import companylogo from "assets/img/companylogo.png";
import industrialicon1 from "assets/img/industrialicon1.png";
import industrialicon2 from "assets/img/industrialicon2.png";
import industrialicon3 from "assets/img/industrialicon3.jpg";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-red-500 cursor-pointer`}
      style={{ ...style, display: "block", zIndex: 1 }}
      onClick={onClick}
    >
      {/* <span className="xl:pl-24 ">{"<"}</span> */}
      {/* ﹤ */}
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-blue-500 cursor-pointer`}
      style={{ ...style, display: "block", zIndex: 1 }}
      onClick={onClick}
    >
      ﹥
    </div>
  );
};

const Landing = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    afterChange: (current) => setCurrentSlide(current), // Track the current slide
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.9,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section className="grotesk">
      <Slider {...settings}>
        <div>
          <div className="xl:p-6 lg:p-6 bg-white rounded-md p-6 ml-4">
            <div className="flex ">
              <div>
                <img class="w-12 h-12 rounded-full" src={industrialicon1} />
              </div>
              <div className="xl:ml-4 lg:ml-4 ml-4">
                <h1 className="xl:text-lg lg:text-base1 text-darkblack font-semibold text-base1">
                  BM ASHIK TOREN
                </h1>
                <p className="xl:text-sm text-grey-600 lg:text-sm text-sm">Founder</p>
              </div>
            </div>
            <p class="xl:text-base text-grey font-bold xl:pt-4 lg:text-base lg:pt-4 text-base pt-4">
              We are largest independent manufacturing company We solve
              worldwide .
            </p>
            <div>
              <img class="xl:w-24 xl:mt-4 lg:w-20 lg:mt-4 mt-4 w-20" src={companylogo} />
            </div>
          </div>
        </div>

        <div>
          <div className="xl:p-6 bg-white rounded-md xl:ml-4 lg:p-6 lg:ml-4 p-6 ml-4">
            <div className="flex ">
              <div>
                <img class="w-12 h-12 rounded-full" src={industrialicon2} />
              </div>
              <div className="xl:ml-4 lg:ml-4 ml-4">
                <h1 className="xl:text-lg text-darkblack font-semibold lg:text-base1 text-base1">
                  BM ASHIK TOREN
                </h1>
                <p className="xl:text-sm text-grey-600 lg:text-sm text-sm">Founder</p>
              </div>
            </div>
            <p class="xl:text-base text-grey font-bold xl:pt-4 lg:text-base lg:pt-4 pt-4 text-base">
              We are largest independent manufacturing company We solve
              worldwide .
            </p>
            <div>
              <img class="xl:w-24 xl:mt-4 lg:w-20 lg:mt-4 mt-4 w-20 " src={companylogo} />
            </div>
          </div>
        </div>

        <div>
          <div className="xl:p-6 bg-white rounded-md xl:ml-4 lg:p-6 lg:ml-4 p-6 ml-4">
             <div className="flex ">
              <div>
                <img class="w-12 h-12 rounded-full"src={industrialicon3}/>
              </div>
              <div className="xl:ml-4 lg:ml-4 ml-4">
                <h1 className="xl:text-lg text-darkblack font-semibold lg:text-base1 text-base1">BM ASHIK TOREN</h1>
                <p className="xl:text-sm text-grey-600 lg:text-sm text-sm">Founder</p>
              </div>
             </div>
             <p class="xl:text-base text-grey font-bold xl:pt-4 lg:text-base lg:pt-4 text-base pt-4">We are largest independent manufacturing company We solve
             worldwide .</p>
             <div>
              <img class="xl:w-24 xl:mt-4 lg:w-20 lg:mt-4 mt-4 w-20" src={companylogo}/>
             </div>
          </div>
        </div>

        <div>
          <div className="xl:p-6 bg-white rounded-md xl:ml-4 lg:p-6 lg:ml-4 ml-4 p-6">
             <div className="flex ">
              <div>
                <img class="w-12 h-12 rounded-full"src={industrialicon2}/>
              </div>
              <div className="xl:ml-4 lg:ml-4 ml-4">
                <h1 className="xl:text-lg text-darkblack font-semibold lg:text-base1 text-base1">BM ASHIK TOREN</h1>
                <p className="xl:text-sm text-grey-600 lg:text-sm text-sm">Founder</p>
              </div>
             </div>
             <p class="xl:text-base text-grey font-bold xl:pt-4 lg:text-base lg:pt-4 pt-4 text-base">We are largest independent manufacturing company We solve
             worldwide .</p>
             <div>
              <img class="xl:w-24 xl:mt-4 lg:w-20 lg:mt-4 mt-4 w-20" src={companylogo}/>
             </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Landing;
