import React from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import industrialslider2 from "assets/img/industrialslider2.png";
import industrial4 from "assets/img/industrial4.jpg";
import industrial5 from "assets/img/industrial5.png";
import industrial6 from "assets/img/industrial6.jpg";
import industrial7 from "assets/img/industrial7.jpg";
import industrial8 from "assets/img/industrial8.jpg";
import servicebackground1 from "assets/img/servicebackground1.jpg";
import serviceicon1 from "assets/img/serviceicon1.png";
import serviceicon2 from "assets/img/serviceicon2.png";
import serviceicon3 from "assets/img/serviceicon3.png";
import servicebackground2 from "assets/img/servicebackground2.jpg";
import servicebackground3 from "assets/img/servicebackground3.jpg";
import servicebackground4 from "assets/img/servicebackground4.png";
import industrial3 from "assets/img/industrial3.png";
import background15 from "assets/img/background15.jpg";
import logo11 from "assets/img/logo11.png";
import logo12 from "assets/img/logo12.png";
import logo13 from "assets/img/logo13.png";
import logo14 from "assets/img/logo14.png";
import logo15 from "assets/img/logo15.png";
import logo16 from "assets/img/logo16.png";

export default function Services2() {
  return (
    <>
      <Indexnavbar2 fixed />

      <section className="xl:block lg:block md:hidden sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "500px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto xl:px-4 xl:pt-48 lg:px-8 lg:pt-56">
              <div>
                <h1 className="xl:text-5xl text-white font-bold lg:text-4xl">
                  Services
                </h1>
                <div className="flex xl:mt-4 lg:mt-4">
                  <p className="xl:text-sm font-bold text-white lg:text-sm">
                    Industrie - Industry and Engineering WordPress Theme
                  </p>
                  <p className="xl:text-base1 font-bold text-white xl:ml-2 xl:-mt-2 lg:text-base1 lg:ml-2">
                    ›
                  </p>
                  <p className="xl:text-sm font-bold text-white xl:ml-2 lg:text-sm lg:ml-2">
                    Services
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk container mx-auto xl:p-4">
        <div className="grid grid-cols-4">
          <div className="">
            <div className="image-container">
              <img class="xl:w-11/12" src={industrial4} />
            </div>
            <div className="xl:p-6 hover-div xl:w-11/12">
              <h1 class="xl:text-2xl font-bold  text-darkblack xl:mt-4">
                Best Innovations in <br></br>metallurgy
              </h1>
              <p class="xl:text-lg  xl:pt-4 text-grey-600">
                Consectetur ipiscing elit<br></br> tellus ullam corper mattis
              </p>
              <div className=" flex  xl:mt-2">
                <h1 class="text-grey xl:text-5xl font-bold">01</h1>
              </div>
              <div className="flex justify-between ">
                <a href="/">
                  <h1 class="xl:text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="xl:text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="">
            <div className="image-container">
              <img class="xl:w-11/12" src={industrial5} />
            </div>
            <div className="xl:p-6 hover-div xl:w-11/12">
              <h1 class="xl:text-2xl font-bold  text-darkblack xl:mt-4">
                Best Innovations in <br></br>metallurgy
              </h1>
              <p class="xl:text-lg  xl:pt-4 text-grey-600">
                Consectetur ipiscing elit<br></br> tellus ullam corper mattis
              </p>
              <div className=" flex  xl:mt-2">
                <h1 class="text-grey xl:text-5xl font-bold">02</h1>
              </div>
              <div className="flex justify-between ">
                <a href="/">
                  <h1 class="xl:text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="xl:text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="">
            <div className="image-container">
              <img class="xl:w-11/12" src={industrial6} />
            </div>
            <div className="xl:p-6 hover-div xl:w-11/12">
              <h1 class="xl:text-2xl font-bold  text-darkblack xl:mt-4">
                Best Innovations in <br></br>metallurgy
              </h1>
              <p class="xl:text-lg xl:pt-4 text-grey-600">
                Consectetur ipiscing elit<br></br> tellus ullam corper mattis
              </p>
              <div className=" flex  xl:mt-2">
                <h1 class="text-grey xl:text-5xl font-bold">03</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="xl:text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="xl:text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="">
            <div className="image-container">
              <img class="xl:w-11/12" src={industrial7} />
            </div>
            <div className="xl:p-6 hover-div xl:w-11/12">
              <h1 class="xl:text-2xl font-bold  text-darkblack xl:mt-4">
                Best Innovations in <br></br>metallurgy
              </h1>
              <p class="xl:text-base  xl:pt-4 text-grey-600">
                Consectetur ipiscing elit<br></br> tellus ullam corper mattis
              </p>
              <div className=" flex  xl:mt-2">
                <h1 class="text-grey xl:text-5xl font-bold">04</h1>
              </div>
              <div className="flex  justify-between">
                <a href="/">
                  <h1 class="xl:text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="xl:text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-4 mt-12">
          <div className="">
            <div className="image-container">
              <img class="xl:w-11/12" src={industrial8} />
            </div>
            <div className="xl:p-6 hover-div xl:w-11/12">
              <h1 class="xl:text-2xl font-bold  text-darkblack xl:mt-4">
                Best Innovations in <br></br>metallurgy
              </h1>
              <p class="xl:text-lg  xl:pt-4 text-grey-600">
                Consectetur ipiscing elit<br></br> tellus ullam corper mattis
              </p>
              <div className=" flex  xl:mt-2">
                <h1 class="text-grey xl:text-5xl font-bold">05</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="xl:text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="xl:text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="">
            <div className="image-container">
              <img class="xl:w-11/12" src={industrial7} />
            </div>
            <div className="xl:p-6 hover-div xl:w-11/12">
              <h1 class="xl:text-2xl font-bold  text-darkblack xl:mt-4">
                Best Innovations in <br></br>metallurgy
              </h1>
              <p class="xl:text-lg  xl:pt-4 text-grey-600">
                Consectetur ipiscing elit<br></br> tellus ullam corper mattis
              </p>
              <div className=" flex  xl:mt-2">
                <h1 class="text-grey xl:text-5xl font-bold">06</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="xl:text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="xl:text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="">
            <div className="image-container">
              <img class="xl:w-11/12" src={industrial4} />
            </div>
            <div className="xl:p-6 hover-div xl:w-11/12">
              <h1 class="xl:text-2xl font-bold  text-darkblack xl:mt-4">
                Best Innovations in <br></br>metallurgy
              </h1>
              <p class="xl:text-lg  xl:pt-4 text-grey-600">
                Consectetur ipiscing elit<br></br> tellus ullam corper mattis
              </p>
              <div className=" flex  xl:mt-2">
                <h1 class="text-grey xl:text-5xl font-bold">07</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="xl:text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="xl:text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${servicebackground1})`,
              width: "100%",
              height: "700px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto xl:pt-20 xl:px-4">
              <p className="xl:text-base font-semibold text-darkblack ">
                OUR BENEFIT
              </p>
              <h1 className="xl:text-4xl font-bold text-darkblack xl:pt-4">
                Always powering your<br></br> business for over 30 years
              </h1>

              <div className="grid grid-cols-3 mt-8">
                <div className="hover-parent">
                  <div className="flex bg-white xl:p-6 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon1} />
                      </div>
                      <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                        01
                      </p>
                    </div>
                    <div className="xl:ml-6">
                      <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                        Expert Engineers
                      </h1>
                      <p class="xl:text-base text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent ml-2">
                  <div className="flex bg-white xl:p-6 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon2} />
                      </div>
                      <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                        02
                      </p>
                    </div>
                    <div className="xl:ml-6">
                      <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                        Quality Control System
                      </h1>
                      <p class="xl:text-base text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent ml-2">
                  <div className="flex bg-white xl:p-6 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon3} />
                      </div>
                      <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                        03
                      </p>
                    </div>
                    <div className="xl:ml-6">
                      <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                        Satisfaction Guarantee
                      </h1>
                      <p class="xl:text-base text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 mt-4">
                <div className="hover-parent">
                  <div className="flex bg-white xl:p-6 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon3} />
                      </div>
                      <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                        04
                      </p>
                    </div>
                    <div className="xl:ml-6">
                      <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                        International Business
                      </h1>
                      <p class="xl:text-base text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent ml-2">
                  <div className="flex bg-white xl:p-6 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon1} />
                      </div>
                      <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                        05
                      </p>
                    </div>
                    <div className="xl:ml-6">
                      <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                        Unrivalled Workmanship
                      </h1>
                      <p class="xl:text-base text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent ml-2">
                  <div className="flex bg-white xl:p-6 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon2} />
                      </div>
                      <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                        06
                      </p>
                    </div>
                    <div className="xl:ml-6">
                      <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                        High Professional Staff
                      </h1>
                      <p class="xl:text-base text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden grotesk container mx-auto xl:px-4">
        <div>
          <div
            style={{
              backgroundImage: `url(${servicebackground2})`,
              width: "100%",
              height: "800px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="xl:pt-16 xl:p-6">
              <p className="xl:text-base text-yellow font-semibold">
                WORK PROCESS
              </p>
              <h1 className="xl:text-4xl font-bold text-darkblack xl:pt-4">
                Project implementation process
              </h1>

              <div className="grid grid-cols-3 mt-10 ">
                <div>
                  <div className="border-new pb-12">
                    <div className="flex">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="xl:text-lg text-center text-darkblack pt-2">
                          01
                        </p>
                      </div>
                      <p class="xl:text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="xl:text-2xl font-semibold xl:pt-5 text-darkblack">
                      Design and Planning
                    </h1>
                    <p className="xl:text-lg text-grey xl:pt-2 ">
                      Industry standard dummy text took<br></br> since the when
                      an unknown printer<br></br> galley type scrambled.
                    </p>
                  </div>

                  <div className="border-new1 pt-16">
                    <div className="flex">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="xl:text-lg text-center text-darkblack pt-2">
                          03
                        </p>
                      </div>
                      <p class="xl:text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="xl:text-2xl font-semibold xl:pt-5 text-darkblack">
                      Testing and Quality
                    </h1>
                    <p className="xl:text-lg text-grey xl:pt-2">
                      Industry standard dummy text took<br></br> since the when
                      an unknown printer<br></br> galley type scrambled.
                    </p>
                  </div>
                </div>

                <div className="">
                  <div className="border-b-bottom pl-6 pb-12">
                    <div className="flex">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="xl:text-lg text-center text-darkblack pt-2">
                          02
                        </p>
                      </div>
                      <p class="xl:text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="xl:text-2xl font-semibold xl:pt-5 text-darkblack">
                      Component Sourcing
                    </h1>
                    <p className="xl:text-lg text-grey xl:pt-2">
                      Industry standard dummy text took<br></br> since the when
                      an unknown printer<br></br> galley type scrambled.
                    </p>
                  </div>

                  <div className="mt-16 pl-6">
                    <div className="flex ">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="xl:text-lg text-center text-darkblack pt-2">
                          04
                        </p>
                      </div>
                      <p class="xl:text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="xl:text-2xl font-semibold xl:pt-5 text-darkblack">
                      Final Assembly
                    </h1>
                    <p className="xl:text-lg text-grey xl:pt-2">
                      Industry standard dummy text took<br></br> since the when
                      an unknown printer<br></br> galley type scrambled.
                    </p>
                  </div>
                </div>

                <div className="ml-4">
                  <div className="bg-white xl:p-6 highlight-border">
                    <h1 className="xl:text-3xl font-bold text-darkblack">
                      Have any Question?
                    </h1>
                    <p className="xl:text-base pt-4 text-grey-600">
                      The point of using Lorem Ipsum is that it has<br></br>{" "}
                      more-or-less packages normal point of using.
                    </p>
                    <div className="mt-8">
                      <form>
                        <input
                          class="bg-newshade33 xl:w-12/12 border-3"
                          type="text"
                          id="text"
                          placeholder="Full Name"
                        />
                        <input
                          class="bg-newshade33 xl:w-12/12 mt-4 border-3 "
                          type="text"
                          id="text"
                          placeholder="Email Address"
                        />
                        <input
                          class="bg-newshade33 xl:w-12/12 mt-4 border-3"
                          type="number"
                          id="text"
                          placeholder="Phone"
                        />
                        <textarea
                          class="mt-4 bg-newshade33 xl:w-12/12 border-3"
                          rows="4"
                          cols=""
                          placeholder="Write Here..."
                        ></textarea>

                        <div className="center-button">
                          <button class="xl:text-base bg-newshade32 xl:px-8 xl:py-2 xl:mt-4">
                            Send Message ➜
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk container mx-auto xl:px-4 ">
        <p class="xl:text-base text-yellow font-semibold "> PRICING PLAN</p>
        <div className="flex justify-between">
          <h1 className="xl:text-4xl font-bold text-darkblack xl:pt-6">
            Our friendly packages
          </h1>
          <div className="border-3 rounded-full  p-4 ">
            <div className="flex">
              <button className="bg-newshade32 rounded-full xl:px-10 xl:py-2 text-darkblack xl:text-base">
                Montly Plan
              </button>
              <button className="bg-newshade32 rounded-full xl:px-10 xl:py-2 text-darkblack xl:text-base ml-4">
                yearly Plan
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 mt-12 ">
          <div className=" highlight-border">
            <div className="bg-newshade33 xl:p-6">
              <h1 className="xl:text-2xl text-center font-bold text-darkblack">
                Basic Plan
              </h1>
              <p className="xl:text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow xl:text-7xl font-bold text-center pt-8">
                $29.00
              </h1>
              <p className="text-darkblack xl:text-base text-center font-semibold">
                Monthly
              </p>
              <p className="xl:text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="xl:text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow xl:text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-darkblack ">
                  Brand Consistency
                </p>
                <p className="text-yellow xl:text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-grey-600 ">
                  Quality Control System
                </p>
                <p className="text-grey-600 xl:text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-grey-600 ">
                  International Business
                </p>
                <p className="text-grey-600 xl:text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-grey-600 ">
                  Professional Support
                </p>
                <p className="text-grey-600 xl:text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 xl:px-30 xl:py-3 xl:text-base text-darkblack mt-6 yellow-on-hover1 ">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>

          <div className="ml-2 highlight-border">
            <div className="bg-newshade33 xl:p-6">
              <h1 className="xl:text-2xl text-center font-bold text-darkblack">
                Commercial
              </h1>
              <p className="xl:text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow xl:text-7xl font-bold text-center pt-8">
                $59.00
              </h1>
              <p className="text-darkblack font-semibold xl:text-base text-center">
                Monthly
              </p>
              <p className="xl:text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="xl:text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow xl:text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-darkblack ">
                  Brand Consistency
                </p>
                <p className="text-yellow xl:text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-darkblack ">
                  Quality Control System
                </p>
                <p className="text-yellow xl:text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-grey-600 ">
                  International Business
                </p>
                <p className="text-grey-600 xl:text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-grey-600 ">
                  Professional Support
                </p>
                <p className="text-grey-600 xl:text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 xl:px-28 xl:py-3 xl:text-base text-darkblack mt-6 bg-newshade32">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>

          <div className="ml-2 highlight-border">
            <div className="bg-newshade33 xl:p-6">
              <h1 className="xl:text-2xl text-center font-bold text-darkblack">
                Enterprise
              </h1>
              <p className="xl:text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow xl:text-7xl font-bold text-center pt-8">
                $89.00
              </h1>
              <p className="text-darkblack font-semibold xl:text-base text-center">
                Monthly
              </p>
              <p className="xl:text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="xl:text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow xl:text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-darkblack ">
                  Brand Consistency
                </p>
                <p className="text-yellow xl:text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-darkblack ">
                  Quality Control System
                </p>
                <p className="text-yellow xl:text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-darkblack ">
                  International Business
                </p>
                <p className="text-yellow xl:text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="xl:text-base text-grey-600 ">
                  Professional Support
                </p>
                <p className="text-grey-600 xl:text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 xl:px-28 xl:py-3 xl:text-base text-darkblack mt-6 yellow-on-hover1 ">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto xl:px-4 ">
        <div className="grid grid-cols-3">
          <div>
            <img class="xl:w-12/12" src={industrial3} />
          </div>
        </div>
      </div>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "500px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="xl:pt-16 container mx-auto xl:px-4">
              <p className="xl:text-base font-semibold text-yellow text-center">
                PARTNERS
              </p>
              <h1 className="xl:text-4xl font-bold text-darkblack xl:pt-4 text-center">
                Partners who trust Industrie
              </h1>
              <div className="grid grid-cols-6 mt-12">
                <div className="bg-white xl:p-12">
                  <div className="center-button image-container">
                    <img class="xl:w-24 " src={logo11} />
                  </div>
                </div>

                <div className="bg-white xl:p-12 xl:ml-2">
                  <div className="center-button image-container">
                    <img class="xl:w-20 " src={logo12} />
                  </div>
                </div>

                <div className="bg-white xl:p-12 xl:ml-2">
                  <div className="center-button image-container">
                    <img class="xl:w-24 " src={logo13} />
                  </div>
                </div>

                <div className="bg-white xl:p-12 xl:ml-2">
                  <div className="center-button image-container">
                    <img class="xl:w-32 xl:pt-2 " src={logo14} />
                  </div>
                </div>

                <div className="bg-white xl:p-12 xl:ml-2">
                  <div className="center-button image-container">
                    <img class="xl:w-24 " src={logo15} />
                  </div>
                </div>

                <div className="bg-white xl:p-12 xl:ml-2">
                  <div className="center-button image-container">
                    <img class="xl:w-32 xl:pt-6 xl:ml-4 " src={logo16} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*landscapeview*/}
      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-16 grotesk container mx-auto p-8">
        <div className="grid grid-cols-4">
          <div className="">
            <div className="image-container">
              <img class="w-11/12" src={industrial4} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">01</h1>
              </div>
              <div className="flex justify-between ">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="ml-2">
            <div className="image-container">
              <img class="w-11/12" src={industrial5} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg  pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">02</h1>
              </div>
              <div className="flex justify-between ">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="ml-2">
            <div className="image-container">
              <img class="w-11/12" src={industrial6} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">03</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="ml-2">
            <div className="image-container">
              <img class="w-11/12" src={industrial7} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-base  pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">04</h1>
              </div>
              <div className="flex  justify-between">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-4 mt-12">
          <div className="">
            <div className="image-container">
              <img class="w-11/12" src={industrial8} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg  pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">05</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="ml-2">
            <div className="image-container">
              <img class="w-11/12" src={industrial7} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg  pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">06</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="ml-2">
            <div className="image-container">
              <img class="w-11/12" src={industrial4} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg  pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex mt-2">
                <h1 class="text-grey text-4xl font-bold">07</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-16 grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${servicebackground1})`,
              width: "100%",
              height: "620px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto pt-16 px-8">
              <p className="text-base font-semibold text-darkblack ">
                OUR BENEFIT
              </p>
              <h1 className="text-4xl font-bold text-darkblack pt-4">
                Always powering your<br></br> business for over 30 years
              </h1>

              <div className="grid grid-cols-3 mt-8">
                <div className="hover-parent">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon1} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        01
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Expert Engineers
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes consectetur adipisicing elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent ml-2">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon2} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        02
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Quality Control System
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes consectetur adipisicing elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent ml-2">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon3} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        03
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Satisfaction Guarantee
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes consectetur adipisicing elitid.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-3 mt-4">
                <div className="hover-parent">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon3} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        04
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        International Business
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent ml-2">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon1} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        05
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Unrivalled Workmanship
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent ml-2">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon2} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        06
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        High Professional Staff
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden grotesk container mx-auto px-8">
        <div>
          <div
            style={{
              backgroundImage: `url(${servicebackground2})`,
              width: "100%",
              height: "830px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-16 p-6">
              <p className="text-base text-yellow font-semibold">
                WORK PROCESS
              </p>
              <h1 className="text-4xl font-bold text-darkblack pt-4">
                Project implementation process
              </h1>

              <div className="grid grid-cols-3 mt-10 ">
                <div>
                  <div className="border-new pb-12">
                    <div className="flex">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="text-lg text-center text-darkblack pt-2">
                          01
                        </p>
                      </div>
                      <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="text-2xl font-semibold pt-4 text-darkblack">
                      Design and Planning
                    </h1>
                    <p className="text-lg text-grey pt-2 ">
                      Industry standard dummy text took since the when an
                      unknown printer galley type scrambled.
                    </p>
                  </div>

                  <div className="border-new1 pt-16">
                    <div className="flex">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="text-lg text-center text-darkblack pt-2">
                          03
                        </p>
                      </div>
                      <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="text-2xl font-semibold pt-4 text-darkblack">
                      Testing and Quality
                    </h1>
                    <p className="text-lg text-grey pt-2">
                      Industry standard dummy text took since the when an
                      unknown printer galley type scrambled.
                    </p>
                  </div>
                </div>

                <div className="">
                  <div className="border-b-bottom pl-6 pb-12">
                    <div className="flex">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="text-lg text-center text-darkblack pt-2">
                          02
                        </p>
                      </div>
                      <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="text-2xl font-semibold pt-4 text-darkblack">
                      Component Sourcing
                    </h1>
                    <p className="text-lg text-grey pt-2">
                      Industry standard dummy text took since the when an
                      unknown printer galley type scrambled.
                    </p>
                  </div>

                  <div className="mt-16 pl-6">
                    <div className="flex ">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="text-lg text-center text-darkblack pt-2">
                          04
                        </p>
                      </div>
                      <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="text-2xl font-semibold pt-4 text-darkblack">
                      Final Assembly
                    </h1>
                    <p className="text-lg text-grey pt-2">
                      Industry standard dummy text took since the when an
                      unknown printer galley type scrambled.
                    </p>
                  </div>
                </div>

                <div className="ml-4">
                  <div className="bg-white p-6 highlight-border">
                    <h1 className="text-3xl font-bold text-darkblack">
                      Have any Question?
                    </h1>
                    <p className="text-base pt-4 text-grey-600">
                      The point of using Lorem Ipsum is that it has more-or-less
                      packages normal point of using.
                    </p>
                    <div className="mt-8">
                      <form>
                        <input
                          class="bg-newshade33 w-12/12 border-3"
                          type="text"
                          id="text"
                          placeholder="Full Name"
                        />
                        <input
                          class="bg-newshade33 w-12/12 mt-4 border-3 "
                          type="text"
                          id="text"
                          placeholder="Email Address"
                        />
                        <input
                          class="bg-newshade33 w-12/12 mt-4 border-3"
                          type="number"
                          id="text"
                          placeholder="Phone"
                        />
                        <textarea
                          class="mt-4 bg-newshade33 w-12/12 border-3"
                          rows="4"
                          cols=""
                          placeholder="Write Here..."
                        ></textarea>

                        <div className="center-button">
                          <button class="xl:text-base bg-newshade32 px-8 py-2 mt-4">
                            Send Message ➜
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-16 grotesk container mx-auto px-8 ">
        <p class="text-base text-yellow font-semibold "> PRICING PLAN</p>
        <div className="flex justify-between">
          <h1 className="text-4xl font-bold text-darkblack pt-6">
            Our friendly packages
          </h1>
          <div className="border-3 rounded-full  p-4 ">
            <div className="flex">
              <button className="bg-newshade32 rounded-full px-12 py-2 text-darkblack text-base">
                Montly Plan
              </button>
              <button className="bg-newshade32 rounded-full px-12 py-2 text-darkblack text-base ml-4">
                yearly Plan
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 mt-12 ">
          <div className=" highlight-border">
            <div className="bg-newshade33 p-6">
              <h1 className="text-2xl text-center font-bold text-darkblack">
                Basic Plan
              </h1>
              <p className="text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow text-5xl font-bold text-center pt-8">
                $29.00
              </h1>
              <p className="text-darkblack text-base text-center font-semibold">
                Monthly
              </p>
              <p className="text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">Brand Consistency</p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">
                  Quality Control System
                </p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">
                  International Business
                </p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">Professional Support</p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 px-16 py-3 text-base text-darkblack mt-6 yellow-on-hover1 ">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>

          <div className="ml-2 highlight-border">
            <div className="bg-newshade33 p-6">
              <h1 className="text-2xl text-center font-bold text-darkblack">
                Commercial
              </h1>
              <p className="text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow text-5xl font-bold text-center pt-8">
                $59.00
              </h1>
              <p className="text-darkblack font-semibold text-base text-center">
                Monthly
              </p>
              <p className="text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">Brand Consistency</p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">
                  Quality Control System
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">
                  International Business
                </p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">Professional Support</p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 px-16 py-3 text-base text-darkblack mt-6 bg-newshade32">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>

          <div className="ml-2 highlight-border">
            <div className="bg-newshade33 p-6">
              <h1 className="text-2xl text-center font-bold text-darkblack">
                Enterprise
              </h1>
              <p className="text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow text-5xl font-bold text-center pt-8">
                $89.00
              </h1>
              <p className="text-darkblack font-semibold xl:text-base text-center">
                Monthly
              </p>
              <p className="text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">Brand Consistency</p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">
                  Quality Control System
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">
                  International Business
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">Professional Support</p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 px-16 py-3 text-base text-darkblack mt-6 yellow-on-hover1 ">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-12 container mx-auto px-8">
              <p className="text-base font-semibold text-yellow text-center">
                PARTNERS
              </p>
              <h1 className="text-4xl font-bold text-darkblack pt-4 text-center">
                Partners who trust Industrie
              </h1>
              <div className="grid grid-cols-6 mt-12">
                <div className="bg-white p-12">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo11} />
                  </div>
                </div>

                <div className="bg-white p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-20 " src={logo12} />
                  </div>
                </div>

                <div className="bg-white p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo13} />
                  </div>
                </div>

                <div className="bg-white p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-32 pt-2 " src={logo14} />
                  </div>
                </div>

                <div className="bg-white p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo15} />
                  </div>
                </div>

                <div className="bg-white p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-32 pt-6 ml-4 " src={logo16} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*tabview*/}

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-8 pt-36 ">
              <div>
                <h1 className="text-5xl text-white font-bold ">Servives</h1>
                <div className="flex mt-4">
                  <p className="text-sm font-bold text-white ">
                    Industrie - Industry and Engineering WordPress Theme
                  </p>
                  <p className="text-base1 font-bold text-white ml-2  ">›</p>
                  <p className="text-sm font-bold text-white ml-2 ">Services</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8">
        <div className="grid grid-cols-2">
          <div className="">
            <div className="image-container">
              <img class="w-11/12" src={industrial4} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">01</h1>
              </div>
              <div className="flex justify-between ">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="ml-2">
            <div className="image-container">
              <img class="w-11/12" src={industrial5} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg  pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">02</h1>
              </div>
              <div className="flex justify-between ">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-4">
          <div className="">
            <div className="image-container">
              <img class="w-11/12" src={industrial6} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">03</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="ml-2">
            <div className="image-container">
              <img class="w-11/12" src={industrial7} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-base  pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">04</h1>
              </div>
              <div className="flex  justify-between">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-4">
          <div className="">
            <div className="image-container">
              <img class="w-11/12" src={industrial8} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg  pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">05</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="ml-2">
            <div className="image-container">
              <img class="w-11/12" src={industrial7} />
            </div>
            <div className="p-6 hover-div w-11/12">
              <h1 class="text-2xl font-bold  text-darkblack mt-4">
                Best Innovations in metallurgy
              </h1>
              <p class="text-lg  pt-4 text-grey-600">
                Consectetur ipiscing elit tellus ullam corper mattis
              </p>
              <div className=" flex  mt-2">
                <h1 class="text-grey text-4xl font-bold">06</h1>
              </div>
              <div className="flex justify-between">
                <a href="/">
                  <h1 class="text-base text-grey-600 font-bold">
                    View Details
                  </h1>
                </a>
                <a href="/">
                  <p class="text-base text-grey-600 font-bold rounded-md  bg-newshade29 h-6 w-8 text-center">
                    →
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${servicebackground1})`,
              width: "100%",
              height: "770px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto pt-16 px-8">
              <p className="text-base font-semibold text-darkblack ">
                OUR BENEFIT
              </p>
              <h1 className="text-4xl font-bold text-darkblack pt-4">
                Always powering your<br></br> business for over 30 years
              </h1>

              <div className="grid grid-cols-2 mt-8">
                <div className="hover-parent">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon1} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        01
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Expert Engineers
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes consectetur adipisicing elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent ml-4">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon2} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        02
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Quality Control System
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes consectetur adipisicing elitid.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 mt-4">
                <div className="hover-parent ">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon3} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        03
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Satisfaction Guarantee
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes consectetur adipisicing elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className=" ml-4">
                  <div className="hover-parent">
                    <div className="flex bg-white p-4 rounded-md">
                      <div>
                        <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                          <img class="w-8" src={serviceicon3} />
                        </div>
                        <p class="text-3xl font-bold text-grey pt-4 text-center">
                          04
                        </p>
                      </div>
                      <div className="ml-6">
                        <h1 class="text-base font-bold text-darkblack pt-6">
                          International Business
                        </h1>
                        <p class="text-sm text-grey-600 pt-2">
                          Modern society consumes<br></br> consectetur
                          adipisicing elitid.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 mt-4">
                <div className="hover-parent ">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon1} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        05
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Unrivalled Workmanship
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent ml-4">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon2} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        06
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        High Professional Staff
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk container mx-auto px-8">
        <div>
          <div
            style={{
              backgroundImage: `url(${servicebackground2})`,
              width: "100%",
              height: "1300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-12 p-6">
              <p className="text-base text-yellow font-semibold">
                WORK PROCESS
              </p>
              <h1 className="text-4xl font-bold text-darkblack pt-4">
                Project implementation process
              </h1>

              <div className="grid grid-cols-2 mt-10 ">
                <div>
                  <div className="border-new pb-12">
                    <div className="flex">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="text-lg text-center text-darkblack pt-2">
                          01
                        </p>
                      </div>
                      <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="text-2xl font-semibold pt-4 text-darkblack">
                      Design and Planning
                    </h1>
                    <p className="text-lg text-grey pt-2 ">
                      Industry standard dummy text took since the when an
                      unknown printer galley type scrambled.
                    </p>
                  </div>

                  <div className="border-new1 pt-16">
                    <div className="flex">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="text-lg text-center text-darkblack pt-2">
                          03
                        </p>
                      </div>
                      <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="text-2xl font-semibold pt-4 text-darkblack">
                      Testing and Quality
                    </h1>
                    <p className="text-lg text-grey pt-2">
                      Industry standard dummy text took since the when an
                      unknown printer galley type scrambled.
                    </p>
                  </div>
                </div>

                <div className="">
                  <div className="border-b-bottom pl-6 pb-12">
                    <div className="flex">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="text-lg text-center text-darkblack pt-2">
                          02
                        </p>
                      </div>
                      <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="text-2xl font-semibold pt-4 text-darkblack">
                      Component Sourcing
                    </h1>
                    <p className="text-lg text-grey pt-2">
                      Industry standard dummy text took since the when an
                      unknown printer galley type scrambled.
                    </p>
                  </div>

                  <div className="mt-16 pl-6">
                    <div className="flex ">
                      <div className="bg-newshade32 w-10 h-12 capsule">
                        <p className="text-lg text-center text-darkblack pt-2">
                          04
                        </p>
                      </div>
                      <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
                    </div>
                    <h1 class="text-2xl font-semibold pt-4 text-darkblack">
                      Final Assembly
                    </h1>
                    <p className="text-lg text-grey pt-2">
                      Industry standard dummy text took since the when an
                      unknown printer galley type scrambled.
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div className=" mt-12">
                  <div className="bg-white p-6 highlight-border">
                    <h1 className="text-3xl font-bold text-darkblack">
                      Have any Question?
                    </h1>
                    <p className="text-base pt-4 text-grey-600">
                      The point of using Lorem Ipsum is that it has more-or-less
                      packages normal point of using.
                    </p>
                    <div className="mt-8">
                      <form>
                        <input
                          class="bg-newshade33 w-12/12 border-3"
                          type="text"
                          id="text"
                          placeholder="Full Name"
                        />
                        <input
                          class="bg-newshade33 w-12/12 mt-4 border-3 "
                          type="text"
                          id="text"
                          placeholder="Email Address"
                        />
                        <input
                          class="bg-newshade33 w-12/12 mt-4 border-3"
                          type="number"
                          id="text"
                          placeholder="Phone"
                        />
                        <textarea
                          class="mt-4 bg-newshade33 w-12/12 border-3"
                          rows="4"
                          cols=""
                          placeholder="Write Here..."
                        ></textarea>

                        <div className="center-button">
                          <button class="xl:text-base bg-newshade32 px-8 py-2 mt-4">
                            Send Message ➜
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8 ">
        <p class="text-base text-yellow font-semibold "> PRICING PLAN</p>
        <div className="">
          <h1 className="text-4xl font-bold text-darkblack pt-4">
            Our friendly packages
          </h1>
          <div className=" ">
            <div className="flex mt-4">
              <button className="bg-newshade32 rounded-full px-8 py-2 text-darkblack text-base">
                Montly Plan
              </button>
              <button className="bg-newshade32 rounded-full px-8 py-2 text-darkblack text-base ml-2">
                yearly Plan
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-12 ">
          <div className=" highlight-border">
            <div className="bg-newshade33 p-6">
              <h1 className="text-2xl text-center font-bold text-darkblack">
                Basic Plan
              </h1>
              <p className="text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow text-5xl font-bold text-center pt-8">
                $29.00
              </h1>
              <p className="text-darkblack text-base text-center font-semibold">
                Monthly
              </p>
              <p className="text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">Brand Consistency</p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">
                  Quality Control System
                </p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">
                  International Business
                </p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">Professional Support</p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 px-16 py-3 text-base text-darkblack mt-6 yellow-on-hover1 ">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>

          <div className="ml-2 highlight-border">
            <div className="bg-newshade33 p-6">
              <h1 className="text-2xl text-center font-bold text-darkblack">
                Commercial
              </h1>
              <p className="text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow text-5xl font-bold text-center pt-8">
                $59.00
              </h1>
              <p className="text-darkblack font-semibold text-base text-center">
                Monthly
              </p>
              <p className="text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">Brand Consistency</p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">
                  Quality Control System
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">
                  International Business
                </p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">Professional Support</p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 px-16 py-3 text-base text-darkblack mt-6 bg-newshade32">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>

          <div className=" highlight-border mt-8">
            <div className="bg-newshade33 p-6">
              <h1 className="text-2xl text-center font-bold text-darkblack">
                Enterprise
              </h1>
              <p className="text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow text-5xl font-bold text-center pt-8">
                $89.00
              </h1>
              <p className="text-darkblack font-semibold xl:text-base text-center">
                Monthly
              </p>
              <p className="text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">Brand Consistency</p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">
                  Quality Control System
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">
                  International Business
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">Professional Support</p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 px-16 py-3 text-base text-darkblack mt-6 yellow-on-hover1 ">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-12 container mx-auto px-8">
              <p className="text-base font-semibold text-yellow text-center">
                PARTNERS
              </p>
              <h1 className="text-4xl font-bold text-darkblack pt-4 text-center">
                Partners who trust Industrie
              </h1>
              <div className="grid grid-cols-4 mt-12">
                <div className="bg-white p-12">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo11} />
                  </div>
                </div>

                <div className="bg-white p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-20 " src={logo12} />
                  </div>
                </div>

                <div className="bg-white p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo13} />
                  </div>
                </div>

                <div className="bg-white p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-32 pt-2 " src={logo14} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*mobileview*/}
      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-4 pt-24 ">
              <div>
                <h1 className="text-5xl text-white font-bold ">Services</h1>
                <div className="flex mt-4">
                  <p className="text-base font-bold text-white ">
                    Industrie - Industry and Engineering
                  </p>
                </div>
                <div className="flex">
                  <p className="text-base font-bold text-white  ">
                    {" "}
                    WordPress Theme
                  </p>
                  <p className="text-base1 font-bold text-white ml-2  ">›</p>
                  <p className="text-base font-bold text-white ml-2 ">
                    Services
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 container mx-auto px-4">
        <div className="">
          <div className="image-container">
            <img class="w-11/12" src={industrial4} />
          </div>
          <div className="p-6 hover-div w-11/12">
            <h1 class="text-2xl font-bold  text-darkblack mt-4">
              Best Innovations in metallurgy
            </h1>
            <p class="text-lg pt-4 text-grey-600">
              Consectetur ipiscing elit tellus ullam corper mattis
            </p>
            <div className=" flex  mt-2">
              <h1 class="text-grey text-4xl font-bold">01</h1>
            </div>
            <div className="flex justify-between mt-2">
              <a href="/">
                <h1 class="text-base text-grey-600 font-bold">View Details</h1>
              </a>
              <a href="/">
                <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <div className="image-container">
            <img class="w-11/12" src={industrial5} />
          </div>
          <div className="p-6 hover-div w-11/12">
            <h1 class="text-2xl font-bold  text-darkblack mt-4">
              Best Innovations in metallurgy
            </h1>
            <p class="text-lg pt-4 text-grey-600">
              Consectetur ipiscing elit tellus ullam corper mattis
            </p>
            <div className=" flex  mt-2">
              <h1 class="text-grey text-4xl font-bold">02</h1>
            </div>
            <div className="flex justify-between mt-2">
              <a href="/">
                <h1 class="text-base text-grey-600 font-bold">View Details</h1>
              </a>
              <a href="/">
                <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <div className="image-container">
            <img class="w-11/12" src={industrial6} />
          </div>
          <div className="p-6 hover-div w-11/12">
            <h1 class="text-2xl font-bold  text-darkblack mt-4">
              Best Innovations in metallurgy
            </h1>
            <p class="text-lg pt-4 text-grey-600">
              Consectetur ipiscing elit tellus ullam corper mattis
            </p>
            <div className=" flex  mt-2">
              <h1 class="text-grey text-4xl font-bold">03</h1>
            </div>
            <div className="flex justify-between mt-2">
              <a href="/">
                <h1 class="text-base text-grey-600 font-bold">View Details</h1>
              </a>
              <a href="/">
                <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <div className="image-container">
            <img class="w-11/12" src={industrial7} />
          </div>
          <div className="p-6 hover-div w-11/12">
            <h1 class="text-2xl font-bold  text-darkblack mt-4">
              Best Innovations in metallurgy
            </h1>
            <p class="text-lg pt-4 text-grey-600">
              Consectetur ipiscing elit tellus ullam corper mattis
            </p>
            <div className=" flex  mt-2">
              <h1 class="text-grey text-4xl font-bold">04</h1>
            </div>
            <div className="flex justify-between mt-2">
              <a href="/">
                <h1 class="text-base text-grey-600 font-bold">View Details</h1>
              </a>
              <a href="/">
                <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <div className="image-container">
            <img class="w-11/12" src={industrial8} />
          </div>
          <div className="p-6 hover-div w-11/12">
            <h1 class="text-2xl font-bold  text-darkblack mt-4">
              Best Innovations in metallurgy
            </h1>
            <p class="text-lg pt-4 text-grey-600">
              Consectetur ipiscing elit tellus ullam corper mattis
            </p>
            <div className=" flex  mt-2">
              <h1 class="text-grey text-4xl font-bold">05</h1>
            </div>
            <div className="flex justify-between mt-2">
              <a href="/">
                <h1 class="text-base text-grey-600 font-bold">View Details</h1>
              </a>
              <a href="/">
                <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-6">
          <div className="image-container">
            <img class="w-11/12" src={industrial5} />
          </div>
          <div className="p-6 hover-div w-11/12">
            <h1 class="text-2xl font-bold  text-darkblack mt-4">
              Best Innovations in metallurgy
            </h1>
            <p class="text-lg pt-4 text-grey-600">
              Consectetur ipiscing elit tellus ullam corper mattis
            </p>
            <div className=" flex  mt-2">
              <h1 class="text-grey text-4xl font-bold">06</h1>
            </div>
            <div className="flex justify-between mt-2">
              <a href="/">
                <h1 class="text-base text-grey-600 font-bold">View Details</h1>
              </a>
              <a href="/">
                <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${servicebackground1})`,
              width: "100%",
              height: "1300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto pt-16 px-4">
              <p className="text-base font-semibold text-darkblack ">
                OUR BENEFIT
              </p>
              <h1 className="text-4xl font-bold text-darkblack pt-4">
                Always powering your business for over 30 years
              </h1>

              <div className=" mt-8">
                <div className="hover-parent">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon1} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        01
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Expert Engineers
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes consectetur adipisicing elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent mt-4">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon2} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        02
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Quality Control System
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes consectetur adipisicing elitid.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" mt-4">
                <div className="hover-parent ">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon3} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        03
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Satisfaction Guarantee
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes consectetur adipisicing elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className=" mt-4">
                  <div className="hover-parent">
                    <div className="flex bg-white p-4 rounded-md">
                      <div>
                        <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                          <img class="w-8" src={serviceicon3} />
                        </div>
                        <p class="text-3xl font-bold text-grey pt-4 text-center">
                          04
                        </p>
                      </div>
                      <div className="ml-6">
                        <h1 class="text-base font-bold text-darkblack pt-6">
                          International Business
                        </h1>
                        <p class="text-sm text-grey-600 pt-2">
                          Modern society consumes<br></br> consectetur
                          adipisicing elitid.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" mt-4">
                <div className="hover-parent ">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon1} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        05
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        Unrivalled Workmanship
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="hover-parent mt-4">
                  <div className="flex bg-white p-4 rounded-md">
                    <div>
                      <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                        <img class="w-8" src={serviceicon2} />
                      </div>
                      <p class="text-3xl font-bold text-grey pt-4 text-center">
                        06
                      </p>
                    </div>
                    <div className="ml-6">
                      <h1 class="text-base font-bold text-darkblack pt-6">
                        High Professional Staff
                      </h1>
                      <p class="text-sm text-grey-600 pt-2">
                        Modern society consumes<br></br> consectetur adipisicing
                        elitid.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade33 pb-16">
        <div className="container mx-auto px-4 pt-12">
          <p className="text-base text-yellow font-semibold">WORK PROCESS</p>
          <h1 className="text-4xl font-bold text-darkblack pt-4">
            Project implementation process
          </h1>

          <div>
            <div className="flex mt-12">
              <div className="bg-newshade32 w-10 h-12 capsule">
                <p className="text-lg text-center text-darkblack pt-2">01</p>
              </div>
              <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
            </div>
            <h1 class="text-2xl font-semibold pt-4 text-darkblack">
              Design and Planning
            </h1>
            <p className="text-lg text-grey pt-4 ">
              Industry standard dummy text took since the when an unknown
              printer galley type scrambled.
            </p>
          </div>

          <div>
            <div className="flex mt-8">
              <div className="bg-newshade32 w-10 h-12 capsule">
                <p className="text-lg text-center text-darkblack pt-2">02</p>
              </div>
              <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
            </div>
            <h1 class="text-2xl font-semibold pt-4 text-darkblack">
              Component Sourcing
            </h1>
            <p className="text-lg text-grey pt-4 ">
              Industry standard dummy text took since the when an unknown
              printer galley type scrambled.
            </p>
          </div>

          <div>
            <div className="flex mt-8">
              <div className="bg-newshade32 w-10 h-12 capsule">
                <p className="text-lg text-center text-darkblack pt-2">03</p>
              </div>
              <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
            </div>
            <h1 class="text-2xl font-semibold pt-4 text-darkblack">
              Testing and Quality
            </h1>
            <p className="text-lg text-grey pt-4 ">
              Industry standard dummy text took since the when an unknown
              printer galley type scrambled.
            </p>
          </div>

          <div>
            <div className="flex mt-8">
              <div className="bg-newshade32 w-10 h-12 capsule">
                <p className="text-lg text-center text-darkblack pt-2">04</p>
              </div>
              <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
            </div>
            <h1 class="text-2xl font-semibold pt-4 text-darkblack">
              Final Assembly
            </h1>
            <p className="text-lg text-grey pt-4 ">
              Industry standard dummy text took since the when an unknown
              printer galley type scrambled.
            </p>
          </div>

          <div>
            <div className=" mt-12">
              <div className="bg-white p-6 highlight-border">
                <h1 className="text-3xl font-bold text-darkblack">
                  Have any Question?
                </h1>
                <p className="text-base pt-4 text-grey-600">
                  The point of using Lorem Ipsum is that it has more-or-less
                  packages normal point of using.
                </p>
                <div className="mt-8">
                  <form>
                    <input
                      class="bg-newshade33 w-12/12 border-3"
                      type="text"
                      id="text"
                      placeholder="Full Name"
                    />
                    <input
                      class="bg-newshade33 w-12/12 mt-4 border-3 "
                      type="text"
                      id="text"
                      placeholder="Email Address"
                    />
                    <input
                      class="bg-newshade33 w-12/12 mt-4 border-3"
                      type="number"
                      id="text"
                      placeholder="Phone"
                    />
                    <textarea
                      class="mt-4 bg-newshade33 w-12/12 border-3"
                      rows="4"
                      cols=""
                      placeholder="Write Here..."
                    ></textarea>

                    <div className="center-button">
                      <button class="xl:text-base bg-newshade32 px-8 py-2 mt-4">
                        Send Message ➜
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk container mx-auto px-4 ">
        <p class="text-base text-yellow font-semibold "> PRICING PLAN</p>
        <div className="">
          <h1 className="text-4xl font-bold text-darkblack pt-4">
            Our friendly packages
          </h1>
          <div className=" ">
            <div className="flex mt-4">
              <button className="bg-newshade32 rounded-full px-8 py-2 text-darkblack text-base">
                Montly Plan
              </button>
              <button className="bg-newshade32 rounded-full px-8 py-2 text-darkblack text-base ml-2">
                yearly Plan
              </button>
            </div>
          </div>
        </div>

        <div className=" mt-12 ">
          <div className=" highlight-border">
            <div className="bg-newshade33 p-6">
              <h1 className="text-2xl text-center font-bold text-darkblack">
                Basic Plan
              </h1>
              <p className="text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow text-5xl font-bold text-center pt-8">
                $29.00
              </h1>
              <p className="text-darkblack text-base text-center font-semibold">
                Monthly
              </p>
              <p className="text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">Brand Consistency</p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">
                  Quality Control System
                </p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">
                  International Business
                </p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">Professional Support</p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 px-16 py-3 text-base text-darkblack mt-6 yellow-on-hover1 ">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>

          <div className="mt-8 highlight-border">
            <div className="bg-newshade33 p-6">
              <h1 className="text-2xl text-center font-bold text-darkblack">
                Commercial
              </h1>
              <p className="text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow text-5xl font-bold text-center pt-8">
                $59.00
              </h1>
              <p className="text-darkblack font-semibold text-base text-center">
                Monthly
              </p>
              <p className="text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">Brand Consistency</p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">
                  Quality Control System
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">
                  International Business
                </p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">Professional Support</p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 px-16 py-3 text-base text-darkblack mt-6 bg-newshade32">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>

          <div className=" highlight-border mt-8">
            <div className="bg-newshade33 p-6">
              <h1 className="text-2xl text-center font-bold text-darkblack">
                Enterprise
              </h1>
              <p className="text-base text-grey-600 text-center pt-2">
                What you will get in this plan !!
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <h1 className="text-yellow text-5xl font-bold text-center pt-8">
                $89.00
              </h1>
              <p className="text-darkblack font-semibold xl:text-base text-center">
                Monthly
              </p>
              <p className="text-base text-grey-600 pt-2 text-center">
                This plan includes online consultation options for all users
              </p>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-8">
                <p className="text-base text-darkblack ">
                  Prepare Documentation
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>
              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">Brand Consistency</p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">
                  Quality Control System
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-darkblack ">
                  International Business
                </p>
                <p className="text-yellow text-lg">✓</p>
              </div>

              <div className="flex justify-between mt-4">
                <p className="text-base text-grey-600 ">Professional Support</p>
                <p className="text-grey-600 text-lg">☓</p>
              </div>

              <a href="/">
                <button className="border-3 px-16 py-3 text-base text-darkblack mt-6 yellow-on-hover1 ">
                  Get Start Now ➜
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-12 container mx-auto px-4">
              <p className="text-base font-semibold text-yellow text-center">
                PARTNERS
              </p>
              <h1 className="text-4xl font-bold text-darkblack pt-4 text-center">
                Partners who trust Industrie
              </h1>
              <div className="grid grid-cols-2 mt-8">
                <div className="bg-white p-12">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo11} />
                  </div>
                </div>

                <div className="bg-white p-12 ml-4">
                  <div className="center-button image-container">
                    <img class="w-32 pt-2 " src={logo14} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*endd*/}

      <Footer2 />
    </>
  );
}
