import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer2 from "components/Footers/Footer2";
import banner from "assets/img/banner.jpg";

import industrialslider2 from "assets/img/industrialslider2.png";
import contactbackground1 from "assets/img/contactbackground1.jpg";
import contactbackground2 from "assets/img/contactbackground2.png";
import contactbackground3 from "assets/img/contactbackground3.jpg";
import contactbackground4 from "assets/img/contactbackground4.jpg";
import contactbackground5 from "assets/img/contactbackground5.jpg";
import mail from "assets/img/mail.png";
import telephone from "assets/img/telephone.png";
import alarm from "assets/img/alarm.png";

export default function Homepage1() {
  return (
    <section>
      <Indexnavbar2 />

      <section className="xl:block lg:block md:hidden sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "500px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto xl:px-4 xl:pt-48 lg:px-8 lg:pt-56">
              <div>
                <h1 className="xl:text-5xl text-white font-bold lg:text-4xl">Contact</h1>
                <div className="flex xl:mt-4 lg:mt-4">
                  <p className="xl:text-sm font-bold text-white lg:text-sm">
                    Industrie - Industry and Engineering WordPress Theme
                  </p>
                  <p className="xl:text-base1 font-bold text-white xl:ml-2 xl:-mt-2 lg:text-base1 lg:ml-2">
                    ›
                  </p>
                  <p className="xl:text-sm font-bold text-white xl:ml-2 lg:text-sm lg:ml-2">
                    Contact
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-16 grotesk container mx-auto xl:px-4">
        <p class="xl:text-base font-bold text-yellow text-center">BRANCH</p>
        <h1 class="xl:text-4xl font-bold text-darkblack xl:pt-4 text-center">
          Global Office
        </h1>
        <div className="grid grid-cols-3 mt-12">
          <div className="custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground3})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="xl:pt-8 ">
                  <div className="xl:p-6">
                    <p
                      class="xl:text-3xl
             text-darkblack font-bold "
                    >
                      San Francisco
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="xl:text-base text-grey-600 xl:ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="xl:text-base text-grey-600 xl:ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="xl:text-base text-grey-600 xl:ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="xl:text-base text-grey-600 xl:px-24 xl:py-3 border-3 xl:mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-4 custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground4})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="xl:pt-8 ">
                  <div className="xl:p-6">
                    <p
                      class="xl:text-3xl
             text-darkblack font-bold "
                    >
                      California City
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="xl:text-base text-grey-600 xl:ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="xl:text-base text-grey-600 xl:ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="xl:text-base text-grey-600 xl:ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="xl:text-base text-grey-600 xl:px-24 xl:py-3 border-3 xl:mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-4 custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground5})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="xl:pt-8 ">
                  <div className="xl:p-6">
                    <p
                      class="xl:text-3xl
             text-darkblack font-bold "
                    >
                      New York City
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="xl:text-base text-grey-600 xl:ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="xl:text-base text-grey-600 xl:ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="xl:text-base text-grey-600 xl:ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="xl:text-base text-grey-600 xl:px-24 xl:py-3 border-3 xl:mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden grotesk container mx-auto xl:px-32 xl:mt-20 ">
        <div className="bg-newshade33 pb-16 border highlight-border">
          <p class="xl:text-4xl text-center xl:pt-16 text-darkblack font-bold">
            Get in Touch
          </p>
          <p class="xl:text-base xl:pt-4 text-grey-600 text-center">
            The point of using Lorem Ipsum is that it has more-or-less packages
            normal <br></br> make a type specimen book it has survived
          </p>
          <div className="">
            <form className="xl:px-32 mt-8 ">
              <div className="flex ">
                <input
                  class="bg-white border-3"
                  type="text"
                  id="text"
                  placeholder="Full Name"
                />
                <input
                  class="bg-white ml-8 border-3"
                  type="text"
                  id="text"
                  placeholder="Email Address"
                />
                <input
                  class="bg-white ml-8 border-3"
                  type="number"
                  id="text"
                  placeholder="Phone"
                />
              </div>
              <textarea
                class="mt-6 bg-white border-3"
                rows="6"
                cols="85"
                placeholder="Write Here..."
              ></textarea>

              <div className="center-button">
                <button class="xl:text-base bg-newshade32 xl:px-8 xl:py-2 xl:mt-6">
                  Send Message ➜
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden grotesk mt-4">
        <div className="">
          <div className=" ">
            <div className="xl:mt-0 lg:mt-64 md:mt-72 mt-100">
              <iframe
                className="xl:w-full rounded-md xl:h-360 lg:w-990 lg:h-250 "
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.593143237906!2d80.25859562454797!3d13.061549662900882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526707ca7b3ea3%3A0xed7f3109bee70e4f!2sSpencer%20Plaza!5e0!3m2!1sen!2sin!4v1708425857317!5m2!1sen!2sin"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {/*landscapeview*/}
      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-12 grotesk container mx-auto px-8">
        <p class="text-base font-bold text-yellow text-center">BRANCH</p>
        <h1 class="text-4xl font-bold text-darkblack pt-4 text-center">
          Global Office
        </h1>
        <div className="grid grid-cols-3 mt-12">
          <div className="custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground3})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-8 ">
                  <div className="p-6">
                    <p
                      class="text-3xl
             text-darkblack font-bold "
                    >
                      San Francisco
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="text-base text-grey-600 px-16 py-3 border-3 mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-4 custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground4})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-8 ">
                  <div className="p-6">
                    <p
                      class="text-3xl
             text-darkblack font-bold "
                    >
                      California City
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="text-base text-grey-600 px-16 py-3 border-3 mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-4 custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground5})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-8 ">
                  <div className="p-6">
                    <p
                      class="text-3xl
             text-darkblack font-bold "
                    >
                      New York City
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="text-base text-grey-600 px-16 py-3 border-3 mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden grotesk container mx-auto px-20 mt-16 ">
        <div className="bg-newshade33 pb-16 highlight-border  ">
          <p class="text-4xl text-center pt-16 text-darkblack font-bold">
            Get in Touch
          </p>
          <p class="text-base pt-4 text-grey-600 text-center">
            The point of using Lorem Ipsum is that it has more-or-less packages
            normal <br></br> make a type specimen book it has survived
          </p>
          <div className="">
            <form className="px-20 mt-8 ">
              <div className="flex ">
                <input
                  class="bg-white border-3 text-grey"
                  type="text"
                  id="text"
                  placeholder="Full Name"
                />
                <input
                  class="bg-white ml-8 border-3"
                  type="text"
                  id="text"
                  placeholder="Email Address"
                />
                <input
                  class="bg-white ml-8 border-3"
                  type="number"
                  id="text"
                  placeholder="Phone"
                />
              </div>
              <textarea
                class="mt-6 bg-white border-3"
                rows="6"
                cols="87"
                placeholder="Write Here..."
              ></textarea>

              <div className="center-button">
                <button class="xl:text-base bg-newshade32 px-8 py-2 mt-6">
                  Send Message ➜
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden grotesk mt-4 pb-16">
        <div className="">
          <div className="">
            <div className="">
              <iframe
                className="w-12/12 h-96 rounded-md  "
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.593143237906!2d80.25859562454797!3d13.061549662900882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526707ca7b3ea3%3A0xed7f3109bee70e4f!2sSpencer%20Plaza!5e0!3m2!1sen!2sin!4v1708425857317!5m2!1sen!2sin"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {/*tabview*/}
      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-8 pt-36 ">
              <div>
                <h1 className="text-5xl text-white font-bold ">Contact</h1>
                <div className="flex mt-4">
                  <p className="text-sm font-bold text-white ">
                    Industrie - Industry and Engineering WordPress Theme
                  </p>
                  <p className="text-base1 font-bold text-white ml-2  ">›</p>
                  <p className="text-sm font-bold text-white ml-2 ">Contact</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-12 grotesk container mx-auto px-8">
        <p class="text-base font-bold text-yellow text-center">BRANCH</p>
        <h1 class="text-4xl font-bold text-darkblack pt-4 text-center">
          Global Office
        </h1>
        <div className="grid grid-cols-2 mt-12">
          <div className="custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground3})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-8 ">
                  <div className="p-6">
                    <p
                      class="text-3xl
             text-darkblack font-bold "
                    >
                      San Francisco
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="text-base text-grey-600 px-16 py-3 border-3 mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-4 custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground4})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-8 ">
                  <div className="p-6">
                    <p
                      class="text-3xl
             text-darkblack font-bold "
                    >
                      California City
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="text-base text-grey-600 px-16 py-3 border-3 mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground5})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-8 ">
                  <div className="p-6">
                    <p
                      class="text-3xl
             text-darkblack font-bold "
                    >
                      New York City
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="text-base text-grey-600 px-16 py-3 border-3 mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk container mx-auto px-8 mt-16 ">
        <div className="bg-newshade33 pb-16 highlight-border  ">
          <p class="text-4xl text-center pt-16 text-darkblack font-bold">
            Get in Touch
          </p>
          <p class="text-base pt-4 text-grey-600 text-center">
            The point of using Lorem Ipsum is that it has more-or-less packages
            normal <br></br> make a type specimen book it has survived
          </p>
          <div className="">
            <form className="px-4 mt-8 ">
              <div className=" ">
                <input
                  class="bg-white border-3 text-grey w-12/12"
                  type="text"
                  id="text"
                  placeholder="Full Name"
                />
                <input
                  class="bg-white  border-3 w-12/12 mt-4"
                  type="text"
                  id="text"
                  placeholder="Email Address"
                />
                <input
                  class="bg-white  border-3 w-12/12 mt-4"
                  type="number"
                  id="text"
                  placeholder="Phone"
                />
              </div>
              <textarea
                class="bg-white border-3 w-12/12 mt-4"
                rows="6"
                cols="87"
                placeholder="Write Here..."
              ></textarea>

              <div className="center-button">
                <button class="text-base bg-newshade32 px-8 py-2 mt-6">
                  Send Message ➜
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk mt-4 pb-16">
        <div className="">
          <div className="">
            <div className="">
              <iframe
                className="w-12/12 h-96 rounded-md  "
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.593143237906!2d80.25859562454797!3d13.061549662900882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526707ca7b3ea3%3A0xed7f3109bee70e4f!2sSpencer%20Plaza!5e0!3m2!1sen!2sin!4v1708425857317!5m2!1sen!2sin"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {/*mobileview*/}
      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-4 pt-24 ">
              <div>
                <h1 className="text-5xl text-white font-bold ">Contact</h1>
                <div className="flex mt-4">
                  <p className="text-base font-bold text-white ">
                    Industrie - Industry and Engineering
                  </p>
                </div>
                <div className="flex">
                  <p className="text-base font-bold text-white  ">
                    {" "}
                    WordPress Theme
                  </p>
                  <p className="text-base1 font-bold text-white ml-2  ">›</p>
                  <p className="text-base font-bold text-white ml-2 ">Contact</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-12 grotesk container mx-auto px-4">
        <p class="text-base font-bold text-yellow text-center">BRANCH</p>
        <h1 class="text-4xl font-bold text-darkblack pt-4 text-center">
          Global Office
        </h1>
        <div className=" mt-12">
          <div className="custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground3})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-8 ">
                  <div className="p-6">
                    <p
                      class="text-3xl
             text-darkblack font-bold "
                    >
                      San Francisco
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="text-base text-grey-600 px-16 py-3 border-3 mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground4})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-8 ">
                  <div className="p-6">
                    <p
                      class="text-3xl
             text-darkblack font-bold "
                    >
                      California City
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="text-base text-grey-600 px-16 py-3 border-3 mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 custom-divvv">
            <div className="">
              <div
                style={{
                  backgroundImage: `url(${contactbackground5})`,

                  width: "100%",
                  height: "530px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-8 ">
                  <div className="p-6">
                    <p
                      class="text-3xl
             text-darkblack font-bold "
                    >
                      New York City
                    </p>
                    <div className="flex mt-6">
                      <div>
                        <img class="w-4 mt-2" src={telephone} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        +32 (0) 73 240 396
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={mail} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        industrie.info@gmail.com
                      </p>
                    </div>

                    <div className="flex mt-2">
                      <div>
                        <img class="w-4 mt-2" src={alarm} />
                      </div>
                      <p class="text-base text-grey-600 ml-4 yellow-on-hover">
                        Mon - Sat 10.00 - 18.00
                      </p>
                    </div>

                    <button className="text-base text-grey-600 px-16 py-3 border-3 mt-6 yellow-on-hover">
                      View Location ➜
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk container mx-auto px-4 mt-16 ">
        <div className="bg-newshade33 pb-16 highlight-border  ">
          <p class="text-4xl text-center pt-16 text-darkblack font-bold">
            Get in Touch
          </p>
          <p class="text-base pt-4 text-grey-600 text-center">
            The point of using Lorem Ipsum is that it has more-or-less packages
            normal  make a type specimen book it has survived
          </p>
          <div className="">
            <form className="px-4 mt-8 ">
              <div className=" ">
                <input
                  class="bg-white border-3 text-grey w-12/12"
                  type="text"
                  id="text"
                  placeholder="Full Name"
                />
                <input
                  class="bg-white  border-3 w-12/12 mt-4"
                  type="text"
                  id="text"
                  placeholder="Email Address"
                />
                <input
                  class="bg-white  border-3 w-12/12 mt-4"
                  type="number"
                  id="text"
                  placeholder="Phone"
                />
              </div>
              <textarea
                class="bg-white border-3 w-12/12 mt-4"
                rows="6"
                cols="87"
                placeholder="Write Here..."
              ></textarea>

              <div className="center-button">
                <button class="text-base bg-newshade32 px-8 py-2 mt-6">
                  Send Message ➜
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk mt-4 pb-16">
        <div className="">
          <div className="">
            <div className="">
              <iframe
                className="w-12/12 h-96 rounded-md  "
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.593143237906!2d80.25859562454797!3d13.061549662900882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526707ca7b3ea3%3A0xed7f3109bee70e4f!2sSpencer%20Plaza!5e0!3m2!1sen!2sin!4v1708425857317!5m2!1sen!2sin"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      {/*endd*/}
      <Footer2 />
    </section>
  );
}
