import React, { useState } from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import serviceicon1 from "assets/img/serviceicon1.png";
import serviceicon2 from "assets/img/serviceicon2.png";
import serviceicon3 from "assets/img/serviceicon3.png";
import industrialslider2 from "assets/img/industrialslider2.png";

export default function Employer() {
  return (
    <>
      <Indexnavbar2 fixed />
      <section className="xl:block lg:block md:hidden sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "500px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto xl:px-4 xl:pt-48 lg:px-8 lg:pt-56">
              <div>
                <h1 className="xl:text-5xl text-white font-bold lg:text-4xl">
                  Work Process Elements
                </h1>
                <div className="flex xl:mt-4 lg:mt-4">
                  <p className="xl:text-sm font-bold text-white lg:text-sm">
                    Industrie - Industry and Engineering WordPress Theme
                  </p>
                  <p className="xl:text-base1 font-bold text-white xl:ml-2 xl:-mt-2 lg:text-base1 lg:ml-2">
                    ›
                  </p>
                  <p className="xl:text-sm font-bold text-white xl:ml-2 lg:text-sm lg:ml-2">
                    Work Process Elements
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk container mx-auto xl:px-4">
        <p class="xl:text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="xl:text-4xl font-bold text-darkblack xl:pt-2 font-bold text-center">
          Work Process Style 01
        </h1>
        <div className="grid grid-cols-3 mt-12">
          <div className="custom-div">
            <div className="bg-newshade33 xl:p-8">
              <h1 className="xl:text-base1 font-bold text-darkblack">
                Analysis of the client's<br></br> objectives & needs
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="xl:w-16" src={serviceicon1} />
                </div>
                <h1 className="xl:text-7xl font-bold text-grey">01</h1>
              </div>
              <p className="xl:text-base text-grey-600 xl:pt-4">
                Our approach to project management is<br></br> grounded in
                practical experience and <br></br>prioritises open
                communication.
              </p>
            </div>
          </div>

          <div className="custom-div ml-2">
            <div className="bg-newshade33 xl:p-8">
              <h1 className="xl:text-base1 font-bold text-darkblack">
                Engineering project study &<br></br> solution design
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="xl:w-16" src={serviceicon2} />
                </div>
                <h1 className="xl:text-7xl font-bold text-grey">02</h1>
              </div>
              <p className="xl:text-base text-grey-600 xl:pt-4">
                Together, we identify the industrial data<br></br> automation
                project's general scope and<br></br>the components needed to
                produce.
              </p>
            </div>
          </div>

          <div className="custom-div ml-2">
            <div className="bg-newshade33 xl:p-8">
              <h1 className="xl:text-base1 font-bold text-darkblack">
                Solution execution phase<br></br> and installation done
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="xl:w-16" src={serviceicon3} />
                </div>
                <h1 className="xl:text-7xl font-bold text-grey">03</h1>
              </div>
              <p className="xl:text-base text-grey-600 xl:pt-4">
                We collaborate closely on with you and<br></br> examine the
                strategy and install and <br></br>integrate the mechanical.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk bg-newshade33 xl:pb-16">
        <div className="container mx-auto xl:px-4">
          <p class="xl:text-base text-yellow text-center xl:pt-16 font-semibold">
            {" "}
            WORK PROCESS
          </p>
          <h1 className="xl:text-4xl font-bold text-darkblack xl:pt-2 font-bold text-center">
            Work Process Style 02
          </h1>
          <div className="grid grid-cols-4 mt-12">
            <div className="xl:p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="xl:text-lg text-center text-darkblack pt-2">
                    01
                  </p>
                </div>
                <p class="xl:text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="xl:text-base1 text-darkblack font-bold xl:pt-4">
                Research
              </h1>
              <p className="xl:text-lg text-grey-600 xl:pt-4 justify-content">
                Industry standard<br></br> dummy text took since<br></br> the
                when an unknown<br></br> printer galley type<br></br> scrambled.
              </p>
            </div>

            <div className="xl:p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="xl:text-lg text-center text-darkblack pt-2">
                    02
                  </p>
                </div>
                <p class="xl:text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="xl:text-base1 text-darkblack font-bold xl:pt-4">
                Brainstorming
              </h1>
              <p className="xl:text-lg text-grey-600 xl:pt-4">
                Industry standard<br></br> dummy text took since<br></br> the
                when an unknown<br></br> printer galley type<br></br> scrambled.
              </p>
            </div>

            <div className="xl:p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="xl:text-lg text-center text-darkblack pt-2">
                    03
                  </p>
                </div>
                <p class="xl:text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="xl:text-base1 text-darkblack font-bold xl:pt-4">
                Finalize
              </h1>
              <p className="xl:text-lg text-grey-600 xl:pt-4">
                Industry standard<br></br> dummy text took since<br></br> the
                when an unknown<br></br> printer galley type<br></br> scrambled.
              </p>
            </div>

            <div className="xl:p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="xl:text-lg text-center text-darkblack pt-2">
                    04
                  </p>
                </div>
                <p class="xl:text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="xl:text-base1 text-darkblack font-bold xl:pt-4">
                Launch
              </h1>
              <p className="xl:text-lg text-grey-600 xl:pt-4">
                Industry standard<br></br> dummy text took since<br></br> the
                when an unknown<br></br> printer galley type<br></br> scrambled.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk container mx-auto xl:px-4">
        <p class="xl:text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="xl:text-4xl font-bold text-darkblack xl:pt-2 font-bold text-center">
          Work Process Style 03
        </h1>
        <div className="grid grid-cols-3 mt-12">
          <div className="hover-container">
            <div className="bg-newshade33 xl:p-8  ">
              <h1 className="xl:text-base1 font-bold text-darkblack  ">
                Preparation of project<br></br> documents
              </h1>
              <p class="xl:text-base text-grey-600 pt-4">
                Page when looking at its layout. The<br></br> point of using
                Lorem Ipsum is that it has<br></br> a more-less normal
                distribution.
              </p>
              <div className="  h-10 w-10  mt-4 xl:ml-72 rounded-md">
                <a href="/">
                  <p class="xl:text-3xl  hover-target font-bold text-yellow text-center pt-2">
                    01
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="ml-2 ">
            <div className="hover-container">
              <div className="bg-newshade33 xl:p-8">
                <h1 className="xl:text-base1 font-bold text-darkblack">
                  Creation of a project with a<br></br> team on time
                </h1>
                <p class="xl:text-base text-grey-600 pt-4">
                  Page when looking at its layout. The<br></br> point of using
                  Lorem Ipsum is that it has<br></br> a more-less normal
                  distribution.
                </p>
                <div className=" h-10 w-10  mt-4 xl:ml-72 rounded-md">
                  <a href="/">
                    <p class="xl:text-3xl font-bold text-yellow text-center pt-2 hover-target ">
                      02
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-2 hover-container">
            <div className="bg-newshade33 xl:p-8">
              <h1 className="xl:text-base1 font-bold text-darkblack">
                Completion of the project<br></br> and payment
              </h1>
              <p class="xl:text-base text-grey-600 pt-4">
                Page when looking at its layout. The<br></br> point of using
                Lorem Ipsum is that it has<br></br> a more-less normal
                distribution.
              </p>
              <div className="  h-10 w-10  mt-4 xl:ml-72 rounded-md">
                <a href="/">
                  <p class="xl:text-3xl font-bold text-yellow text-center pt-2  hover-target ">
                    03
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk bg-newshade33 xl:pb-16">
        <div className="container mx-auto xl:px-4">
          <p class="xl:text-base text-yellow text-center xl:pt-16 font-semibold">
            {" "}
            WORK PROCESS
          </p>
          <h1 className="xl:text-4xl font-bold text-darkblack xl:pt-2 font-bold text-center">
            Work Process Style 04
          </h1>

          <div className="flex flex-wrap mt-16 xl:w-12/12">
            <div className="xl:w-2/12">
              <div className="bg-white  h-10 w-10 border-line">
                <p class="xl:text-lg text-yellow text-center pt-2">01</p>
              </div>
              <div className="bg-white  h-10 w-10 mt-12 border-line">
                <p class="xl:text-lg text-yellow text-center pt-1 ">02</p>
              </div>
              <div className="bg-white  h-10 w-10 mt-12 border-line">
                <p class="xl:text-lg text-yellow text-center pt-1 ">03</p>
              </div>
              <div className="bg-white  h-10 w-10 mt-12 border-line">
                <p class="xl:text-lg text-yellow text-center pt-1 ">04</p>
              </div>
            </div>

            <div className="xl:w-4/12 xl:-ml-16">
              <h1 className="xl:text-lg text-darkblack font-semibold pt-2">
                Product Design and Planning
              </h1>
              <h1 className="xl:text-lg text-darkblack font-semibold pt-14">
                Component Sourcing and Procurement
              </h1>
              <h1 className="xl:text-lg text-darkblack font-semibold pt-14">
                Testing and Quality Control
              </h1>
              <h1 className="xl:text-lg text-darkblack font-semibold pt-14">
                Final Assembly and Integration
              </h1>
            </div>
            <div className="xl:w-6/12">
              <p className="xl:text-base text-grey-600 pt-2">
                The point of using Lorem Ipsum is that it has more-or-less
                normal.
              </p>
              <p className="xl:text-base text-grey-600 pt-16">
                Packages and web page editors now use as their default model.
              </p>
              <p className="xl:text-base text-grey-600 pt-15">
                Packages and web page editors now use as their default model.
              </p>
              <p className="xl:text-base text-grey-600 pt-15">
                The standard chunk of Lorem Ipsum used since the 1500s is
                reproduced below.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk container mx-auto xl:px-4 xl:pb-16">
        <p class="xl:text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="xl:text-4xl font-bold text-darkblack xl:pt-2 font-bold text-center">
          Work Process Style 05
        </h1>

        <div className="grid grid-cols-3 mt-8">
          <div className="hover-parent ">
            <div className="flex bg-newshade33 xl:p-6 rounded-md">
              <div>
                <div className="bg-newshade11 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon1} />
                </div>
                <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                  01
                </p>
              </div>
              <div className="xl:ml-6">
                <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                  Expert Engineers
                </h1>
                <p class="xl:text-base text-grey-600 pt-2">
                  Modern society consumes<br></br> consectetur adipisicing
                  elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent ml-2">
            <div className="flex bg-newshade33 xl:p-6 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon2} />
                </div>
                <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                  02
                </p>
              </div>
              <div className="xl:ml-6">
                <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                  Quality Control System
                </h1>
                <p class="xl:text-base text-grey-600 pt-2">
                  Modern society consumes<br></br> consectetur adipisicing
                  elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent ml-2">
            <div className="flex bg-newshade33 xl:p-6 rounded-md">
              <div>
                <div className="bg-newshade33 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon3} />
                </div>
                <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                  03
                </p>
              </div>
              <div className="xl:ml-6">
                <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                  Satisfaction Guarantee
                </h1>
                <p class="xl:text-base text-grey-600 pt-2">
                  Modern society consumes<br></br> consectetur adipisicing
                  elitid.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 mt-4">
          <div className="hover-parent">
            <div className="flex bg-newshade33 xl:p-6 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon3} />
                </div>
                <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                  04
                </p>
              </div>
              <div className="xl:ml-6">
                <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                  International Business
                </h1>
                <p class="xl:text-base text-grey-600 pt-2">
                  Modern society consumes<br></br> consectetur adipisicing
                  elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent ml-2">
            <div className="flex bg-newshade33 xl:p-6 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon1} />
                </div>
                <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                  05
                </p>
              </div>
              <div className="xl:ml-6">
                <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                  Unrivalled Workmanship
                </h1>
                <p class="xl:text-base text-grey-600 pt-2">
                  Modern society consumes<br></br> consectetur adipisicing
                  elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent ml-2">
            <div className="flex bg-newshade33 xl:p-6 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon2} />
                </div>
                <p class="xl:text-3xl font-bold text-grey pt-4 text-center">
                  06
                </p>
              </div>
              <div className="xl:ml-6">
                <h1 class="xl:text-base1 font-bold text-darkblack pt-4">
                  High Professional Staff
                </h1>
                <p class="xl:text-base text-grey-600 pt-2">
                  Modern society consumes<br></br> consectetur adipisicing
                  elitid.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*landscapeview*/}
      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-16 grotesk container mx-auto px-8">
        <p class="text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="text-4xl font-bold text-darkblack pt-2 font-bold text-center">
          Work Process Style 01
        </h1>
        <div className="grid grid-cols-3 mt-12">
          <div className="custom-div">
            <div className="bg-newshade33 p-8">
              <h1 className="text-base1 font-bold text-darkblack">
                Analysis of the client'sobjectives & needs
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="w-16" src={serviceicon1} />
                </div>
                <h1 className="text-5xl font-bold text-grey">01</h1>
              </div>
              <p className="text-base text-grey-600 pt-4">
                Our approach to project management is grounded in practical
                experience and prioritises open communication.
              </p>
            </div>
          </div>

          <div className="custom-div ml-2">
            <div className="bg-newshade33 p-6">
              <h1 className="text-base1 font-bold text-darkblack">
                Engineering project study & solution design
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="w-16" src={serviceicon2} />
                </div>
                <h1 className="text-5xl font-bold text-grey">02</h1>
              </div>
              <p className="text-base text-grey-600 pt-8">
                Together, we identify the industrial data automation project's
                general scope and the components needed to produce.
              </p>
            </div>
          </div>

          <div className="custom-div ml-2">
            <div className="bg-newshade33 p-8">
              <h1 className="text-base1 font-bold text-darkblack">
                Solution execution phase and installation done
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="w-16" src={serviceicon3} />
                </div>
                <h1 className="text-5xl font-bold text-grey">03</h1>
              </div>
              <p className="text-base text-grey-600 pt-4">
                We collaborate closely on with you and examine the strategy and
                install and integrate the mechanical.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-16 grotesk bg-newshade33 pb-16">
        <div className="container mx-auto px-8">
          <p class="text-base text-yellow text-center pt-16 font-semibold">
            {" "}
            WORK PROCESS
          </p>
          <h1 className="text-4xl font-bold text-darkblack pt-2 font-bold text-center">
            Work Process Style 02
          </h1>
          <div className="grid grid-cols-4 mt-12">
            <div className="p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">01</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">Research</h1>
              <p className="text-lg text-grey-600 pt-4 justify-content">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>

            <div className="p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">02</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">
                Brainstorming
              </h1>
              <p className="text-lg text-grey-600 pt-4">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>

            <div className="p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">03</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">Finalize</h1>
              <p className="text-lg text-grey-600 pt-4">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>

            <div className="p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">04</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">Launch</h1>
              <p className="text-lg text-grey-600 pt-4">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-16 grotesk container mx-auto px-8">
        <p class="text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="text-4xl font-bold text-darkblack pt-2 font-bold text-center">
          Work Process Style 03
        </h1>
        <div className="grid grid-cols-3 mt-12">
          <div className="hover-container">
            <div className="bg-newshade33 p-8  ">
              <h1 className="text-base1 font-bold text-darkblack  ">
                Preparation of project documents
              </h1>
              <p class="xl:text-base text-grey-600 pt-4">
                Page when looking at its layout. The point of using Lorem Ipsum
                is that it has a more-less normal distribution.
              </p>
              <div className="  h-10 w-10  mt-4 ml-52 rounded-md">
                <a href="/">
                  <p class="text-3xl  hover-target font-bold text-yellow text-center pt-2">
                    01
                  </p>
                </a>
              </div>
            </div>
          </div>

          <div className="ml-2 ">
            <div className="hover-container">
              <div className="bg-newshade33 p-8">
                <h1 className="text-base1 font-bold text-darkblack">
                  Creation of a project with a team on time
                </h1>
                <p class="text-base text-grey-600 pt-4">
                  Page when looking at its layout. The point of using Lorem
                  Ipsum is that it has a more-less normal distribution.
                </p>
                <div className=" h-10 w-10  mt-4 ml-52 rounded-md">
                  <a href="/">
                    <p class="text-3xl font-bold text-yellow text-center pt-2 hover-target ">
                      02
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-2 hover-container">
            <div className="bg-newshade33 p-8">
              <h1 className="text-base1 font-bold text-darkblack">
                Completion of the project and payment
              </h1>
              <p class="xl:text-base text-grey-600 pt-4">
                Page when looking at its layout. The point of using Lorem Ipsum
                is that it has a more-less normal distribution.
              </p>
              <div className="  h-10 w-10  mt-4 ml-52 rounded-md">
                <a href="/">
                  <p class="text-3xl font-bold text-yellow text-center pt-2  hover-target ">
                    03
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-16 grotesk bg-newshade33 pb-16">
        <div className="container mx-auto px-8">
          <p class="text-base text-yellow text-center pt-16 font-semibold">
            {" "}
            WORK PROCESS
          </p>
          <h1 className="text-4xl font-bold text-darkblack pt-2 font-bold text-center">
            Work Process Style 04
          </h1>

          <div className="flex flex-wrap mt-16 w-12/12">
            <div className="w-2/12">
              <div className="bg-white  h-10 w-10 border-line">
                <p class="text-lg text-yellow text-center pt-2">01</p>
              </div>
              <div className="bg-white  h-10 w-10 mt-12 border-line">
                <p class="text-lg text-yellow text-center pt-1 ">02</p>
              </div>
              <div className="bg-white  h-10 w-10 mt-12 border-line">
                <p class="text-lg text-yellow text-center pt-1 ">03</p>
              </div>
              <div className="bg-white  h-10 w-10 mt-12 border-line">
                <p class="text-lg text-yellow text-center pt-1 ">04</p>
              </div>
            </div>

            <div className="w-4/12 ml-8">
              <h1 className="text-lg text-darkblack font-semibold pt-2">
                Product Design and Planning
              </h1>
              <h1 className="text-lg text-darkblack font-semibold pt-14">
                Component Sourcing and Procurement
              </h1>
              <h1 className="text-lg text-darkblack font-semibold pt-15">
                Testing and Quality Control
              </h1>
              <h1 className="text-lg text-darkblack font-semibold pt-16">
                Final Assembly and Integration
              </h1>
            </div>
            <div className="w-6/12">
              <p className="text-base text-grey-600 pt-2">
                The point of using Lorem Ipsum is that it has more-or-less
                normal.
              </p>
              <p className="text-base text-grey-600 pt-10">
                Packages and web page editors now use as their default model.
              </p>
              <p className="text-base text-grey-600 pt-15">
                Packages and web page editors now use as their default model.
              </p>
              <p className="text-base text-grey-600 pt-15">
                The standard chunk of Lorem Ipsum used since the 1500s is
                reproduced below.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 grotesk container mx-auto px-8 pb-16">
        <p class="text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="text-4xl font-bold text-darkblack pt-2 font-bold text-center">
          Work Process Style 05
        </h1>

        <div className="grid grid-cols-3 mt-8">
          <div className="hover-parent ">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade11 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon1} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">01</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Expert Engineers
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent ml-2">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon2} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">02</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Quality Control System
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent ml-2">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade33 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon3} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">03</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Satisfaction Guarantee
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 mt-4">
          <div className="hover-parent">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon3} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">04</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  International Business
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent ml-2">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon1} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">05</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Unrivalled Workmanship
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent ml-2">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon2} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">06</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  High Professional Staff
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*tabview*/}
      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-8 pt-36 ">
              <div>
                <h1 className="text-5xl text-white font-bold ">
                  Work Process Elements
                </h1>
                <div className="flex mt-4">
                  <p className="text-sm font-bold text-white ">
                    Industrie - Industry and Engineering WordPress Theme
                  </p>
                  <p className="text-base1 font-bold text-white ml-2  ">›</p>
                  <p className="text-sm font-bold text-white ml-2 ">
                    Work Process Elements
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8">
        <p class="text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="text-4xl font-bold text-darkblack pt-2 font-bold text-center">
          Work Process Style 01
        </h1>
        <div className="grid grid-cols-2 mt-12">
          <div className="custom-div">
            <div className="bg-newshade33 p-8">
              <h1 className="text-base1 font-bold text-darkblack">
                Analysis of the client'sobjectives & needs
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="w-16" src={serviceicon1} />
                </div>
                <h1 className="text-5xl font-bold text-grey">01</h1>
              </div>
              <p className="text-base text-grey-600 pt-4">
                Our approach to project management is grounded in practical
                experience and prioritises open communication.
              </p>
            </div>
          </div>

          <div className="custom-div ml-4">
            <div className="bg-newshade33 p-6">
              <h1 className="text-base1 font-bold text-darkblack">
                Engineering project study & solution design
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="w-16" src={serviceicon2} />
                </div>
                <h1 className="text-5xl font-bold text-grey">02</h1>
              </div>
              <p className="text-base text-grey-600 pt-8">
                Together, we identify the industrial data automation project's
                general scope and the components needed to produce.
              </p>
            </div>
          </div>

          <div className="custom-div mt-4">
            <div className="bg-newshade33 p-8">
              <h1 className="text-base1 font-bold text-darkblack">
                Solution execution phase and installation done
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="w-16" src={serviceicon3} />
                </div>
                <h1 className="text-5xl font-bold text-grey">03</h1>
              </div>
              <p className="text-base text-grey-600 pt-4">
                We collaborate closely on with you and examine the strategy and
                install and integrate the mechanical.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk bg-newshade33 pb-16">
        <div className="container mx-auto px-8">
          <p class="text-base text-yellow text-center pt-16 font-semibold">
            {" "}
            WORK PROCESS
          </p>
          <h1 className="text-4xl font-bold text-darkblack pt-2 font-bold text-center">
            Work Process Style 02
          </h1>
          <div className="grid grid-cols-2 mt-12">
            <div className="p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">01</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">Research</h1>
              <p className="text-lg text-grey-600 pt-4 justify-content">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>

            <div className="p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">02</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">
                Brainstorming
              </h1>
              <p className="text-lg text-grey-600 pt-4">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2">
            <div className="p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">03</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">Finalize</h1>
              <p className="text-lg text-grey-600 pt-4">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>

            <div className="p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">04</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">Launch</h1>
              <p className="text-lg text-grey-600 pt-4">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8">
        <p class="text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="text-4xl font-bold text-darkblack pt-2 font-bold text-center">
          Work Process Style 03
        </h1>
        <div className=" mt-12">
          <div className="hover-container">
            <div className="bg-newshade33 p-8  ">
              <h1 className="text-base1 font-bold text-darkblack  ">
                Preparation of project documents
              </h1>
              <p class="xl:text-base text-grey-600 pt-4">
                Page when looking at its layout. The point of using Lorem Ipsum
                is that it has a more-less normal distribution.
              </p>
              <div className="flex justify-end">
                <div className="h-10 w-10 mt-4 mr-4 rounded-md">
                  <a href="/">
                    <p className="text-3xl font-bold text-yellow text-center pt-2 hover-target">
                      01
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 ">
            <div className="hover-container">
              <div className="bg-newshade33 p-8">
                <h1 className="text-base1 font-bold text-darkblack">
                  Creation of a project with a team on time
                </h1>
                <p class="text-base text-grey-600 pt-4">
                  Page when looking at its layout. The point of using Lorem
                  Ipsum is that it has a more-less normal distribution.
                </p>
                <div className="flex justify-end">
                  <div className="h-10 w-10 mt-4 mr-4 rounded-md">
                    <a href="/">
                      <p className="text-3xl font-bold text-yellow text-center pt-2 hover-target">
                        02
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 hover-container">
            <div className="bg-newshade33 p-8">
              <h1 className="text-base1 font-bold text-darkblack">
                Completion of the project and payment
              </h1>
              <p class="xl:text-base text-grey-600 pt-4">
                Page when looking at its layout. The point of using Lorem Ipsum
                is that it has a more-less normal distribution.
              </p>
              <div className="flex justify-end">
                <div className="h-10 w-10 mt-4 mr-4 rounded-md">
                  <a href="/">
                    <p className="text-3xl font-bold text-yellow text-center pt-2 hover-target">
                      03
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk bg-newshade33 pb-16">
        <div className="container mx-auto px-8">
          <p class="text-base text-yellow text-center pt-16 font-semibold">
            {" "}
            WORK PROCESS
          </p>
          <h1 className="text-4xl font-bold text-darkblack pt-2 font-bold text-center">
            Work Process Style 04
          </h1>

          <div className=" mt-16 ">
            <div className="bg-white  h-10 w-10 ">
              <p class="text-lg text-yellow text-center pt-2">01</p>
            </div>
            <h1 className="text-xl text-darkblack font-semibold pt-4">
              Product Design and Planning
            </h1>
            <p className="text-lg text-grey-600 pt-2">
              The point of using Lorem Ipsum is that it has more-or-less normal.
            </p>

            <div className="bg-white  h-10 w-10 mt-8 ">
              <p class="text-lg text-yellow text-center pt-1 ">02</p>
            </div>
            <h1 className="text-sxl text-darkblack font-semibold pt-4">
              Component Sourcing and Procurement
            </h1>
            <p className="text-lg text-grey-600 pt-2">
              Packages and web page editors now use as their default model.
            </p>
            <div className="bg-white  h-10 w-10 mt-8 ">
              <p class="text-lg text-yellow text-center pt-1 ">03</p>
            </div>
            <h1 className="text-xl text-darkblack font-semibold pt-4">
              Testing and Quality Control
            </h1>
            <p className="text-lg text-grey-600 pt-2">
              Packages and web page editors now use as their default model.
            </p>

            <div className="bg-white  h-10 w-10 mt-8 ">
              <p class="text-lg text-yellow text-center pt-1 ">04</p>
            </div>
            <h1 className="text-lg text-darkblack font-semibold pt-4">
              Final Assembly and Integration
            </h1>
            <p className="text-base text-grey-600 pt-2">
              The standard chunk of Lorem Ipsum used since the 1500s is
              reproduced below.
            </p>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8 pb-16">
        <p class="text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="text-4xl font-bold text-darkblack pt-2 font-bold text-center">
          Work Process Style 05
        </h1>

        <div className="grid grid-cols-2 mt-8">
          <div className="hover-parent ">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade11 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon1} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">01</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Expert Engineers
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent ml-4">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon2} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">02</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Quality Control System
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-4">
          <div className="hover-parent ">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade33 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon3} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">03</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Satisfaction Guarantee
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="ml-4">
            <div className="hover-parent">
              <div className="flex bg-newshade33 p-4 rounded-md">
                <div>
                  <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                    <img class="w-8" src={serviceicon3} />
                  </div>
                  <p class="text-3xl font-bold text-grey pt-4 text-center">
                    04
                  </p>
                </div>
                <div className="ml-6">
                  <h1 class="text-base font-bold text-darkblack pt-6">
                    International Business
                  </h1>
                  <p class="text-sm text-grey-600 pt-2">
                    Modern society consumes consectetur adipisicing elitid.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-4">
          <div className="hover-parent ">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon1} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">05</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Unrivalled Workmanship
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent ml-4">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon2} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">06</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  High Professional Staff
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*mobileview*/}

      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-4 pt-20 ">
              <div>
                <h1 className="text-5xl text-white font-bold ">
                  Work Process Elements
                </h1>
                <div className="flex mt-4">
                  <p className="text-base font-bold text-white ">
                    Industrie - Industry and Engineering
                  </p>
                </div>
                <div className="flex">
                  <p className="text-base font-bold text-white  ">
                    {" "}
                    WordPress Theme
                  </p>
                  <p className="text-base1 font-bold text-white ml-2  ">›</p>
                  <p className="text-base font-bold text-white ml-2 ">
                    Work Process Elements
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk container mx-auto px-4">
        <p class="text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="text-3xl font-bold text-darkblack pt-2 font-bold text-center">
          Work Process Style 01
        </h1>
        <div className=" mt-12">
          <div className="custom-div">
            <div className="bg-newshade33 p-8">
              <h1 className="text-base1 font-bold text-darkblack">
                Analysis of the client'sobjectives & needs
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="w-16" src={serviceicon1} />
                </div>
                <h1 className="text-5xl font-bold text-grey">01</h1>
              </div>
              <p className="text-base text-grey-600 pt-4">
                Our approach to project management is grounded in practical
                experience and prioritises open communication.
              </p>
            </div>
          </div>

          <div className="custom-div mt-4">
            <div className="bg-newshade33 p-6">
              <h1 className="text-base1 font-bold text-darkblack">
                Engineering project study & solution design
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="w-16" src={serviceicon2} />
                </div>
                <h1 className="text-5xl font-bold text-grey">02</h1>
              </div>
              <p className="text-base text-grey-600 pt-8">
                Together, we identify the industrial data automation project's
                general scope and the components needed to produce.
              </p>
            </div>
          </div>

          <div className="custom-div mt-4">
            <div className="bg-newshade33 p-8">
              <h1 className="text-base1 font-bold text-darkblack">
                Solution execution phase and installation done
              </h1>
              <div className="flex justify-between mt-10">
                <div>
                  <img class="w-16" src={serviceicon3} />
                </div>
                <h1 className="text-5xl font-bold text-grey">03</h1>
              </div>
              <p className="text-base text-grey-600 pt-4">
                We collaborate closely on with you and examine the strategy and
                install and integrate the mechanical.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk bg-newshade33 pb-16">
        <div className="container mx-auto px-4">
          <p class="text-base text-yellow text-center pt-16 font-semibold">
            {" "}
            WORK PROCESS
          </p>
          <h1 className="text-3xl font-bold text-darkblack pt-2 font-bold text-center">
            Work Process Style 02
          </h1>
          <div className=" mt-12">
            <div className="p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">01</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">Research</h1>
              <p className="text-lg text-grey-600 pt-4 justify-content">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>

            <div className="p-6 mt-4">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">02</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">
                Brainstorming
              </h1>
              <p className="text-lg text-grey-600 pt-4">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>
          </div>

          <div className="mt-4">
            <div className="p-6">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">03</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">Finalize</h1>
              <p className="text-lg text-grey-600 pt-4">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>

            <div className="p-6 mt-4">
              <div className="flex">
                <div className="bg-newshade32 w-10 h-12 capsule">
                  <p className="text-lg text-center text-darkblack pt-2">04</p>
                </div>
                <p class="text-2xl text-grey ml-4 mt-2">S T E P</p>
              </div>
              <h1 class="text-base1 text-darkblack font-bold pt-4">Launch</h1>
              <p className="text-lg text-grey-600 pt-4">
                Industry standard dummy text took since the when an unknown
                printer galley type scrambled.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk container mx-auto px-4">
        <p class="text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="text-3xl font-bold text-darkblack pt-2 font-bold text-center">
          Work Process Style 03
        </h1>
        <div className=" mt-12">
          <div className="hover-container">
            <div className="bg-newshade33 p-8  ">
              <h1 className="text-lg font-bold text-darkblack  ">
                Preparation of project documents
              </h1>
              <p class="text-base text-grey-600 pt-4">
                Page when looking at its layout. The point of using Lorem Ipsum
                is that it has a more-less normal distribution.
              </p>
              <div className="flex justify-end">
                <div className="h-10 w-10 mt-4 mr-4 rounded-md">
                  <a href="/">
                    <p className="text-3xl font-bold text-yellow text-center pt-2 hover-target">
                      01
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 ">
            <div className="hover-container">
              <div className="bg-newshade33 p-8">
                <h1 className="text-lg font-bold text-darkblack">
                  Creation of a project with a team<br></br> on time
                </h1>
                <p class="text-base text-grey-600 pt-4">
                  Page when looking at its layout. The point of using Lorem
                  Ipsum is that it has a more-less normal distribution.
                </p>
                <div className="flex justify-end">
                  <div className="h-10 w-10 mt-4 mr-4 rounded-md">
                    <a href="/">
                      <p className="text-3xl font-bold text-yellow text-center pt-2 hover-target">
                        02
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 hover-container">
            <div className="bg-newshade33 p-8">
              <h1 className="text-lg font-bold text-darkblack">
                Completion of the project and payment
              </h1>
              <p class="text-base text-grey-600 pt-4">
                Page when looking at its layout. The point of using Lorem Ipsum
                is that it has a more-less normal distribution.
              </p>
              <div className="flex justify-end">
                <div className="h-10 w-10 mt-4 mr-4 rounded-md">
                  <a href="/">
                    <p className="text-3xl font-bold text-yellow text-center pt-2 hover-target">
                      03
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk bg-newshade33 pb-16">
        <div className="container mx-auto px-4">
          <p class="text-base text-yellow text-center pt-16 font-semibold">
            {" "}
            WORK PROCESS
          </p>
          <h1 className="text-3xl font-bold text-darkblack pt-2 font-bold text-center">
            Work Process Style 04
          </h1>

          <div className=" mt-16 ">
            <div className="bg-white  h-10 w-10 ">
              <p class="text-lg text-yellow text-center pt-2">01</p>
            </div>
            <h1 className="text-xl text-darkblack font-semibold pt-4">
              Product Design and Planning
            </h1>
            <p className="text-lg text-grey-600 pt-2">
              The point of using Lorem Ipsum is that it has more-or-less normal.
            </p>

            <div className="bg-white  h-10 w-10 mt-8 ">
              <p class="text-lg text-yellow text-center pt-1 ">02</p>
            </div>
            <h1 className="text-sxl text-darkblack font-semibold pt-4">
              Component Sourcing and Procurement
            </h1>
            <p className="text-lg text-grey-600 pt-2">
              Packages and web page editors now use as their default model.
            </p>
            <div className="bg-white  h-10 w-10 mt-8 ">
              <p class="text-lg text-yellow text-center pt-1 ">03</p>
            </div>
            <h1 className="text-xl text-darkblack font-semibold pt-4">
              Testing and Quality Control
            </h1>
            <p className="text-lg text-grey-600 pt-2">
              Packages and web page editors now use as their default model.
            </p>

            <div className="bg-white  h-10 w-10 mt-8 ">
              <p class="text-lg text-yellow text-center pt-1 ">04</p>
            </div>
            <h1 className="text-lg text-darkblack font-semibold pt-4">
              Final Assembly and Integration
            </h1>
            <p className="text-base text-grey-600 pt-2">
              The standard chunk of Lorem Ipsum used since the 1500s is
              reproduced below.
            </p>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk container mx-auto px-8 pb-16">
        <p class="text-base text-yellow text-center  font-semibold">
          {" "}
          WORK PROCESS
        </p>
        <h1 className="text-3xl font-bold text-darkblack pt-2 font-bold text-center">
          Work Process Style 05
        </h1>

        <div className=" mt-8">
          <div className="hover-parent ">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade11 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon1} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">01</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Expert Engineers
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent mt-4">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon2} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">02</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Quality Control System
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className=" mt-4">
          <div className="hover-parent ">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade33 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon3} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">03</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Satisfaction Guarantee
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <div className="hover-parent">
              <div className="flex bg-newshade33 p-4 rounded-md">
                <div>
                  <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                    <img class="w-8" src={serviceicon3} />
                  </div>
                  <p class="text-3xl font-bold text-grey pt-4 text-center">
                    04
                  </p>
                </div>
                <div className="ml-6">
                  <h1 class="text-base font-bold text-darkblack pt-6">
                    International Business
                  </h1>
                  <p class="text-sm text-grey-600 pt-2">
                    Modern society consumes consectetur adipisicing elitid.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" mt-4">
          <div className="hover-parent ">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon1} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">05</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  Unrivalled Workmanship
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>

          <div className="hover-parent mt-4">
            <div className="flex bg-newshade33 p-4 rounded-md">
              <div>
                <div className="bg-newshade29 w-16 h-16 p-4 serviceicon">
                  <img class="w-8" src={serviceicon2} />
                </div>
                <p class="text-3xl font-bold text-grey pt-4 text-center">06</p>
              </div>
              <div className="ml-6">
                <h1 class="text-base font-bold text-darkblack pt-6">
                  High Professional Staff
                </h1>
                <p class="text-sm text-grey-600 pt-2">
                  Modern society consumes consectetur adipisicing elitid.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*endd*/}

      <Footer2 />
    </>
  );
}
