import React, { useState } from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import blue from "assets/img/blue.jpeg";
import industrialslider2 from "assets/img/industrialslider2.png";
import blogpage1 from "assets/img/blogpage1.jpg";
import blogpage2 from "assets/img/blogpage2.jpg";
import blogpage3 from "assets/img/blogpage3.jpg";
import blogpage4 from "assets/img/blogpage4.jpg";
import blogpage5 from "assets/img/blogpage5.jpg";

export default function PrivacyPolicy() {
  return (
    <>
      <Indexnavbar2 fixed />

      <section className="xl:block lg:block md:hidden sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "500px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto xl:px-4 xl:pt-48 lg:px-8 lg:pt-56">
              <div>
                <h1 className="xl:text-5xl text-white font-bold lg:text-4xl">
                  Blog
                </h1>
                <div className="flex xl:mt-4 lg:mt-4">
                  <p className="xl:text-sm font-bold text-white lg:text-sm">
                    Industrie - Industry and Engineering WordPress Theme
                  </p>
                  <p className="xl:text-base1 font-bold text-white xl:ml-2 xl:-mt-2 lg:text-base1 lg:ml-2">
                    ›
                  </p>
                  <p className="xl:text-sm font-bold text-white xl:ml-2 lg:text-sm lg:ml-2">
                    Blog
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 container mx-auto px-4">
        <div className="flex xl:w-12/12">
          <div className="xl:w-8/12">
            <div className="image-container">
              <img class="xl:w-12/12 rounded-md" src={blogpage1} />
            </div>
            <div className="absolute ml-12">
              <div className=" bg-newshade32 rounded-md xl:-mt-96">
                <p className="xl:text-sm text-darkblack text-center px-6 py-2">
                  Company
                </p>
              </div>
            </div>
            <div className="flex xl:mt-4">
              <p class="xl:text-lg text-grey-600">By</p>
              <p class="xl:text-lg text-grey-600 font-bold xl:ml-2">Istaik</p>
              <p class="xl:text-2xl -mt-2 font-bold text-grey-600 xl:ml-2">.</p>
              <p class="xl:text-lg text-grey-600 xl:ml-2">February 8, 2024</p>
            </div>
            <h1 className="xl:text-3xl text-darkblack font-semibold yellow-on-hover mt-4">
              Construction of a new high tech plant in washingtons
            </h1>
            <p className="xl:text-lg pt-4 ">
              Industry’s standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. At vero eos et accusamus et iusto odio.
            </p>
            <div>
              <a href="PrivacyPolicy/">
                <p class="xl:text-lg pt-4 text-darkblack font-semibold yellow-on-hover">
                  Continue Reading ➜
                </p>
              </a>
            </div>
          </div>

          <div className=" xl:ml-6 bg-newshade33 p-6">
            <h1 className="xl:text-2xl font-bold text-darkblack yellow-on-hover pt-4">
              Current Affair
            </h1>
            <div className="flex mt-8 ">
              <div>
                <img class="w-24  rounded-md" src={blogpage4} />
              </div>
              <div className="ml-4">
                <h1 className="xl:text-lg font-bold text-darkblack yellow-on-hover">
                  Construction of a new high<br></br> tech plant
                </h1>
                <div className="flex xl:mt-2">
                  <p class="xl:text-lg text-grey-600">By</p>
                  <p class="xl:text-lg text-grey-600 font-bold xl:ml-2">
                    Istaik
                  </p>
                  <p class="xl:text-2xl -mt-2 font-bold text-grey-600 xl:ml-2">
                    .
                  </p>
                  <p class="xl:text-lg text-grey-600 xl:ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24  rounded-md" src={blogpage5} />
              </div>
              <div className="ml-4">
                <h1 className="xl:text-lg font-bold text-darkblack yellow-on-hover">
                  Building resilient supply<br></br> chains for industries
                </h1>
                <div className="flex xl:mt-2">
                  <p class="xl:text-lg text-grey-600">By</p>
                  <p class="xl:text-lg text-grey-600 font-bold xl:ml-2">
                    Istaik
                  </p>
                  <p class="xl:text-2xl -mt-2 font-bold text-grey-600 xl:ml-2">
                    .
                  </p>
                  <p class="xl:text-lg text-grey-600 xl:ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24  rounded-md" src={blogpage3} />
              </div>
              <div className="ml-4">
                <h1 className="xl:text-lg font-bold text-darkblack yellow-on-hover">
                  Factories technologies in<br></br>interactive and plants
                </h1>
                <div className="flex xl:mt-2">
                  <p class="xl:text-lg text-grey-600">By</p>
                  <p class="xl:text-lg text-grey-600 font-bold xl:ml-2">
                    Istaik
                  </p>
                  <p class="xl:text-2xl -mt-2 font-bold text-grey-600 xl:ml-2">
                    .
                  </p>
                  <p class="xl:text-lg text-grey-600 xl:ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24  rounded-md" src={blogpage3} />
              </div>
              <div className="ml-4">
                <h1 className="xl:text-lg font-bold text-darkblack yellow-on-hover">
                  Building resilient supply for<br></br> industries and factorie
                </h1>
                <div className="flex xl:mt-2">
                  <p class="xl:text-lg text-grey-600">By</p>
                  <p class="xl:text-lg text-grey-600 font-bold xl:ml-2">
                    Istaik
                  </p>
                  <p class="xl:text-2xl -mt-2 font-bold text-grey-600 xl:ml-2">
                    .
                  </p>
                  <p class="xl:text-lg text-grey-600 xl:ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>
          </div>





        </div>
      </section>

      <section className="xl:block lg:hidden md:hiddem sm:hidden hidden grotesk mt-12 container mx-auto px-4 pb-16">
        <div className="w-12/12">
          <div className="w-8/12">
            <div className="divider"></div>
            <div className="image-container mt-12">
              <img class="xl:w-12/12 rounded-md" src={blogpage2} />
            </div>
            <div className="absolute ml-12">
              <div className=" bg-newshade32 rounded-md xl:-mt-96">
                <p className="xl:text-sm text-darkblack text-center px-6 py-2">
                  Company
                </p>
              </div>
            </div>
            <div className="flex xl:mt-4">
              <p class="xl:text-lg text-grey-600">By</p>
              <p class="xl:text-lg text-grey-600 font-bold xl:ml-2">Istaik</p>
              <p class="xl:text-2xl -mt-2 font-bold text-grey-600 xl:ml-2">.</p>
              <p class="xl:text-lg text-grey-600 xl:ml-2">February 8, 2024</p>
            </div>
            <h1 className="xl:text-3xl text-darkblack font-semibold yellow-on-hover mt-4">
              Construction of a new high tech plant in washingtons
            </h1>
            <p className="xl:text-lg pt-4 ">
              Industry’s standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. At vero eos et accusamus et iusto odio.
            </p>
            <div>
              <a href="PrivacyPolicy/">
                <p class="xl:text-lg pt-4 text-darkblack font-semibold yellow-on-hover">
                  Continue Reading ➜
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/*landscapeview*/}
      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-16 container mx-auto px-8">
        <div className="flex w-12/12">
          <div className="w-10/12">
            <div className="image-container">
              <img class="w-12/12 rounded-md" src={blogpage1} />
            </div>
            <div className="absolute ml-12">
              <div className=" bg-newshade32 rounded-md -mt-68">
                <p className="text-sm text-darkblack text-center px-6 py-2">
                  Company
                </p>
              </div>
            </div>
            <div className="flex mt-4">
              <p class="text-lg text-grey-600">By</p>
              <p class="text-lg text-grey-600 font-bold ml-2">Istaik</p>
              <p class="text-2xl -mt-2 font-bold text-grey-600 ml-2">.</p>
              <p class="text-lg text-grey-600 ml-2">February 8, 2024</p>
            </div>
            <h1 className="text-3xl text-darkblack font-semibold yellow-on-hover mt-4">
              Construction of a new high tech plant in washingtons
            </h1>
            <p className="text-lg pt-4 ">
              Industry’s standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. At vero eos et accusamus et iusto odio.
            </p>
            <div>
              <a href="PrivacyPolicy/">
                <p class="text-lg pt-4 text-darkblack font-semibold yellow-on-hover">
                  Continue Reading ➜
                </p>
              </a>
            </div>
          </div>

          <div className=" ml-4 bg-newshade33 p-6">
            <h1 className="text-2xl font-bold text-darkblack yellow-on-hover pt-4">
              Current Affair
            </h1>
            <div className="flex mt-8 ">
              <div>
                <img class="w-20  rounded-md" src={blogpage4} />
              </div>
              <div className="ml-4">
                <h1 className="text-base font-bold text-darkblack yellow-on-hover">
                  Construction of a new high tech plant
                </h1>
                <div className="flex mt-2">
                  <p class="text-sm text-grey-600">By</p>
                  <p class="text-sm text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1 -mt-2 font-bold text-grey-600 ml-2">
                    .
                  </p>
                  <p class="text-sm text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24  rounded-md" src={blogpage5} />
              </div>
              <div className="ml-4">
                <h1 className="text-base font-bold text-darkblack yellow-on-hover">
                  Building resilient supply chains for industries
                </h1>
                <div className="flex mt-2">
                  <p class="text-sm text-grey-600">By</p>
                  <p class="text-sm text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1 -mt-2 font-bold text-grey-600 ml-2">
                    .
                  </p>
                  <p class="text-sm text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24  rounded-md" src={blogpage3} />
              </div>
              <div className="ml-4">
                <h1 className="text-base font-bold text-darkblack yellow-on-hover">
                  Factories technologies in interactive and plants
                </h1>
                <div className="flex mt-2">
                  <p class="text-sm text-grey-600">By</p>
                  <p class="text-sm text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1 -mt-2 font-bold text-grey-600 ml-2">
                    .
                  </p>
                  <p class="text-sm text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-20 rounded-md" src={blogpage3} />
              </div>
              <div className="ml-4">
                <h1 className="text-base font-bold text-darkblack yellow-on-hover">
                  Building resilient supply for<br></br> industries and factorie
                </h1>
                <div className="flex mt-2">
                  <p class="text-sm text-grey-600">By</p>
                  <p class="text-sm text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1 -mt-2 font-bold text-grey-600 ml-2">
                    .
                  </p>
                  <p class="text-sm text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>
          </div>






        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden grotesk mt-12 container mx-auto px-8 pb-16">
        <div className="w-12/12">
          <div className="w-8/12">
            <div className="divider"></div>
            <div className="image-container mt-12">
              <img class="w-12/12 rounded-md" src={blogpage2} />
            </div>
            <div className="absolute ml-12">
              <div className=" bg-newshade32 rounded-md -mt-68">
                <p className="text-sm text-darkblack text-center px-6 py-2">
                  Company
                </p>
              </div>
            </div>
            <div className="flex mt-4">
              <p class="text-lg text-grey-600">By</p>
              <p class="text-lg text-grey-600 font-bold ml-2">Istaik</p>
              <p class="text-2xl -mt-2 font-bold text-grey-600 ml-2">.</p>
              <p class="text-lg text-grey-600 ml-2">February 8, 2024</p>
            </div>
            <h1 className="text-3xl text-darkblack font-semibold yellow-on-hover mt-4">
              Construction of a new high tech plant in washingtons
            </h1>
            <p className="text-lg pt-4 ">
              Industry’s standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. At vero eos et accusamus et iusto odio.
            </p>
            <div>
              <a href="PrivacyPolicy/">
                <p class="text-lg pt-4 text-darkblack font-semibold yellow-on-hover">
                  Continue Reading ➜
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/*tabview*/}
      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-8 pt-36 ">
              <div>
                <h1 className="text-5xl text-white font-bold ">Blog</h1>
                <div className="flex mt-4">
                  <p className="text-sm font-bold text-white ">
                    Industrie - Industry and Engineering WordPress Theme
                  </p>
                  <p className="text-base1 font-bold text-white ml-2  ">›</p>
                  <p className="text-sm font-bold text-white ml-2 ">Blog</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8">
        <div>
        <div className="w-10/12">
            <div className="image-container">
              <img class="w-12/12 rounded-md" src={blogpage1} />
            </div>
            <div className="absolute ml-12">
              <div className=" bg-newshade32 rounded-md -mt-68">
                <p className="text-sm text-darkblack text-center px-6 py-2">
                  Company
                </p>
              </div>
            </div>
            <div className="flex mt-4">
              <p class="text-lg text-grey-600">By</p>
              <p class="text-lg text-grey-600 font-bold ml-2">Istaik</p>
              <p class="text-2xl -mt-2 font-bold text-grey-600 ml-2">.</p>
              <p class="text-lg text-grey-600 ml-2">February 8, 2024</p>
            </div>
            <h1 className="text-3xl text-darkblack font-semibold yellow-on-hover mt-4">
              Construction of a new high tech plant in washingtons
            </h1>
            <p className="text-lg pt-4 ">
              Industry’s standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. At vero eos et accusamus et iusto odio.
            </p>
            <div>
              <a href="PrivacyPolicy/">
                <p class="text-lg pt-4 text-darkblack font-semibold yellow-on-hover">
                  Continue Reading ➜
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk mt-12 container mx-auto px-8 pb-16">
        <div className="w-12/12">
          <div className="w-10/12">
            <div className="divider"></div>
            <div className="image-container mt-12">
              <img class="w-12/12 rounded-md" src={blogpage2} />
            </div>
            <div className="absolute ml-12">
              <div className=" bg-newshade32 rounded-md -mt-68">
                <p className="text-sm text-darkblack text-center px-6 py-2">
                  Company
                </p>
              </div>
            </div>
            <div className="flex mt-4">
              <p class="text-lg text-grey-600">By</p>
              <p class="text-lg text-grey-600 font-bold ml-2">Istaik</p>
              <p class="text-2xl -mt-2 font-bold text-grey-600 ml-2">.</p>
              <p class="text-lg text-grey-600 ml-2">February 8, 2024</p>
            </div>
            <h1 className="text-3xl text-darkblack font-semibold yellow-on-hover mt-4">
              Construction of a new high tech plant in washingtons
            </h1>
            <p className="text-lg pt-4 ">
              Industry’s standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. At vero eos et accusamus et iusto odio.
            </p>
            <div>
              <a href="PrivacyPolicy/">
                <p class="text-lg pt-4 text-darkblack font-semibold yellow-on-hover">
                  Continue Reading ➜
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden  container mx-auto px-8 pb-16">
         <div className="w-12/12">
          <div className="w-12/12">
          <div className="  bg-newshade33 p-6">
            <h1 className="text-2xl font-bold text-darkblack yellow-on-hover pt-4">
              Current Affair
            </h1>
            <div className="flex mt-8 ">
              <div>
                <img class="w-24 rounded-md" src={blogpage4} />
              </div>
              <div className="ml-4">
                <h1 className="text-xl font-bold text-darkblack yellow-on-hover">
                  Construction of a new high tech plant
                </h1>
                <div className="flex mt-2">
                  <p class="text-lg text-grey-600">By</p>
                  <p class="text-lg text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1  font-bold text-grey-600 ml-2">
                    .
                  </p>
                  <p class="text-lg text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24  rounded-md" src={blogpage5} />
              </div>
              <div className="ml-4">
                <h1 className="text-xl font-bold text-darkblack yellow-on-hover">
                  Building resilient supply chains for industries
                </h1>
                <div className="flex mt-2">
                  <p class="text-lg text-grey-600">By</p>
                  <p class="text-lg text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1  font-bold text-grey-600 ml-2">
                    .
                  </p>
                  <p class="text-lg text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24 rounded-md" src={blogpage3} />
              </div>
              <div className="ml-4">
                <h1 className="text-xl font-bold text-darkblack yellow-on-hover">
                  Factories technologies in interactive and plants
                </h1>
                <div className="flex mt-2">
                  <p class="text-lg text-grey-600">By</p>
                  <p class="text-lg text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1  font-bold text-grey-600 ml-2">
                    .
                  </p>
                  <p class="text-lg text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24 rounded-md" src={blogpage3} />
              </div>
              <div className="ml-4">
                <h1 className="text-xl font-bold text-darkblack yellow-on-hover">
                  Building resilient supply for industries and factorie
                </h1>
                <div className="flex mt-2">
                  <p class="text-lg text-grey-600">By</p>
                  <p class="text-lg text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1  font-bold text-grey-600 ml-2">
                    .
                  </p>
                  <p class="text-lg text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
         </div>
        </section>
      {/*mobileview*/}
      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-4 pt-20 ">
              <div>
                <h1 className="text-5xl text-white font-bold ">
                  Blog
                </h1>
                <div className="flex mt-4">
                  <p className="text-base font-bold text-white ">
                    Industrie - Industry and Engineering
                  </p>
                </div>
                <div className="flex">
                  <p className="text-base font-bold text-white  ">
                    {" "}
                    WordPress Theme
                  </p>
                  <p className="text-base1 font-bold text-white ml-2  ">›</p>
                  <p className="text-base font-bold text-white ml-2 ">
                    Blog
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk container mx-auto px-4">
        <div>
        <div className="w-12/12">
            <div className="image-container">
              <img class="w-12/12 rounded-md" src={blogpage1} />
            </div>
            <div className="absolute ml-6">
              <div className=" bg-newshade32 rounded-md -mt-36">
                <p className="text-sm text-darkblack text-center px-6 py-2">
                  Company
                </p>
              </div>
            </div>
            <div className="flex mt-4">
              <p class="text-lg text-grey-600">By</p>
              <p class="text-lg text-grey-600 font-bold ml-2">Istaik</p>
              <p class="text-2xl -mt-2 font-bold text-grey-600 ml-2">.</p>
              <p class="text-lg text-grey-600 ml-2">February 8, 2024</p>
            </div>
            <h1 className="text-3xl text-darkblack font-semibold yellow-on-hover mt-4">
              Construction of a new high tech plant in washingtons
            </h1>
            <p className="text-lg pt-4 ">
              Industry’s standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. At vero eos et accusamus et iusto odio.
            </p>
            <div>
              <a href="PrivacyPolicy/">
                <p class="text-lg pt-4 text-darkblack font-semibold yellow-on-hover">
                  Continue Reading ➜
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk mt-12 container mx-auto px-4 pb-16">
        <div className="w-12/12">
          <div className="w-12/12">
            <div className="divider"></div>
            <div className="image-container mt-12">
              <img class="w-12/12 rounded-md" src={blogpage2} />
            </div>
            <div className="absolute ml-6">
              <div className=" bg-newshade32 rounded-md -mt-36">
                <p className="text-sm text-darkblack text-center px-6 py-2">
                  Company
                </p>
              </div>
            </div>
            <div className="flex mt-4">
              <p class="text-lg text-grey-600">By</p>
              <p class="text-lg text-grey-600 font-bold ml-2">Istaik</p>
              <p class="text-2xl -mt-2 font-bold text-grey-600 ml-2">.</p>
              <p class="text-lg text-grey-600 ml-2">February 8, 2024</p>
            </div>
            <h1 className="text-3xl text-darkblack font-semibold yellow-on-hover mt-4">
              Construction of a new high tech plant in washingtons
            </h1>
            <p className="text-lg pt-4 ">
              Industry’s standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. At vero eos et accusamus et iusto odio.
            </p>
            <div>
              <a href="PrivacyPolicy/">
                <p class="text-lg pt-4 text-darkblack font-semibold yellow-on-hover">
                  Continue Reading ➜
                </p>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block  container mx-auto px-4 pb-16">
         <div className="w-12/12">
          <div className="w-12/12">
          <div className="  bg-newshade33 p-6 pb-12">
            <h1 className="text-2xl font-bold text-darkblack yellow-on-hover pt-4">
              Current Affair
            </h1>
            <div className="flex mt-8 ">
              <div>
                <img class="w-20 rounded-md" src={blogpage4} />
              </div>
              <div className="ml-4">
                <h1 className="text-base font-bold text-darkblack yellow-on-hover">
                  Construction of a new high tech plant
                </h1>
                <div className="flex mt-2">
                  <p class="text-sm text-grey-600">By</p>
                  <p class="text-sm text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1  font-bold text-grey-600 ml-2 -mt-1">
                    .
                  </p>
                  <p class="text-sm text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24  rounded-md" src={blogpage5} />
              </div>
              <div className="ml-4">
                <h1 className="text-base font-bold text-darkblack yellow-on-hover">
                  Building resilient supply chains for industries
                </h1>
                <div className="flex mt-2">
                  <p class="text-sm text-grey-600">By</p>
                  <p class="text-sm text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1  font-bold text-grey-600 ml-2 -mt-2">
                    .
                  </p>
                  <p class="text-sm text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24 rounded-md" src={blogpage3} />
              </div>
              <div className="ml-4">
                <h1 className="text-base font-bold text-darkblack yellow-on-hover">
                  Factories technologies in interactive and plants
                </h1>
                <div className="flex mt-2">
                  <p class="text-sm text-grey-600">By</p>
                  <p class="text-sm text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1  font-bold text-grey-600 ml-2 -mt-2">
                    .
                  </p>
                  <p class="text-sm text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>

            <div className="flex mt-5">
              <div>
                <img class="w-24 rounded-md" src={blogpage3} />
              </div>
              <div className="ml-4">
                <h1 className="text-base font-bold text-darkblack yellow-on-hover">
                  Building resilient supply for industries and factorie
                </h1>
                <div className="flex mt-2">
                  <p class="text-sm text-grey-600">By</p>
                  <p class="text-sm text-grey-600 font-bold ml-2">
                    Istaik
                  </p>
                  <p class="text-base1  font-bold text-grey-600 ml-2 -mt-2">
                    .
                  </p>
                  <p class="text-sm text-grey-600 ml-2">
                    February 8, 2024
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
         </div>
        </section>
      {/*endd*/}
      <Footer2 />
    </>
  );
}
