import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import industrial4 from "assets/img/industrial4.jpg";
import industrial5 from "assets/img/industrial5.png";
import industrial6 from "assets/img/industrial6.jpg";
import industrial7 from "assets/img/industrial7.jpg";
import industrial8 from "assets/img/industrial8.jpg";

const SliderPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    centerPadding: "10px",
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="container mx-auto  grotesk">
      <Slider {...settings}>
        <div className="">
          <div className="image-container">
            <img class="xl:w-11/12 lg:w-11/12 md:w-11/12" src={industrial4} />
          </div>
          <div className="xl:p-8 hover-div xl:w-11/12 lg:p-6 lg:w-11/12 md:w-11/12 p-6">
            <h1 class="xl:text-2xl font-bold  text-darkblack lg:text-2xl md:text-2xl text-2xl">
              Best Innovations in <br></br>metallurgy
            </h1>
            <p class="xl:text-base  xl:pt-4 text-grey-600 lg:text-base lg:pt-4 md:text-base md:pt-4 pt-4 text-base">
              Consectetur ipiscing elit<br></br> tellus ullam corper mattis
            </p>
            <div className=" flex  xl:mt-2 lg:mt-2 mt-2">
              <h1 class="text-grey xl:text-5xl font-bold lg:text-4xl md:text-5xl text-5xl">01</h1>
              
            </div>
            <div className="flex justify-between xl:mt-2 lg:mt-2 mt-2 ">
              <a href="/">
                <h1 class="xl:text-base text-grey-600 font-bold lg:text-base md:text-base text-base">
                  View Details
                </h1>
              </a>
              <a href="/">
                <p class="xl:text-base text-grey-600 font-bold  bg-newshade29 h-6 w-8 text-center rounded-md">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="">
          <div className="image-container">
            <img class="xl:w-11/12 lg:w-11/12 md:w-11/12" src={industrial5} />
          </div>
          <div className="xl:p-8 hover-div xl:w-11/12 lg:p-6 lg:w-11/12 md:w-11/12 p-6">
            <h1 class="xl:text-2xl font-bold  text-darkblack lg:text-2xl md:text-2xl text-2xl">
              Best Innovations in <br></br>metallurgy
            </h1>
            <p class="xl:text-base  xl:pt-4 text-grey-600 lg:text-base lg:pt-4 md:pt-4 pt-4 text-base">
              Consectetur ipiscing elit<br></br> tellus ullam corper mattis
            </p>
            <div className=" flex  xl:mt-2 lg:mt-2 mt-2">
              <h1 class="text-grey xl:text-5xl font-bold lg:text-4xl md:text-5xl text-5xl">02</h1>
              
            </div>
            <div className="flex justify-between xl:mt-2 lg:mt-2 mt-2 ">
              <a href="/">
                <h1 class="xl:text-base text-grey-600 font-bold lg:text-base md:text-base text-base">
                  View Details
                </h1>
              </a>
              <a href="/">
                <p class="xl:text-base text-grey-600 font-bold  bg-newshade29 h-6 w-8 text-center rounded-md">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="">
          <div className="image-container">
            <img class="xl:w-11/12 lg:w-11/12 md:w-11/12" src={industrial6} />
          </div>
          <div className="xl:p-8 hover-div xl:w-11/12 lg:p-6 lg:w-11/12 md:w-11/12 p-6">
            <h1 class="xl:text-2xl font-bold  text-darkblack lg:text-2xl md:text-2xl text-2xl">
              Best Innovations in <br></br>metallurgy
            </h1>
            <p class="xl:text-base xl:pt-4 text-grey-600 lg:text-base lg:pt-4 md:pt-4 pt-4 text-base">
              Consectetur ipiscing elit<br></br> tellus ullam corper mattis
            </p>
            <div className=" flex  xl:mt-2 lg:mt-2 mt-2">
              <h1 class="text-grey xl:text-5xl font-bold lg:text-4xl md:text-5xl text-5xl">03</h1>
              
            </div>
            <div className="flex justify-between xl:mt-2 lg:mt-2 mt-2">
              <a href="/">
                <h1 class="xl:text-base text-grey-600 font-bold text-base">
                  View Details
                </h1>
              </a>
              <a href="/">
                <p class="xl:text-base text-grey-600 font-bold  bg-newshade29 h-6 w-8 text-center rounded-md">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="">
          <div className="image-container">
            <img class="xl:w-11/12 lg:w-11/12 md:w-11/12" src={industrial7} />
          </div>
          <div className="xl:p-8 hover-div xl:w-11/12 lg:p-6 lg:w-11/12 md:w-11/12 p-6">
            <h1 class="xl:text-2xl font-bold  text-darkblack lg:text-2xl md:text-2xl text-2xl">
              Best Innovations in <br></br>metallurgy
            </h1>
            <p class="xl:text-base  xl:pt-4 text-grey-600 lg:text-base lg:pt-4 md:pt-4 pt-4 text-base">
              Consectetur ipiscing elit<br></br> tellus ullam corper mattis
            </p>
            <div className=" flex  xl:mt-2 lg:mt-2 mt-2">
              <h1 class="text-grey xl:text-5xl font-bold lg:text-4xl md:text-5xl text-5xl">04</h1>
              
            </div>
            <div className="flex  justify-between xl:mt-2 lg:mt-2 mt-2">
              <a href="/">
                <h1 class="xl:text-base text-grey-600 font-bold text-base">
                  View Details
                </h1>
              </a>
              <a href="/">
                <p class="xl:text-base text-grey-600 font-bold  bg-newshade29 h-6 w-8 text-center rounded-md">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>

        <div className="">
          <div className="image-container">
            <img class="xl:w-11/12 lg:w-11/12 md:w-11/12" src={industrial8} />
          </div>
          <div className="xl:p-8 hover-div xl:w-11/12 lg:p-6 md:w-11/12 p-6">
            <h1 class="xl:text-2xl font-bold  text-darkblack lg:text-2xl md:text-2xl text-2xl">
              Best Innovations in <br></br>metallurgy
            </h1>
            <p class="xl:text-base  xl:pt-4 text-grey-600 lg:pt-4 md:pt-4 pt-4 text-base">
              Consectetur ipiscing elit<br></br> tellus ullam corper mattis
            </p>
            <div className=" flex  xl:mt-2 lg:mt-2 mt-2">
              <h1 class="text-grey xl:text-5xl font-bold lg:text-4xl md:text-5xl text-5xl">05</h1>
              
            </div>
            <div className="flex justify-between xl:mt-2 lg:mt-2 mt-2">
              <a href="/">
                <h1 class="xl:text-base text-grey-600 font-bold text-base">
                  View Details
                </h1>
              </a>
              <a href="/">
                <p class="xl:text-base text-grey-600 font-bold  bg-newshade29 h-6 w-8 text-center rounded-md">
                  →
                </p>
              </a>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default SliderPage;
