import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import featureimage from "assets/img/featureimage.jpg";
import industrial1 from "assets/img/industrial1.png";
import industrial2 from "assets/img/industrial2.png";
import manufacturer from "assets/img/manufacturer.png";
import industrial3 from "assets/img/industrial3.png";
import industrial32 from "assets/img/industrial32.png";
import imageicon from "assets/img/imageicon.png";
import sign from "assets/img/sign.png";
import background15 from "assets/img/background15.jpg";
import background14 from "assets/img/background14.png";
import video from "assets/img/videoplay.png";
import Footer2 from "components/Footers/Footer2";
import Cloudslider from "views/Cloudslider";
import SliderPage from "views/SliderPage";
import industria9 from "assets/img/industrial9.jpg";
import logo11 from "assets/img/logo11.png";
import logo12 from "assets/img/logo12.png";
import logo13 from "assets/img/logo13.png";
import logo14 from "assets/img/logo14.png";
import logo15 from "assets/img/logo15.png";
import logo16 from "assets/img/logo16.png";
import industria10 from "assets/img/industrial10.jpg";
import industria11 from "assets/img/industrial11.jpg";
import background16 from "assets/img/background16.jpg";
import Landing from "views/Landing";
import Profile from "views/Profile";

export default function Homepage1() {
 
  
  // Counting numbers
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const sectionRef = useRef(null);
  const startedRef = useRef(false);

  const animateCount = (target, setter, duration) => {
    let start = 0;
    const increment = target / (duration / 10);
    
    const timer = setInterval(() => {
      start += increment;
      if (start >= target) {
        start = target;
        clearInterval(timer);
      }
      setter(Math.ceil(start));
    }, 10);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !startedRef.current) {
          animateCount(50, setCount1, 2500);
          animateCount(49, setCount2, 2500);
          animateCount(25, setCount3, 2500);
          animateCount(60, setCount4, 2500);
          startedRef.current = true;
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer && sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section className=" ">
      <Indexnavbar2 />

      <section className="xl:block lg:block md:block sm:block block ">
        <div>
          <div className="App">
            <Cloudslider />
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 container mx-auto xl:px-4 grotesk lg:mt-20 lg:px-4 ">
        <div className="grid grid-cols-3">
          <div className="your-class ">
            <div className="">
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 xl:ml-76 p-4  ">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="xl:text-base1 font-bold text-darkblack xl:ml-6 xl:-mt-16 lg:text-base1  ">
                    Best Manufacturing<br></br> Service Provider
                  </h1>
                  <p class="xl:text-lg text-black xl:pt-4 xl:ml-6 lg:text-base">
                    At the heart of the global landscape, the industry stands as
                    a multidimensional was progress driving.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-2 your-class">
            <div>
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 xl:ml-76 p-4">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="xl:text-base1 font-bold text-darkblack xl:ml-6 xl:-mt-16">
                    Experienced & Trusted<br></br> Best Contractor
                  </h1>
                  <p class="xl:text-lg text-black xl:pt-4 xl:ml-6">
                    Various versions have evolved over the years sometimes by
                    accident, sometimes purpose(injected humour and the like).
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-4 your-class">
            <div>
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 xl:ml-76 p-4">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="xl:text-base1 font-bold text-darkblack xl:ml-6 xl:-mt-16">
                    That always building<br></br> quality is industrial
                  </h1>
                  <p class="xl:text-lg text-black xl:pt-4 xl:ml-6">
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk container mx-auto xl:px-4">
        <div className="grid grid-cols-2 ">
          <div>
            <div className="flex">
              <div>
                <img class="xl:w-12/12" src={industrial1} />
              </div>
              <div>
                <img class="xl:w-12/12 xl:mt-24 xl:-ml-20" src={industrial2} />
              </div>
            </div>
            <div>
              <img class="xl:w-12/12 xl:-ml-4" src={industrial3} />
            </div>
          </div>

          <div>
            <p class="xl:text-base font-bold text-yellow">ABOUT INDUSTRIE</p>
            <div className="xl:text-4xll text-darkblack font-bold xl:mt-2">
              <h1 className="typewriter">
                We are largest independent<br></br> manufacturing company
              </h1>
            </div>
            <p class="xl:text-lg xl:pt-4 text-grey-600 ">
              We solve worldwide industrial every problem, the heart of global
              landscape the industry stands multidimensional was progress
              driving more-or-less normal distribution.
            </p>

            <div className="flex xl:mt-12">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center xl:text-base">
                ✓
              </p>
              <p class="xl:ml-2 text-grey-600 font-semibold xl:text-lg">
                Quality Control System, 100% Guarantee
              </p>
            </div>

            <div className="flex xl:mt-4">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center xl:text-base">
                ✓
              </p>
              <p class="xl:ml-2 text-grey-600 font-semibold xl:text-lg">
                We Always Building Quality Industrial
              </p>
            </div>

            <div className="flex xl:mt-4">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center xl:text-base">
                ✓
              </p>
              <p class="xl:ml-2 text-grey-600 font-semibold xl:text-lg">
                Professional Team to Specific Industry
              </p>
            </div>

            <div className="flex xl:mt-6">
              <button class="xl:text-lg text-darkblack bg-newshade32 rounded-md xl:px-12 xl:py-2">
                Explore More →
              </button>
              <div>
                <img class="w-16 h-16 rounded-full xl:ml-12" src={imageicon} />
              </div>
              <div>
                <img
                  class="xl:w-4/12 rounded-full xl:ml-12 xl:mt-4"
                  src={sign}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "890px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="xl:pt-16 container mx-auto xl:px-4">
              <p class="xl:text-base text-yellow font-bold">BEST SERVICES</p>
              <div className="xl:mt-4 xl:text-4xll font-bold text-darkblack">
                <h1 className="typewriter">
                  Get best industrial services<br></br> for your business
                </h1>
              </div>

              <div className="xl:mt-12">
                <div className="App">
                  <SliderPage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background14})`,
              width: "100%",
              height: "600px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto xl:px-4 xl:pt-44">
              <div>
                <p class="xl:text-base text-yellow font-bold">BEST SERVICES</p>
                <div className="xl:mt-4 xl:text-4xll font-bold text-yellow ">
                  <h1 className="typewriter">
                    Everyone Loves Got an
                    <br></br> incredible project right now
                  </h1>
                </div>
                <div className="flex xl:mt-8">
                  <a href="/">
                    <button class="xl:text-lg bg-newshade32 xl:px-12 xl:py-4  rounded-md text-black">
                      Start Project
                    </button>
                  </a>
                  <div>
                    <a href="/">
                      <img
                        class="w-16 xl:ml-8 transition-imgg bg-newshade32 rounded-full"
                        src={video}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 container mx-auto xl:px-4 grotesk ">
        <div className="grid grid-cols-2">
          <div>
            <div>
              <img class="xl:w-12/12 lg:w-12/12" src={industria9} />
            </div>
          </div>

          <div>
            <div
              className="xl:ml-72"
              style={{
                backgroundImage: `url(${industrial32})`,
                width: "50%",
                height: "300px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="xl:-ml-52 xl:pt-16">
                <p className="xl:text-base text-yellow font-bold">OUR GOAL</p>
                <div className="xl:text-4xl text-darkblack font-bold xl:pt-2">
                  <h1 className="typewriter">
                    we are always<br></br> moving forward{" "}
                  </h1>
                  <p class="xl:text-lg text-grey-600 xl:pt-2">
                    We are largest independent manufacturing company We solve
                    worldwide industrial every problem, the heart of global
                    landscape
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk container mx-auto xl:px-4">
        <div className="grid grid-cols-6">
          <div className="bg-newshade33 xl:p-12">
            <div className="center-button image-container">
              <img class="xl:w-24 " src={logo11} />
            </div>
          </div>

          <div className="bg-newshade33 xl:p-12 xl:ml-2">
            <div className="center-button image-container">
              <img class="xl:w-20 " src={logo12} />
            </div>
          </div>

          <div className="bg-newshade33 xl:p-12 xl:ml-2">
            <div className="center-button image-container">
              <img class="xl:w-24 " src={logo13} />
            </div>
          </div>

          <div className="bg-newshade33 xl:p-12 xl:ml-2">
            <div className="center-button image-container">
              <img class="xl:w-32 xl:pt-2 " src={logo14} />
            </div>
          </div>

          <div className="bg-newshade33 xl:p-12 xl:ml-2">
            <div className="center-button image-container">
              <img class="xl:w-24 " src={logo15} />
            </div>
          </div>

          <div className="bg-newshade33 xl:p-12 xl:ml-2">
            <div className="center-button image-container">
              <img class="xl:w-32 xl:pt-6 xl:ml-4 " src={logo16} />
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "870px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="xl:pt-16 container mx-auto xl:px-4">
              <p class="xl:text-base text-yellow font-bold">RECENT PROJECT</p>
              <div class="xl:text-4xl text-darkblack font-bold xl:pt-4">
                <h1 class=" typewriter  ">
                  Some of our finished projects that<br></br> will amaze your
                  industry
                </h1>
              </div>

              <div className="grid grid-cols-2 xl:mt-12">
                <div>
                  <div
                    className="bgg-container"
                    style={{
                      backgroundImage: `url(${industria10})`,
                      width: "100%",
                      height: "500px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="">
                      <div className="bg-newshade32 xl:p-6 xl:w-12/12 bg-container">
                        <div>
                          <p class="xl:text-base1 text-darkblack">01</p>
                          <h1 className="xl:text-base1 text-darkblack font-semibold xl:pt-4">
                            Oil & Gas Industry
                          </h1>
                          <p class="xl:text-base text-grey-600 xl:pt-2">
                            Midstream
                          </p>
                          <hr className="border-blue-50"></hr>
                          <div className="  flex justify-between xl:mt-5">
                            <a href="/">
                              <h1 class="xl:text-base text-grey-600 ">
                                View Details
                              </h1>
                            </a>
                            <a href="/">
                              <p class="xl:text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                                →
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ml-4">
                  <div
                    className="bgg-container"
                    style={{
                      backgroundImage: `url(${industria11})`,
                      width: "100%",
                      height: "500px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="">
                      <div className="bg-newshade32 xl:p-6  xl:w-12/12 bg-container">
                        <div className="">
                          <p class="xl:text-base1 text-darkblack">02</p>
                          <h1 className="xl:text-base1 text-darkblack font-semibold xl:pt-4">
                            Metal Industry
                          </h1>
                          <p class="xl:text-base text-grey-600 xl:pt-2">
                            Innovative
                          </p>
                          <hr className="border-blue-50"></hr>
                          <div className="  flex justify-between xl:mt-5">
                            <a href="/">
                              <h1 class="xl:text-base text-grey-600 ">
                                View Details
                              </h1>
                            </a>
                            <a href="/">
                              <p class="xl:text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                                →
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h1 class="xl:text-lg text-grey-600 yellow-on-hover text-center xl:mt-8 font-semibold">
                START YOUR NEXT GAIN PROJECT
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section ref={sectionRef} className="xl:block lg:hidden md:hidden sm:hidden hidden grotesk">
      <div className="">
        <div className="container relative mx-auto overflow-hidden hometext xl:px-4 ">
          <div className="">
            <div className="grid grid-cols-4  pb-12 xl:mt-20  xl:px-12 mt-8 text-animate">
              <div className="center-items">
                <h1 className="h1 font-bold  text-white ">
                  {count1}+<p></p>
                </h1>
                <hr className="border-blue-50 xl:mt-2 xl:mr-8" />
                <p className="text-grey-600  xl:pt-6 xl:text-base1 banner-font font-medium">
                  Drilling Fields
                </p>
              </div>
              <div className="center-items ml-4">
                <h1 className="h1 font-bold  text-white">
                  {count2}+<p></p>
                </h1>
                <hr className="border-blue-50 xl:mt-2 xl:mr-8" />
                <p className="text-grey-600 xl:pt-6 xl:text-base1  banner-font font-medium">
                  Crane Equipment
                </p>
              </div>
              <div className="center-items ml-4">
                <h1 className="h1 font-bold  text-white  ">
                  {count3}+<p></p>
                </h1>
                <hr className="border-blue-50 xl:mt-2 xl:mr-8" />
                <p className="text-grey-600 xl:pt-6  xl:text-base1 banner-font font-medium">
                  Metal Factory
                </p>
              </div>
              <div className="center-items ml-4">
                <h1 className="h1 font-bold  text-white ">
                  {count4}+<p></p>
                </h1>
                <hr className="border-blue-50 xl:mt-2 xl:mr-8" />
                <p className="text-grey-600 xl:pt-6  xl:text-base1  banner-font font-medium">
                  Special Machinery
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background16})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto xl:px-4 xl:pt-24">
              <div className="grid grid-cols-2">
                <div className="">
                  <p class="xl:text-base text-yellow font-bold">TESTIMONIALS</p>
                  <div className="xl:text-4xl text-darkblack font-bold xl:pt-4">
                    <h1 className="typewriter">
                      What people<br></br> saying about<br></br> Industrie
                    </h1>
                  </div>
                </div>

                <div className="xl:-ml-32">
                  <div className="App">
                    <Landing />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*landscapeview*/}

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 container mx-auto px-8 grotesk  ">
        <div className="grid grid-cols-3">
          <div className="your-class ">
            <div className="">
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 ml-52 p-4  ">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="text-lg font-bold text-darkblack ml-2 -mt-16   ">
                    Best Manufacturing<br></br> Service Provider
                  </h1>
                  <p class="text-lg text-black pt-4 ml-2 ">
                    At the heart of the global landscape, the industry stands as
                    a multidimensional was progress driving.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-2 your-class">
            <div>
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 ml-52 p-4">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="text-lg font-bold text-darkblack ml-2 -mt-16">
                    Experienced & Trusted<br></br> Best Contractor
                  </h1>
                  <p class="xl:text-base text-black pt-4 ml-2">
                    Various versions have evolved over the years sometimes by
                    accident, sometimes purpose(injected humour and the like).
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-4 your-class">
            <div>
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 ml-52 p-4">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="text-lg font-bold text-darkblack ml-2 -mt-16">
                    That always building<br></br> quality is industrial
                  </h1>
                  <p class="text-base text-black pt-4 ml-2">
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 grotesk container mx-auto px-8">
        <div className="grid grid-cols-2 ">
          <div>
            <div className="flex">
              <div>
                <img class="w-12/12" src={industrial1} />
              </div>
              <div>
                <img class="w-12/12 mt-24 -ml-20" src={industrial2} />
              </div>
            </div>
            <div>
              <img class="w-12/12 -ml-2" src={industrial3} />
            </div>
          </div>

          <div>
            <p class="text-base font-bold text-yellow">ABOUT INDUSTRIE</p>
            <div className="text-4xl text-darkblack font-bold mt-2">
              <h1 className="typewriter">
                We are largest independent<br></br> manufacturing company
              </h1>
            </div>
            <p class="text-lg pt-4 text-grey-600 ">
              We solve worldwide industrial every problem, the heart of global
              landscape the industry stands multidimensional was progress
              driving more-or-less normal distribution.
            </p>

            <div className="flex mt-6">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center text-base">
                ✓
              </p>
              <p class="ml-2 text-grey-600 font-semibold text-lg">
                Quality Control System, 100% Guarantee
              </p>
            </div>

            <div className="flex mt-4">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center text-base">
                ✓
              </p>
              <p class="ml-2 text-grey-600 font-semibold xl:text-lg">
                We Always Building Quality Industrial
              </p>
            </div>

            <div className="flex mt-4">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center xl:text-base">
                ✓
              </p>
              <p class="ml-2 text-grey-600 font-semibold text-lg">
                Professional Team to Specific Industry
              </p>
            </div>

            <div className="flex mt-6">
              <button class="text-base text-darkblack bg-newshade32 rounded-md px-8 ">
                Explore More →
              </button>
              <div>
                <img class="w-16 h-16 rounded-full ml-2" src={imageicon} />
              </div>
              <div>
                <img class="w-4/12 rounded-full ml-2 mt-4" src={sign} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "780px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-16 container mx-auto px-8">
              <p class="text-base text-yellow font-bold">BEST SERVICES</p>
              <div className="mt-4 text-4xl font-bold text-darkblack">
                <h1 className="typewriter">
                  Get best industrial services<br></br> for your business
                </h1>
              </div>

              <div className="mt-10">
                <div className="App">
                  <SliderPage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background14})`,
              width: "100%",
              height: "500px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-8 pt-36">
              <div>
                <p class="text-base text-yellow font-bold">BEST SERVICES</p>
                <div className="mt-4 text-4xl font-bold text-yellow ">
                  <h1 className="typewriter">
                    Everyone Loves Got an
                    <br></br> incredible project right now
                  </h1>
                </div>
                <div className="flex mt-8">
                  <a href="/">
                    <button class="text-lg bg-newshade32 px-8 py-2  rounded-md text-black">
                      Start Project
                    </button>
                  </a>
                  <div>
                    <a href="/">
                      <img
                        class="w-12 ml-6 transition-imgg bg-newshade32 rounded-full"
                        src={video}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 container mx-auto px-8 grotesk ">
        <div className="grid grid-cols-2">
          <div>
            <div>
              <img class="w-12/12" src={industria9} />
            </div>
          </div>

          <div>
            <div
              className="ml-60"
              style={{
                backgroundImage: `url(${industrial32})`,
                width: "50%",

                height: "300px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className=" -ml-46 ">
                <p className="text-base text-yellow font-bold">OUR GOAL</p>
                <div className="text-4xl text-darkblack font-bold pt-2">
                  <h1 className="typewriter">
                    we are always<br></br> moving forward{" "}
                  </h1>
                  <p class="text-lg text-grey-600 pt-2">
                    We are largest independent manufacturing company We solve
                    worldwide industrial every problem, the heart of global
                    landscape
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 grotesk container mx-auto px-8">
        <div className="grid grid-cols-6">
          <div className="bg-newshade33 p-12">
            <div className="center-button image-container">
              <img class="w-24 " src={logo11} />
            </div>
          </div>

          <div className="bg-newshade33 p-12 ml-2">
            <div className="center-button image-container">
              <img class="w-20 " src={logo12} />
            </div>
          </div>

          <div className="bg-newshade33 p-12 ml-2">
            <div className="center-button image-container">
              <img class="w-24 " src={logo13} />
            </div>
          </div>

          <div className="bg-newshade33 p-12 ml-2">
            <div className="center-button image-container">
              <img class="w-32 pt-2 " src={logo14} />
            </div>
          </div>

          <div className="bg-newshade33 p-12 ml-2">
            <div className="center-button image-container">
              <img class="w-24 " src={logo15} />
            </div>
          </div>

          <div className="bg-newshade33 p-12 ml-2">
            <div className="center-button image-container">
              <img class="w-32 pt-6 ml-4 " src={logo16} />
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "870px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-16 container mx-auto px-8">
              <p class="text-base text-yellow font-bold">RECENT PROJECT</p>
              <div class="text-4xl text-darkblack font-bold pt-4">
                <h1 class=" typewriter  ">
                  Some of our finished projects that<br></br> will amaze your
                  industry
                </h1>
              </div>

              <div className="grid grid-cols-2 mt-12">
                <div>
                  <div
                    className="bgg-container"
                    style={{
                      backgroundImage: `url(${industria10})`,
                      width: "100%",
                      height: "500px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="">
                      <div className="bg-newshade32 p-6 w-12/12 bg-container">
                        <div>
                          <p class="text-base1 text-darkblack">01</p>
                          <h1 className="text-base1 text-darkblack font-semibold pt-4">
                            Oil & Gas Industry
                          </h1>
                          <p class="text-base text-grey-600 pt-2">Midstream</p>
                          <hr className="border-blue-50"></hr>
                          <div className="  flex justify-between mt-5">
                            <a href="/">
                              <h1 class="text-base text-grey-600 ">
                                View Details
                              </h1>
                            </a>
                            <a href="/">
                              <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                                →
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ml-4">
                  <div
                    className="bgg-container"
                    style={{
                      backgroundImage: `url(${industria11})`,
                      width: "100%",
                      height: "500px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="">
                      <div className="bg-newshade32 p-6  w-12/12 bg-container">
                        <div className="">
                          <p class="text-base1 text-darkblack">02</p>
                          <h1 className="text-base1 text-darkblack font-semibold pt-4">
                            Metal Industry
                          </h1>
                          <p class="text-base text-grey-600 pt-2">Innovative</p>
                          <hr className="border-blue-50"></hr>
                          <div className="  flex justify-between mt-5">
                            <a href="/">
                              <h1 class="text-base text-grey-600 ">
                                View Details
                              </h1>
                            </a>
                            <a href="/">
                              <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center ml-2">
                                →
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h1 class="text-base1 text-grey-600 yellow-on-hover text-center mt-8 font-semibold">
                START YOUR NEXT GAIN PROJECT
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden grotesk">
        <div className="">
          <div className="container relative mx-auto overflow-hidden hometext   px-8">
            <div className="">
              <div
                ref={sectionRef}
                className="grid grid-cols-4 mt-20 px-12 mt-8 text-animate"
              >
                <div className="center-items">
                  <h1 className=" h1 font-bold text-4xl  text-white  ">
                    {count1}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-10 mr-8"></hr>
                  <p className="text-grey-600 pt-6 w-9/12 text-base1  banner-font font-medium">
                    Drilling Fields
                  </p>
                </div>
                <div className="center-items ml-4">
                  <h1 className="  h1 font-bold text-4xl text-white ">
                    {count2}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-10 mr-8"></hr>
                  <p className="text-grey-600 pt-6  w-9/12 text-base1  banner-font font-medium">
                    Crane Equipment
                  </p>
                </div>
                <div className="center-items ml-4">
                  <h1 className=" h1 font-bold text-4xl text-white">
                    {count3}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-10 mr-8"></hr>
                  <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                    Metal Factory
                  </p>
                </div>

                <div className="center-items ml-4">
                  <h1 className=" h1 font-bold text-4xl text-white ">
                    {count4}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-10 mr-8"></hr>
                  <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                    Special Machinery
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background16})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-8 pt-24">
              <div className="grid grid-cols-2">
                <div className="">
                  <p class="text-base text-yellow font-bold">TESTIMONIALS</p>
                  <div className="text-4xl text-darkblack font-bold pt-4">
                    <h1 className="typewriter">
                      What people<br></br> saying about<br></br> Industrie
                    </h1>
                  </div>
                </div>

                <div className="-ml-40">
                  <div className="App">
                    <Landing />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*tabview*/}
      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 container mx-auto px-8 grotesk">
        <div className="grid grid-cols-2">
          <div className="your-class ">
            <div className="">
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 ml-56 p-4  ">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="text-lg font-bold text-darkblack ml-2 -mt-16   ">
                    Best Manufacturing<br></br> Service Provider
                  </h1>
                  <p class="text-base text-black pt-4 ml-2 ">
                    At the heart of the global landscape, the industry stands as
                    a multidimensional was progress driving.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="ml-4 your-class">
            <div>
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 ml-56 p-4">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="text-lg font-bold text-darkblack ml-2 -mt-16">
                    Experienced & Trusted<br></br> Best Contractor
                  </h1>
                  <p class="xl:text-base text-black pt-4 ml-2">
                    Various versions have evolved over the years sometimes by
                    accident, sometimes purpose(injected humour and the like).
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 your-class">
            <div>
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 ml-56 p-4">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="text-lg font-bold text-darkblack ml-2 -mt-16">
                    That always building<br></br> quality is industrial
                  </h1>
                  <p class="text-base text-black pt-4 ml-2">
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8">
        <div className="  ">
          <div>
            <div className="">
              <img class="w-10/12" src={industrial1} />
            </div>
          </div>

          <div>
            <p class="text-base font-bold text-yellow mt-8">ABOUT INDUSTRIE</p>
            <div className="text-4xl text-darkblack font-bold mt-2">
              <h1 className="typewriter">
                We are largest independent<br></br> manufacturing company
              </h1>
            </div>
            <p class="text-lg pt-4 text-grey-600 ">
              We solve worldwide industrial every problem, the heart of global
              landscape the industry stands multidimensional was progress
              driving more-or-less normal distribution.
            </p>

            <div className="flex mt-6">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center text-base">
                ✓
              </p>
              <p class="ml-2 text-grey-600 font-semibold text-lg">
                Quality Control System, 100% Guarantee
              </p>
            </div>

            <div className="flex mt-4">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center text-base">
                ✓
              </p>
              <p class="ml-2 text-grey-600 font-semibold xl:text-lg">
                We Always Building Quality Industrial
              </p>
            </div>

            <div className="flex mt-4">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center xl:text-base">
                ✓
              </p>
              <p class="ml-2 text-grey-600 font-semibold text-lg">
                Professional Team to Specific Industry
              </p>
            </div>

            <div className="flex mt-6">
              <button class="text-base text-darkblack bg-newshade32 rounded-md px-8 ">
                Explore More →
              </button>
              <div>
                <img
                  class="w-16 h-16 rounded-full ml-16 mt-1"
                  src={imageicon}
                />
              </div>
              <div>
                <img class="w-4/12 rounded-full ml-12 mt-1" src={sign} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "780px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-16 container mx-auto px-8">
              <p class="text-base text-yellow font-bold">BEST SERVICES</p>
              <div className="mt-4 text-4xl font-bold text-darkblack">
                <h1 className="typewriter">
                  Get best industrial services<br></br> for your business
                </h1>
              </div>

              <div className="mt-10">
                <div className="App">
                  <SliderPage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background14})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-8 pt-24">
              <div>
                <p class="text-base text-yellow font-bold">BEST SERVICES</p>
                <div className="mt-4 text-4xl font-bold text-yellow ">
                  <h1 className="typewriter">
                    Everyone Loves Got an
                    <br></br> incredible project right now
                  </h1>
                </div>
                <div className="flex mt-8">
                  <a href="/">
                    <button class="text-lg bg-newshade32 px-8 py-2  rounded-md text-black">
                      Start Project
                    </button>
                  </a>
                  <div>
                    <a href="/">
                      <img
                        class="w-12 ml-6 transition-imgg bg-newshade32 rounded-full"
                        src={video}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 container mx-auto px-8 grotesk ">
        <div className="">
          <div>
            <div>
              <img class="w-12/12" src={industria9} />
            </div>
          </div>

          <div className=" mt-8 ">
            <p className="text-base text-yellow font-bold">OUR GOAL</p>
            <div className="text-4xl text-darkblack font-bold pt-2">
              <h1 className="typewriter">
                we are always<br></br> moving forward{" "}
              </h1>
              <p class="text-lg text-grey-600 pt-2">
                We are largest independent manufacturing company We solve
                worldwide industrial every problem, the heart of global
                landscape
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8">
        <div className="grid grid-cols-3">
          <div className="bg-newshade33 p-12">
            <div className="center-button image-container">
              <img class="w-24 " src={logo11} />
            </div>
          </div>

          <div className="bg-newshade33 p-12 ml-2">
            <div className="center-button image-container">
              <img class="w-20 " src={logo12} />
            </div>
          </div>

          <div className="bg-newshade33 p-12 ml-2">
            <div className="center-button image-container">
              <img class="w-24 " src={logo13} />
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "870px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-16 container mx-auto px-8">
              <p class="text-base text-yellow font-bold">RECENT PROJECT</p>
              <div class="text-4xl text-darkblack font-bold pt-4">
                <h1 class=" typewriter  ">
                  Some of our finished projects that<br></br> will amaze your
                  industry
                </h1>
              </div>

              <div className="grid grid-cols-2 mt-12">
                <div>
                  <div
                    className="bgg-container"
                    style={{
                      backgroundImage: `url(${industria10})`,
                      width: "100%",
                      height: "500px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="">
                      <div className="bg-newshade32 p-6 w-12/12 bg-container">
                        <div>
                          <p class="text-base1 text-darkblack">01</p>
                          <h1 className="text-base1 text-darkblack font-semibold pt-4">
                            Oil & Gas Industry
                          </h1>
                          <p class="text-base text-grey-600 pt-2">Midstream</p>
                          <hr className="border-blue-50"></hr>
                          <div className="  flex justify-between mt-5">
                            <a href="/">
                              <h1 class="text-base text-grey-600 ">
                                View Details
                              </h1>
                            </a>
                            <a href="/">
                              <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                                →
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ml-4">
                  <div
                    className="bgg-container"
                    style={{
                      backgroundImage: `url(${industria11})`,
                      width: "100%",
                      height: "500px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="">
                      <div className="bg-newshade32 p-6  w-12/12 bg-container">
                        <div className="">
                          <p class="text-base1 text-darkblack">02</p>
                          <h1 className="text-base1 text-darkblack font-semibold pt-4">
                            Metal Industry
                          </h1>
                          <p class="text-base text-grey-600 pt-2">Innovative</p>
                          <hr className="border-blue-50"></hr>
                          <div className="  flex justify-between mt-5">
                            <a href="/">
                              <h1 class="text-base text-grey-600 ">
                                View Details
                              </h1>
                            </a>
                            <a href="/">
                              <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center ml-2">
                                →
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h1 class="text-base1 text-grey-600 yellow-on-hover text-center mt-8 font-semibold">
                START YOUR NEXT GAIN PROJECT
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk">
        <div className="">
          <div className="container relative mx-auto overflow-hidden hometext ">
            <div className="">
              <div
                ref={sectionRef}
                className="grid grid-cols-4 mt-16 px-12 mt-8 text-animate"
              >
                <div className="center-items">
                  <h1 className=" h2 font-bold text-4xl  text-white  ">
                    {count1}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-6 mr-8"></hr>
                  <p className="text-grey-600 pt-6 w-9/12 text-base1  banner-font font-medium">
                    Drilling Fields
                  </p>
                </div>
                <div className="center-items ml-4">
                  <h1 className="  h2 font-bold text-4xl text-white ">
                    {count2}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-6 mr-8"></hr>
                  <p className="text-grey-600 pt-6  w-9/12 text-base1  banner-font font-medium">
                    Crane Equipment
                  </p>
                </div>
                <div className="center-items ml-4">
                  <h1 className=" h2 font-bold text-4xl text-white">
                    {count3}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-6 mr-8"></hr>
                  <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                    Metal Factory
                  </p>
                </div>

                <div className="center-items ml-4">
                  <h1 className=" h2 font-bold text-4xl text-white ">
                    {count4}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-6 mr-8"></hr>
                  <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                    Special Machinery
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background16})`,
              width: "100%",
              height: "510px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-8 pt-16">
              <div className="">
                <div className="">
                  <p class="text-base text-yellow font-bold">TESTIMONIALS</p>
                  <div className="text-4xl text-darkblack font-bold pt-4">
                    <h1 className="typewriter">
                      What people saying about<br></br> Industrie
                    </h1>
                  </div>
                </div>

                <div className="mt-8">
                  <div className="App">
                    <Landing />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*mobileview*/}
      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 container mx-auto px-4 grotesk">
        <div className="">
          <div className="your-class ">
            <div className="">
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 ml-56 p-4  ">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="text-lg font-bold text-darkblack ml-2 -mt-16   ">
                    Best Manufacturing<br></br> Service Provider
                  </h1>
                  <p class="text-base text-black pt-4 ml-2 ">
                    At the heart of the global landscape, the industry stands as
                    a multidimensional was progress driving.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 your-class">
            <div>
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 ml-56 p-4">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="text-lg font-bold text-darkblack ml-2 -mt-16">
                    Experienced & Trusted<br></br> Best Contractor
                  </h1>
                  <p class="xl:text-base text-black pt-4 ml-2">
                    Various versions have evolved over the years sometimes by
                    accident, sometimes purpose(injected humour and the like).
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 your-class">
            <div>
              <div className="your-overlay-class ">
                <div className="bg-newshade29 w-20 h-20 ml-56 p-4">
                  <img class="w-12" src={manufacturer} />
                </div>
                <div>
                  <h1 class="text-lg font-bold text-darkblack ml-2 -mt-16">
                    That always building<br></br> quality is industrial
                  </h1>
                  <p class="text-base text-black pt-4 ml-2">
                    Many desktop publishing packages and web page editors now
                    use Lorem Ipsum as their default model text.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk container mx-auto px-4">
        <div className="  ">
          <div>
            <div className="">
              <img class="w-10/12" src={industrial1} />
            </div>
          </div>

          <div>
            <p class="text-base font-bold text-yellow mt-8">ABOUT INDUSTRIE</p>
            <div className="text-4xl text-darkblack font-bold mt-2">
              <h1 className="typewriter">
                We are largest independent<br></br> manufacturing company
              </h1>
            </div>
            <p class="text-lg pt-4 text-grey-600 ">
              We solve worldwide industrial every problem, the heart of global
              landscape the industry stands multidimensional was progress
              driving more-or-less normal distribution.
            </p>

            <div className="flex mt-6">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center text-base">
                ✓
              </p>
              <p class="ml-2 text-grey-600 font-semibold text-lg">
                Quality Control System, 100% Guarantee
              </p>
            </div>

            <div className="flex mt-4">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center text-base">
                ✓
              </p>
              <p class="ml-2 text-grey-600 font-semibold xl:text-lg">
                We Always Building Quality Industrial
              </p>
            </div>

            <div className="flex mt-4">
              <p class="text-yellow border-8 rounded-full w-6 h-6 text-center xl:text-base">
                ✓
              </p>
              <p class="ml-2 text-grey-600 font-semibold text-lg">
                Professional Team to Specific Industry
              </p>
            </div>

            <div className=" mt-6">
              <button class="text-base text-darkblack bg-newshade32 rounded-md px-8 py-3">
                Explore More →
              </button>
             
             
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk mt-16">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "780px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-16 container mx-auto px-4">
              <p class="text-base text-yellow font-bold">BEST SERVICES</p>
              <div className="mt-4 text-4xl font-bold text-darkblack">
                <h1 className="typewriter">
                  Get best industrial services<br></br> for your business
                </h1>
              </div>

              <div className="mt-10">
                <div className="App">
                  <SliderPage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background14})`,
              width: "100%",
              height: "380px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-4 pt-16">
              <div>
                <p class="text-base text-yellow font-bold">BEST SERVICES</p>
                <div className="mt-4 text-4xl font-bold text-yellow ">
                  <h1 className="typewriter">
                    Everyone Loves Got<br></br> an
                     incredible project<br></br> right now
                  </h1>
                </div>
                <div className="flex mt-8">
                  <a href="/">
                    <button class="text-lg bg-newshade32 px-8 py-2  rounded-md text-black">
                      Start Project
                    </button>
                  </a>
                  <div>
                    <a href="/">
                      <img
                        class="w-12 ml-6 transition-imgg bg-newshade32 rounded-full"
                        src={video}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 container mx-auto px-4 grotesk ">
        <div className="">
          <div>
            <div>
              <img class="w-12/12" src={industria9} />
            </div>
          </div>

          <div className=" mt-8 ">
            <p className="text-base text-yellow font-bold">OUR GOAL</p>
            <div className="text-4xl text-darkblack font-bold pt-2">
              <h1 className="typewriter">
                we are always<br></br> moving forward{" "}
              </h1>
              <p class="text-base text-grey-600 pt-2">
                We are largest independent manufacturing company We solve
                worldwide industrial every problem, the heart of global
                landscape
              </p>
            </div>
          </div>
        </div>
      </section>


      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk container mx-auto px-4">
        <div className="grid grid-cols-2">
          <div className="bg-newshade33 p-12">
            <div className="center-button image-container">
              <img class="w-24 " src={logo11} />
            </div>
          </div>

          <div className="bg-newshade33 p-12 ml-2">
            <div className="center-button image-container">
              <img class="w-20 " src={logo12} />
            </div>
          </div>

          
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${background15})`,
              width: "100%",
              height: "850px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-16 container mx-auto px-4">
              <p class="text-base text-yellow font-bold">RECENT PROJECT</p>
              <div class="text-4xl text-darkblack font-bold pt-4">
                <h1 class=" typewriter  ">
                  Some of our<br></br> finished projects<br></br> that will amaze your<br></br>
                  industry
                </h1>
              </div>

              <div className=" mt-6">
                <div>
                  <div
                    className="bgg-container"
                    style={{
                      backgroundImage: `url(${industria10})`,
                      width: "100%",
                      height: "500px",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="">
                      <div className="bg-newshade32 p-6 w-12/12 bg-container">
                        <div>
                          <p class="text-base1 text-darkblack">01</p>
                          <h1 className="text-base1 text-darkblack font-semibold pt-4">
                            Oil & Gas Industry
                          </h1>
                          <p class="text-base text-grey-600 pt-2">Midstream</p>
                          <hr className="border-blue-50"></hr>
                          <div className="  flex justify-between mt-5">
                            <a href="/">
                              <h1 class="text-base text-grey-600 ">
                                View Details
                              </h1>
                            </a>
                            <a href="/">
                              <p class="text-base text-grey-600 font-bold rounded-md bg-newshade29 h-6 w-8 text-center">
                                →
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                
              </div>

              <h1 class="text-base1 text-grey-600 yellow-on-hover text-center mt-8 font-semibold">
                START YOUR NEXT GAIN PROJECT
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk px-4">
        <div className="">
          <div className="container relative mx-auto overflow-hidden hometext ">
            <div className="">
              <div
                ref={sectionRef}
                className=" mt-16  mt-8 text-animate"
              >
                <div className="center-items ml-4">
                  <h1 className=" h2 font-bold text-4xl  text-white  ">
                    {count1}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-6 mr-8"></hr>
                  <p className="text-grey-600 pt-6 w-9/12 text-base1  banner-font font-medium">
                    Drilling Fields
                  </p>
                </div>
                <div className="center-items ml-4 mt-10">
                  <h1 className="  h2 font-bold text-4xl text-white ">
                    {count2}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-6 mr-8"></hr>
                  <p className="text-grey-600 pt-6  w-9/12 text-base1  banner-font font-medium">
                    Crane Equipment
                  </p>
                </div>
                <div className="center-items ml-4 mt-10">
                  <h1 className=" h2 font-bold text-4xl text-white">
                    {count3}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-6 mr-8"></hr>
                  <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                    Metal Factory
                  </p>
                </div>

                <div className="center-items ml-4 mt-10">
                  <h1 className=" h2 font-bold text-4xl text-white ">
                    {count4}+<p></p>
                  </h1>
                  <hr className="border-blue-50 mt-6 mr-8"></hr>
                  <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                    Special Machinery
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${background16})`,
              width: "100%",
              height: "510px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-4 pt-16">
              <div className="">
                <div className="">
                  <p class="text-base text-yellow font-bold">TESTIMONIALS</p>
                  <div className="text-4xl text-darkblack font-bold pt-4">
                    <h1 className="typewriter">
                      What people saying<br></br> about Industrie
                    </h1>
                  </div>
                </div>

                <div className="mt-8">
                  <div className="App">
                    <Landing />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      


      {/*endd*/}

      <Footer2 />
    </section>
  );
}
