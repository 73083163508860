import Footer2 from "components/Footers/Footer2";

import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import aboutimg1 from "assets/img/aboutimg1.jpg";
import aboutimg2 from "assets/img/aboutimg2.jpg";
import aboutimg3 from "assets/img/aboutimg3.jpg";
import aboutimg4 from "assets/img/aboutimg4.jpg";
import aboutimg5 from "assets/img/aboutimg5.jpg";
import aboutimg6 from "assets/img/aboutimg6.jpg";
import aboutimg7 from "assets/img/aboutimg7.jpg";
import aboutimg8 from "assets/img/aboutimg8.jpg";
import abouticon1 from "assets/img/abouticon1.png";
import abouticon2 from "assets/img/abouticon2.png";
import aboutbackground1 from "assets/img/aboutbackground1.jpg";
import aboutbackground2 from "assets/img/aboutbackground2.jpg";
import aboutbackground4 from "assets/img/aboutbackground4.jpg";
import aboutbackground3 from "assets/img/aboutbackground3.jpg";
import industrialslider2 from "assets/img/industrialslider2.png";
import industrial9 from "assets/img/industrial9.jpg";
import industrial32 from "assets/img/industrial32.png";
import logo11 from "assets/img/logo11.png";
import logo12 from "assets/img/logo12.png";
import logo13 from "assets/img/logo13.png";
import logo14 from "assets/img/logo14.png";
import logo15 from "assets/img/logo15.png";
import logo16 from "assets/img/logo16.png";
import Profile from "views/Profile";
import industrial3 from "assets/img/industrial3.png";
import industrialicon1 from "assets/img/industrialicon1.png";

export default function About3() {
  const [activeTab, setActiveTab] = useState("1990");
  const tabs = ["1990", "2000", "2012", "2020", "2024"];
  // Counting numbers
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);
  const sectionRef = useRef(null);
  const startedRef = useRef(false);

  const animateCount = (target, setter, duration) => {
    let start = 0;
    const increment = target / (duration / 10);

    const timer = setInterval(() => {
      start += increment;
      if (start >= target) {
        start = target;
        clearInterval(timer);
      }
      setter(Math.ceil(start));
    }, 10);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !startedRef.current) {
          animateCount(50, setCount1, 2500, true);
          animateCount(49, setCount2, 2500, true);
          animateCount(25, setCount3, 2500, true);
          animateCount(60, setCount4, 2500, true);
          startedRef.current = true;
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer && sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const [isOpen1, setIsOpen1] = useState(false);

  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
  };

  const [isOpen2, setIsOpen2] = useState(false);

  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };

  const [isOpen3, setIsOpen3] = useState(false);

  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
  };

  const [isOpen4, setIsOpen4] = useState(false);

  const toggleAccordion4 = () => {
    setIsOpen4(!isOpen4);
  };

  return (
    <section>
      <Indexnavbar2 />

      <section className="xl:block lg:block md:hidden sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "500px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto xl:px-4 xl:pt-48 lg:px-8 lg:pt-56">
              <div>
                <h1 className="xl:text-5xl text-white font-bold lg:text-4xl">
                  About
                </h1>
                <div className="flex xl:mt-4 lg:mt-4">
                  <p className="xl:text-sm font-bold text-white lg:text-sm">
                    Industrie - Industry and Engineering WordPress Theme
                  </p>
                  <p className="xl:text-base1 font-bold text-white xl:ml-2 xl:-mt-2 lg:text-base1 lg:ml-2">
                    ›
                  </p>
                  <p className="xl:text-sm font-bold text-white xl:ml-2 lg:text-sm lg:ml-2">
                    About
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-16 grotesk container mx-auto xl:px-4 xl:pb-32">
        <div>
          <p className="xl:text-base font-semibold text-center text-yellow">
            ABOUT INDUSTRIE
          </p>
          <h1 className="xl:text-4xl font-bold text-darkblack xl:pt-2 text-center">
            Welcome to Industrie, a leading<br></br> industry innovator with a
            rich<br></br> history of excellence.
          </h1>
        </div>

        <div className="grid grid-cols-4 xl:mt-8">
          <div>
            <div>
              <img className="xl:w-12/12" src={aboutimg1} />
            </div>
          </div>

          <div>
            <div className="xl:ml-6 ">
              <img className="xl:w-8/12" src={abouticon1} />
            </div>
          </div>

          <div>
            <div>
              <img className="xl:w-12/12" src={aboutimg2} />
            </div>
          </div>

          <div className="xl:ml-6 ">
            <div>
              <img className="xl:w-8/12" src={abouticon2} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 xl:px-32 ">
          <div>
            <div className="rounded-md xl:p-10 bg-newshade33 custom-border-bottom  xl:-mt-24 absolute">
              <p className="text-darkblack xl:text-lg font-bold">Our Mission</p>
              <p className="text-grey-600 xl:text-base  xl:pt-4">
                Welcome to Industrie, a leading industry<br></br> innovator with
                a rich history of excellence.<br></br> With a passion for
                precision.
              </p>
            </div>
          </div>

          <div className="xl:ml-32">
            <div className="rounded-md xl:p-10 bg-newshade33 custom-border-bottom  xl:-mt-24 absolute">
              <p className="text-darkblack xl:text-lg font-bold">Our Vision</p>
              <p className="text-grey-600 xl:text-base  xl:pt-4">
                We have been empowering industries and<br></br> driving progress
                for over 30 years. Our diverse<br></br>team of experts brings
                together.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden grotesk container mx-auto xl:px-4 bg-newshade33 xl:mt-16">
        <div>
          <div
            style={{
              backgroundImage: `url(${aboutbackground1})`,
              width: "100%",
              height: "700px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="xl:px-8 xl:pt-16">
              <div>
                <p className="xl:text-base font-bold text-yellow text-center">
                  OUR HISTORY
                </p>
                <h1 className="xl:text-4xl text-darkblack font-bold text-center xl:pt-4">
                  Company Journey
                </h1>
                <div className="xl:px-16 xl:mt-8">
                  <div className="bg-gray-100 p-2 min-h-screen">
                    <div className="bg-white p-2 rounded shadow-md">
                      <div className="grid grid-cols-5 gap-4">
                        {tabs.map((tab) => (
                          <button
                            key={tab}
                            className={`p-2 text-center rounded ${
                              activeTab === tab
                                ? "bg-blue-500 text-white"
                                : "bg-gray-200 text-gray-800 text-lg"
                            }`}
                            onClick={() => setActiveTab(tab)}
                          >
                            {tab}
                            {activeTab === tab && (
                              <span className="relative  text-lg -mt-6 text-yellow  flex items-center justify-center">
                                {tab}
                              </span>
                            )}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="mt-4 p-4 border rounded bg-white">
                      {activeTab === "1990" && (
                        <div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <img
                                className="xl:w-11/12"
                                src={aboutimg3}
                                alt="1990"
                              />
                            </div>
                            <div>
                              <h1 className="xl:text-base1 font-semibold text-darkblack xl:pt-6">
                                Journey Was Started
                              </h1>
                              <p className="xl:text-lg xl:pt-4">
                                Welcome to Industrie, a leading industry
                                innovator<br></br> with a rich history of
                                excellence. With a passion for<br></br>
                                precision and a commitment to quality, we have
                                been<br></br> empowering industries and driving
                                progress.
                              </p>
                              <div className="flex">
                                <p className="xl:text-5xl text-yellow">–</p>
                                <p className="xl:text-base xl:ml-4 mt-6">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="xl:text-5xl text-yellow">–</p>
                                <p className="xl:text-base xl:ml-4 mt-6">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2000" && (
                        <div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <img
                                className="xl:w-11/12"
                                src={aboutimg1}
                                alt="2000"
                              />
                            </div>
                            <div>
                              <h1 className="xl:text-base1 font-semibold text-darkblack xl:pt-6">
                                Journey Was Started
                              </h1>
                              <p className="xl:text-lg xl:pt-4">
                                Welcome to Industrie, a leading industry
                                innovator<br></br> with a rich history of
                                excellence. With a passion for<br></br>
                                precision and a commitment to quality, we have
                                been<br></br> empowering industries and driving
                                progress.
                              </p>
                              <div className="flex">
                                <p className="xl:text-5xl text-yellow">–</p>
                                <p className="xl:text-base xl:ml-4 mt-6">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="xl:text-5xl text-yellow">–</p>
                                <p className="xl:text-base xl:ml-4 mt-6">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2012" && (
                        <div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <img
                                className="xl:w-11/12"
                                src={aboutimg2}
                                alt="2012"
                              />
                            </div>
                            <div>
                              <h1 className="xl:text-base1 font-semibold text-darkblack xl:pt-6">
                                Journey Was Started
                              </h1>
                              <p className="xl:text-lg xl:pt-4">
                                Welcome to Industrie, a leading industry
                                innovator<br></br> with a rich history of
                                excellence. With a passion for<br></br>
                                precision and a commitment to quality, we have
                                been<br></br> empowering industries and driving
                                progress.
                              </p>
                              <div className="flex">
                                <p className="xl:text-5xl text-yellow">–</p>
                                <p className="xl:text-base xl:ml-4 mt-6">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="xl:text-5xl text-yellow">–</p>
                                <p className="xl:text-base xl:ml-4 mt-6">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2020" && (
                        <div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <img
                                className="xl:w-11/12"
                                src={aboutimg3}
                                alt="2020"
                              />
                            </div>
                            <div>
                              <h1 className="xl:text-base1 font-semibold text-darkblack xl:pt-6">
                                Journey Was Started
                              </h1>
                              <p className="xl:text-lg xl:pt-4">
                                Welcome to Industrie, a leading industry
                                innovator<br></br> with a rich history of
                                excellence. With a passion for<br></br>
                                precision and a commitment to quality, we have
                                been<br></br> empowering industries and driving
                                progress.
                              </p>
                              <div className="flex">
                                <p className="xl:text-5xl text-yellow">–</p>
                                <p className="xl:text-base xl:ml-4 mt-6">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="xl:text-5xl text-yellow">–</p>
                                <p className="xl:text-base xl:ml-4 mt-6">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2024" && (
                        <div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <img
                                className="xl:w-11/12"
                                src={aboutimg1}
                                alt="2024"
                              />
                            </div>
                            <div>
                              <h1 className="xl:text-base1 font-semibold text-darkblack xl:pt-6">
                                Journey Was Started
                              </h1>
                              <p className="xl:text-lg xl:pt-4">
                                Welcome to Industrie, a leading industry
                                innovator<br></br> with a rich history of
                                excellence. With a passion for<br></br>
                                precision and a commitment to quality, we have
                                been<br></br> empowering industries and driving
                                progress.
                              </p>
                              <div className="flex">
                                <p className="xl:text-5xl text-yellow">–</p>
                                <p className="xl:text-base xl:ml-4 mt-6">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="xl:text-5xl text-yellow">–</p>
                                <p className="xl:text-base xl:ml-4 mt-6">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk container mx-auto xl:px-4 pb-16">
        <p className="xl:text-base font-semibold text-yellow ">ENGINEER</p>
        <div className="flex justify-between xl:mt-4">
          <h1 className="xl:text-4xl font-bold text-darkblack">
            The Best industry experts
          </h1>
          <a href="/">
            <button className="xl:text-base1 text-black bg-newshade32 rounded-md xl:px-8 xl:py-2">
              Become a Member ➜
            </button>
          </a>
        </div>

        <div className="grid grid-cols-3 xl:mt-12 grotesk">
          <div className="parent-hover">
            <div className=" ">
              <img className="xl:w-11/12 hover-image" src={aboutimg4} />
            </div>
            <div className="xl:w-10/12 xl:ml-18">
              <div className="bg-white  xl:-mt-12 absolute xl:px-12 shadow-xl hover-border pb-4 ">
                <h1 className="xl:text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Penelopa Miller
                </h1>
                <p className="xl:text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="parent-hover">
            <div className=" ">
              <img className="xl:w-11/12 hover-image" src={aboutimg5} />
            </div>
            <div className="xl:w-12/12 xl:ml-20">
              <div className="bg-white  xl:-mt-12 absolute xl:px-12 shadow-xl hover-border pb-4 ">
                <h1 className="xl:text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Peter Hase
                </h1>
                <p className="xl:text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="parent-hover">
            <div className=" ">
              <img className="xl:w-11/12 hover-image" src={aboutimg6} />
            </div>
            <div className="xl:w-10/12 xl:ml-16">
              <div className="bg-white  xl:-mt-12 absolute xl:px-12 shadow-xl hover-border pb-4 ">
                <h1 className="xl:text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Lawrence Peter
                </h1>
                <p className="xl:text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-16 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${aboutbackground4})`,

              width: "100%",
              height: "700px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="xl:pt-16 container mx-auto xl:px-4 ">
              <div>
                <p className="xl:text-base font-bold text-yellow text-center">
                  ACHIEVEMENT
                </p>
                <h1 className="xl:text-4xl text-darkblack font-bold text-center xl:pt-4">
                  Company Achievement
                </h1>

                <div className="xl:mt-8">
                  <div className="">
                    <div className="container relative mx-auto overflow-hidden hometext xl:px-4 lg:px-4 md:px-4   px-4">
                      <div className="">
                        <div
                          ref={sectionRef}
                          className="grid grid-cols-4  grid-cols-1 md:pb-0 pb-12 xl:mt-20 lg:mt-16 md:mt-16 xl:px-12 mt-8 text-animate"
                        >
                          <div className="center-items">
                            <h1 className=" h1 font-bold xl:text-5xl md:text-4xl text-white text-3xl">
                              {count1}+<p></p>
                            </h1>
                            <hr className="border-blue-50 xl:mt-2 xl:mr-8"></hr>
                            <p className="text-grey-600 xl:pt-6 pt-2 xl:w-9/12 md:w-9/12 w-9/12 xl:text-base1 md:text-sm banner-font font-medium">
                              Drilling Fields
                            </p>
                          </div>
                          <div className="center-items ml-4">
                            <h1 className="  h1 font-bold xl:text-5xl md:text-4xl text-white text-3xl xl:pt-0 md:pt-0 pt-4">
                              {count2}+<p></p>
                            </h1>
                            <hr className="border-blue-50 xl:mt-2 xl:mr-8"></hr>
                            <p className="text-grey-600 xl:pt-6 pt-2 xl:w-9/12 md:w-9/12 w-9/12 xl:text-base1 md:text-sm banner-font font-medium">
                              Crane Equipment
                            </p>
                          </div>
                          <div className="center-items ml-4">
                            <h1 className=" h1 font-bold xl:text-5xl md:text-4xl text-white text-3xl xl:pt-0 md:pt-0 pt-4">
                              {count3}+<p></p>
                            </h1>
                            <hr className="border-blue-50 xl:mt-2 xl:mr-8"></hr>
                            <p className="text-grey-600 xl:pt-6 pt-2 xl:text-base1 md:text-sm xl:w-9/12 md:w-9/12 w-9/12 banner-font font-medium">
                              Metal Factory
                            </p>
                          </div>

                          <div className="center-items ml-4">
                            <h1 className=" h1 font-bold xl:text-5xl md:text-4xl text-white text-3xl xl:pt-0 md:pt-0 pt-4">
                              {count4}+<p></p>
                            </h1>
                            <hr className="border-blue-50 xl:mt-2 xl:mr-8"></hr>
                            <p className="text-grey-600 xl:pt-6 pt-2 xl:text-base1 md:text-sm xl:w-9/12 md:w-9/12 w-9/12 banner-font font-medium">
                              Special Machinery
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden grotesk container mx-auto xl:px-4">
        <div className="xl:-mt-32">
          <div className="grid grid-cols-2">
            <div>
              <div
                style={{
                  backgroundImage: `url(${aboutbackground2})`,

                  width: "100%",
                  height: "300px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="xl:pt-12 xl:ml-6">
                  <p class="xl:text-base font-semibold text-white ">
                    START YOUR PROJECT
                  </p>
                  <h1 className="xl:text-4xl font-bold text-white xl:pt-4">
                    Metallurgical products<br></br>
                    of the highest class
                  </h1>
                  <a href="/">
                    <button className="xl:text-lg text-darkblack xl:px-8 xl:py-2 rounded-md xl:mt-5 bg-newshade32">
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="xl:ml-2">
              <div>
                <img class="xl:w-12/12" src={industrial9} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk container mx-auto xl:px-4">
        <div className="grid grid-cols-2">
          <div>
            <div
              style={{
                backgroundImage: `url(${aboutbackground3})`,
                width: "100%",
                height: "600px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>

          <div className=" bg-black xl:p-12">
            <p className="xl:text-base font-bold text-yellow pt-12">
              ACHIEVEMENT
            </p>
            <h1 className="xl:text-4xll text-white font-bold  xl:pt-6">
              Company Achievement
            </h1>

            <div>
              <div className="App">
                <Profile />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:mt-20 grotesk container mx-auto xl:px-4 xl:pb-16">
        <div className="grid grid-cols-2">
          <div>
            <div className="xl:pt-16">
              <p class="xl:text-base text-yellow font-semibold ">COMPANY</p>
              <h1 class="xl:text-4xl font-bold text-darkblack">
                Our client and<br></br> partners
              </h1>
              <p class="xl:text-base text-grey-600 xl:pt-4">
                Engines prime movers and exhaust gas<br></br> turbochargers.
              </p>
            </div>
            <div>
              <img class="xl:w-9/12 -mt-8" src={industrial3} />
            </div>
          </div>

          <div>
            <div className="xl:-ml-36">
              <div className="grid grid-cols-4">
                <div className="bg-newshade33 xl:p-12">
                  <div className="center-button image-container">
                    <img class="xl:w-24 " src={logo11} />
                  </div>
                </div>

                <div className="bg-newshade33 xl:p-12 xl:ml-2">
                  <div className="center-button image-container">
                    <img class="xl:w-20 " src={logo12} />
                  </div>
                </div>

                <div className="bg-newshade33 xl:p-12 xl:ml-2">
                  <div className="center-button image-container">
                    <img class="xl:w-24 " src={logo13} />
                  </div>
                </div>

                <div className="bg-newshade33 xl:p-12 xl:ml-2">
                  <div className="center-button image-container">
                    <img class="xl:w-32 xl:pt-2 " src={logo14} />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-4 xl:mt-4 xl:-ml-36">
              <div className="bg-newshade33 xl:p-12 ">
                <div className="center-button image-container">
                  <img class="xl:w-24 " src={logo13} />
                </div>
              </div>

              <div className="bg-newshade33 xl:p-12 xl:ml-2">
                <div className="center-button image-container">
                  <img class="xl:w-32 xl:pt-2 " src={logo14} />
                </div>
              </div>

              <div className="bg-newshade33 xl:p-12 xl:ml-2">
                <div className="center-button image-container">
                  <img class="xl:w-24 " src={logo15} />
                </div>
              </div>

              <div className="bg-newshade33 xl:p-12 xl:ml-2">
                <div className="center-button image-container">
                  <img class="xl:w-32 xl:pt-6 xl:ml-4 " src={logo16} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden grotesk bg-newshade33 xl:pb-16">
        <div className="">
          <p class="xl:text-base text-yellow font-semibold xl:pt-24 text-center">
            NEWS & BLOG
          </p>
          <h1 class="xl:text-4xl font-bold text-darkblack xl:pt-4 text-center">
            Discover the future of factories<br></br> and industries
          </h1>
        </div>
      </section>

      <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:pb-16 grotesk">
        <div className="xl:mt-12 grid grid-cols-3 container mx-auto xl:px-4">
          <div className="bg-newshade33 xl:w-11/12">
            <div className="image-container">
              <img class="xl:w-12/12 " src={aboutimg7} />
            </div>
            <div className="xl:-mt-4  absolute z-50 xl:ml-8">
              <div className="bg-newshade34 p-2 xl:w-12/12 rounded-md ">
                <p className="xl:text-sm text-black text-center ">Company</p>
              </div>
            </div>
            <div className="xl:p-6 article">
              <h1 className="xl:text-base1 font-bold text-darkblack pt-6 heading highlight-hover">
                {" "}
                Construction of a new high tech plant in washingtons
              </h1>
              <div className="flex xl:mt-4">
                <p class="xl:text-base text-grey-600">By</p>
                <p class="xl:text-base text-grey-600 font-bold xl:ml-2">
                  Istaik
                </p>
                <p class="xl:text-2xl -mt-2 font-bold text-grey-600 xl:ml-4">
                  .
                </p>
                <p class="xl:text-base text-grey-600 xl:ml-4">Jan 3,2024</p>
              </div>
              <hr className="border-blue-50 xl:mt-8"></hr>
              <div className="flex justify-between xl:mt-5">
                <p className="xl:text-base text-grey-600 ">22 min read</p>
                <div className="h-10 w-10 arrow-symbol rounded-md">
                  <p class="xl:text-base1 font-bold text-darkblack text-center pt-1 ">
                    ➜
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-newshade33 xl:w-11/12 ">
            <div className="image-container">
              <img class="xl:w-12/12  " src={aboutimg7} />
            </div>
            <div className="xl:-mt-4  absolute z-50 xl:ml-8">
              <div className="bg-newshade34 p-2 xl:w-12/12 rounded-md ">
                <p className="xl:text-sm text-black text-center ">Company</p>
              </div>
            </div>
            <div className="xl:p-8 article">
              <h1 className="xl:text-base1 font-bold text-darkblack pt-6 heading highlight-hover">
                {" "}
                Construction of a new high tech plant in washingtons
              </h1>
              <div className="flex xl:mt-4">
                <p class="xl:text-base text-grey-600">By</p>
                <p class="xl:text-base text-grey-600 font-bold xl:ml-2">
                  Istaik
                </p>
                <p class="xl:text-2xl -mt-2 font-bold text-grey-600 xl:ml-4">
                  .
                </p>
                <p class="xl:text-base text-grey-600 xl:ml-4">Jan 3,2024</p>
              </div>
              <hr className="border-blue-50 xl:mt-8"></hr>
              <div className="flex justify-between xl:mt-5">
                <p className="xl:text-base text-grey-600 ">22 min read</p>
                <div className="h-10 w-10 arrow-symbol rounded-md">
                  <p class="xl:text-base1 font-bold text-darkblack text-center pt-1 ">
                    ➜
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-newshade33 xl:w-11/12">
            <div className="image-container">
              <img class="xl:w-12/12 " src={aboutimg7} />
            </div>
            <div className="xl:-mt-4  absolute z-50 xl:ml-8">
              <div className="bg-newshade34 p-2 xl:w-12/12 rounded-md ">
                <p className="xl:text-sm text-black text-center ">Company</p>
              </div>
            </div>
            <div className="xl:p-6 article">
              <h1 className="xl:text-base1 font-bold text-darkblack pt-6 heading highlight-hover">
                {" "}
                Construction of a new high tech plant in washingtons
              </h1>
              <div className="flex xl:mt-4">
                <p class="xl:text-base text-grey-600">By</p>
                <p class="xl:text-base text-grey-600 font-bold xl:ml-2">
                  Istaik
                </p>
                <p class="xl:text-2xl -mt-2 font-bold text-grey-600 xl:ml-4">
                  .
                </p>
                <p class="xl:text-base text-grey-600 xl:ml-4">Jan 3,2024</p>
              </div>
              <hr className="border-blue-50 xl:mt-8"></hr>
              <div className="flex justify-between xl:mt-5">
                <p className="xl:text-base text-grey-600 ">22 min read</p>
                <div className="h-10 w-10 arrow-symbol rounded-md">
                  <p class="xl:text-base1 font-bold text-darkblack text-center pt-1 ">
                    ➜
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*landscapeview*/}
      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-12 grotesk container mx-auto px-8 pb-32">
        <div>
          <p className="text-base font-semibold text-center text-yellow">
            ABOUT INDUSTRIE
          </p>
          <h1 className="text-4xl font-bold text-darkblack pt-2 text-center">
            Welcome to Industrie, a leading<br></br> industry innovator with a
            rich<br></br> history of excellence.
          </h1>
        </div>

        <div className="grid grid-cols-4 mt-8">
          <div>
            <div>
              <img className="w-12/12" src={aboutimg1} />
            </div>
          </div>

          <div>
            <div className="ml-6 ">
              <img className="w-8/12" src={abouticon1} />
            </div>
          </div>

          <div>
            <div>
              <img className="w-12/12" src={aboutimg2} />
            </div>
          </div>

          <div className="ml-6 ">
            <div>
              <img className="w-8/12" src={abouticon2} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 px-20 ">
          <div>
            <div className="rounded-md p-6 bg-newshade33 custom-border-bottom  -mt-12 absolute">
              <p className="text-darkblack text-lg font-bold">Our Mission</p>
              <p className="text-grey-600 text-base  pt-4">
                Welcome to Industrie, a leading industry<br></br> innovator with
                a rich history of excellence.<br></br> With a passion for
                precision.
              </p>
            </div>
          </div>

          <div className="ml-24">
            <div className="rounded-md p-6 bg-newshade33 custom-border-bottom  -mt-12 absolute">
              <p className="text-darkblack text-lg font-bold">Our Vision</p>
              <p className="text-grey-600 xl:text-base  pt-4">
                We have been empowering industries and<br></br> driving progress
                for over 30 years. Our diverse<br></br>team of experts brings
                together.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden grotesk container mx-auto px-8 bg-newshade33 mt-16">
        <div>
          <div
            style={{
              backgroundImage: `url(${aboutbackground1})`,
              width: "100%",
              height: "650px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="px-8 pt-16">
              <div>
                <p className="text-base font-bold text-yellow text-center">
                  OUR HISTORY
                </p>
                <h1 className="text-4xl text-darkblack font-bold text-center pt-4">
                  Company Journey
                </h1>
                <div className="px-16 mt-8">
                  <div className="bg-gray-100 p-2 min-h-screen">
                    <div className="bg-white p-2 rounded shadow-md">
                      <div className="grid grid-cols-5 gap-4">
                        {tabs.map((tab) => (
                          <button
                            key={tab}
                            className={`p-2 text-center rounded ${
                              activeTab === tab
                                ? "bg-blue-500 text-white"
                                : "bg-gray-200 text-gray-800 text-lg"
                            }`}
                            onClick={() => setActiveTab(tab)}
                          >
                            {tab}
                            {activeTab === tab && (
                              <span className="relative  text-lg -mt-6 text-yellow  flex items-center justify-center">
                                {tab}
                              </span>
                            )}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="mt-4 p-4 border rounded bg-white">
                      {activeTab === "1990" && (
                        <div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <img
                                className="w-12/12"
                                src={aboutimg3}
                                alt="1990"
                              />
                            </div>
                            <div>
                              <h1 className="text-base1 font-semibold text-darkblack pt-2">
                                Journey Was Started
                              </h1>
                              <p className="text-base1 pt-4">
                                Welcome to Industrie, a leading industry
                                innovator with a rich history of excellence.
                                With a passion for precision and a commitment to
                                quality, we have beenempowering industries and
                                driving progress.
                              </p>
                              <div className="flex">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-3">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-4">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2000" && (
                        <div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <img
                                className="xl:w-11/12"
                                src={aboutimg1}
                                alt="2000"
                              />
                            </div>
                            <div>
                              <h1 className="text-base1 font-semibold text-darkblack pt-2">
                                Journey Was Started
                              </h1>
                              <p className="text-base1 pt-4">
                                Welcome to Industrie, a leading industry
                                innovator with a rich history of excellence.
                                With a passion for precision and a commitment to
                                quality, we have beenempowering industries and
                                driving progress.
                              </p>
                              <div className="flex">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-3">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-4">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2012" && (
                        <div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <img
                                className="xl:w-11/12"
                                src={aboutimg2}
                                alt="2012"
                              />
                            </div>
                            <div>
                              <h1 className="text-base1 font-semibold text-darkblack pt-2">
                                Journey Was Started
                              </h1>
                              <p className="text-base1 pt-4">
                                Welcome to Industrie, a leading industry
                                innovator with a rich history of excellence.
                                With a passion for precision and a commitment to
                                quality, we have beenempowering industries and
                                driving progress.
                              </p>
                              <div className="flex">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-3">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-4">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2020" && (
                        <div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <img
                                className="xl:w-11/12"
                                src={aboutimg3}
                                alt="2020"
                              />
                            </div>
                            <div>
                              <h1 className="text-base1 font-semibold text-darkblack pt-2">
                                Journey Was Started
                              </h1>
                              <p className="text-base1 pt-4">
                                Welcome to Industrie, a leading industry
                                innovator with a rich history of excellence.
                                With a passion for precision and a commitment to
                                quality, we have beenempowering industries and
                                driving progress.
                              </p>
                              <div className="flex">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-3">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-4">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2024" && (
                        <div>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <img
                                className="xl:w-11/12"
                                src={aboutimg1}
                                alt="2024"
                              />
                            </div>
                            <div>
                              <h1 className="text-base1 font-semibold text-darkblack pt-2">
                                Journey Was Started
                              </h1>
                              <p className="text-base1 pt-4">
                                Welcome to Industrie, a leading industry
                                innovator with a rich history of excellence.
                                With a passion for precision and a commitment to
                                quality, we have beenempowering industries and
                                driving progress.
                              </p>
                              <div className="flex">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-3">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-4">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 grotesk container mx-auto px-8 pb-16">
        <p className="text-base font-semibold text-yellow ">ENGINEER</p>
        <div className="flex justify-between mt-4">
          <h1 className="text-4xl font-bold text-darkblack">
            The Best industry experts
          </h1>
          <a href="/">
            <button className="text-base1 text-black bg-newshade32 rounded-md px-8 py-2">
              Become a Member ➜
            </button>
          </a>
        </div>

        <div className="grid grid-cols-3 mt-12 grotesk">
          <div className="parent-hover">
            <div className=" ">
              <img className="w-10/12 hover-image" src={aboutimg4} />
            </div>
            <div className=" ml-7">
              <div className="bg-white  -mt-12 absolute px-12 shadow-xl hover-border pb-4 ">
                <h1 className="text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Penelopa Miller
                </h1>
                <p className="text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="parent-hover">
            <div className=" ">
              <img className="w-10/12 hover-image" src={aboutimg5} />
            </div>
            <div className=" ml-10">
              <div className="bg-white  -mt-12 absolute px-12 shadow-xl hover-border pb-4 ">
                <h1 className="text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Peter Hase
                </h1>
                <p className="text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="parent-hover">
            <div className=" ">
              <img className="w-10/12 hover-image" src={aboutimg6} />
            </div>
            <div className=" ml-7">
              <div className="bg-white  -mt-12 absolute px-12 shadow-xl hover-border pb-4 ">
                <h1 className="text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Lawrence Peter
                </h1>
                <p className="text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-16 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${aboutbackground4})`,

              width: "100%",
              height: "550px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-16 container mx-auto px-4 ">
              <div>
                <p className="text-base font-bold text-yellow text-center">
                  ACHIEVEMENT
                </p>
                <h1 className="text-4xl text-darkblack font-bold text-center pt-4">
                  Company Achievement
                </h1>

                <div className="">
                  <div className="container relative mx-auto overflow-hidden hometext   px-8">
                    <div className="">
                      <div
                        ref={sectionRef}
                        className="grid grid-cols-4 mt-20 px-12 mt-8 text-animate"
                      >
                        <div className="center-items">
                          <h1 className=" h1 font-bold text-4xl  text-white  ">
                            {count1}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-10 mr-8"></hr>
                          <p className="text-grey-600 pt-6 w-9/12 text-base1  banner-font font-medium">
                            Drilling Fields
                          </p>
                        </div>
                        <div className="center-items ml-4">
                          <h1 className="  h1 font-bold text-4xl text-white ">
                            {count2}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-10 mr-8"></hr>
                          <p className="text-grey-600 pt-6  w-9/12 text-base1  banner-font font-medium">
                            Crane Equipment
                          </p>
                        </div>
                        <div className="center-items ml-4">
                          <h1 className=" h1 font-bold text-4xl text-white">
                            {count3}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-10 mr-8"></hr>
                          <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                            Metal Factory
                          </p>
                        </div>

                        <div className="center-items ml-4">
                          <h1 className=" h1 font-bold text-4xl text-white ">
                            {count4}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-10 mr-8"></hr>
                          <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                            Special Machinery
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden grotesk container mx-auto px-8">
        <div className="-mt-24">
          <div className="grid grid-cols-2">
            <div>
              <div
                style={{
                  backgroundImage: `url(${aboutbackground2})`,

                  width: "100%",
                  height: "230px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-12 ml-6">
                  <p class="text-base font-semibold text-white ">
                    START YOUR PROJECT
                  </p>
                  <h1 className="text-4xl font-bold text-white pt-4">
                    Metallurgical products<br></br>
                    of the highest class
                  </h1>
                  <a href="/">
                    <button className="text-lg text-darkblack px-8 py-2 rounded-md mt-5 bg-newshade32">
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="ml-2">
              <div>
                <img class="w-12/12" src={industrial9} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 grotesk container mx-auto px-8">
        <div className="grid grid-cols-2">
          <div>
            <div
              style={{
                backgroundImage: `url(${aboutbackground3})`,
                width: "100%",
                height: "500px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>

          <div className=" bg-black p-8">
            <p className="text-base font-bold text-yellow pt-8">ACHIEVEMENT</p>
            <h1 className="text-3xl text-white font-bold  pt-6">
              Company Achievement
            </h1>

            <div>
              <div className="App">
                <Profile />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden mt-20 grotesk container mx-auto px-8 pb-8">
        <div className="grid grid-cols-2">
          <div>
            <div className="pt-8">
              <p class="text-base text-yellow font-semibold ">COMPANY</p>
              <h1 class="text-4xl font-bold text-darkblack">
                Our client and<br></br> partners
              </h1>
              <p class="text-base text-grey-600 pt-4">
                Engines prime movers and exhaust gas<br></br> turbochargers.
              </p>
            </div>
            <div>
              <img class="w-8/12 -mt-4" src={industrial3} />
            </div>
          </div>

          <div>
            <div className="-ml-32">
              <div className="grid grid-cols-4">
                <div className="bg-newshade33 p-12">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo11} />
                  </div>
                </div>

                <div className="bg-newshade33 p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-20 " src={logo12} />
                  </div>
                </div>

                <div className="bg-newshade33 p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo13} />
                  </div>
                </div>

                <div className="bg-newshade33 p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-32 pt-2 " src={logo14} />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-4 mt-4 -ml-32">
              <div className="bg-newshade33 p-12 ">
                <div className="center-button image-container">
                  <img class="w-24 " src={logo13} />
                </div>
              </div>

              <div className="bg-newshade33 p-12 ml-2">
                <div className="center-button image-container">
                  <img class="w-32 pt-2 " src={logo14} />
                </div>
              </div>

              <div className="bg-newshade33 p-12 ml-2">
                <div className="center-button image-container">
                  <img class="w-24 " src={logo15} />
                </div>
              </div>

              <div className="bg-newshade33 p-12 ml-2">
                <div className="center-button image-container">
                  <img class="w-32 pt-6 ml-4 " src={logo16} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden grotesk bg-newshade33 pb-16">
        <div className="">
          <p class="text-base text-yellow font-semibold pt-16 text-center">
            NEWS & BLOG
          </p>
          <h1 class="text-4xl font-bold text-darkblack pt-4 text-center">
            Discover the future of factories<br></br> and industries
          </h1>
        </div>
      </section>

      <section className="xl:hidden lg:block md:hidden sm:hidden hidden pb-16 grotesk">
        <div className="mt-12 grid grid-cols-3 container mx-auto px-8">
          <div className="bg-newshade33 w-10/12">
            <div className="image-container">
              <img class="w-12/12 " src={aboutimg7} />
            </div>
            <div className="-mt-4  absolute z-50 ml-8">
              <div className="bg-newshade34 p-2 w-12/12 rounded-md ">
                <p className="text-sm text-black text-center ">Company</p>
              </div>
            </div>
            <div className="p-6 article">
              <h1 className="text-base font-bold text-darkblack pt-6 heading highlight-hover">
                {" "}
                Construction of a new high tech plant in washingtons
              </h1>
              <div className="flex mt-4">
                <p class="text-base text-grey-600">By</p>
                <p class="text-base text-grey-600 font-bold ml-2">Istaik</p>
                <p class="text-2xl -mt-2 font-bold text-grey-600 ml-4">.</p>
                <p class="text-base text-grey-600 ml-4">Jan 3,2024</p>
              </div>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-5">
                <p className="text-base text-grey-600 ">22 min read</p>
                <div className="h-10 w-10 arrow-symbol rounded-md">
                  <p class="text-base1 font-bold text-darkblack text-center pt-2 ">
                    ➜
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-newshade33 w-10/12 ">
            <div className="image-container">
              <img class="w-12/12  " src={aboutimg7} />
            </div>
            <div className="-mt-4  absolute z-50 ml-8">
              <div className="bg-newshade34 p-2 w-12/12 rounded-md ">
                <p className="text-sm text-black text-center ">Company</p>
              </div>
            </div>
            <div className="p-8 article">
              <h1 className="text-base font-bold text-darkblack pt-6 heading highlight-hover">
                {" "}
                Construction of a new high tech plant in washingtons
              </h1>
              <div className="flex mt-4">
                <p class="text-base text-grey-600">By</p>
                <p class="text-base text-grey-600 font-bold ml-2">Istaik</p>
                <p class="text-2xl -mt-2 font-bold text-grey-600 ml-4">.</p>
                <p class="text-base text-grey-600 ml-4">Jan 3,2024</p>
              </div>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-5">
                <p className="text-base text-grey-600 ">22 min read</p>
                <div className="h-10 w-10 arrow-symbol rounded-md">
                  <p class="text-base1 font-bold text-darkblack text-center pt-2 ">
                    ➜
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-newshade33 w-10/12">
            <div className="image-container">
              <img class="w-12/12 " src={aboutimg7} />
            </div>
            <div className="-mt-4  absolute z-50 ml-8">
              <div className="bg-newshade34 p-2 w-12/12 rounded-md ">
                <p className="text-sm text-black text-center ">Company</p>
              </div>
            </div>
            <div className="p-6 article">
              <h1 className="text-base font-bold text-darkblack pt-6 heading highlight-hover">
                {" "}
                Construction of a new high tech plant in washingtons
              </h1>
              <div className="flex mt-4">
                <p class="text-base text-grey-600">By</p>
                <p class="text-base text-grey-600 font-bold ml-2">Istaik</p>
                <p class="text-2xl -mt-2 font-bold text-grey-600 ml-4">.</p>
                <p class="text-base text-grey-600 ml-4">Jan 3,2024</p>
              </div>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-5">
                <p className="text-base text-grey-600 ">22 min read</p>
                <div className="h-10 w-10 arrow-symbol rounded-md">
                  <p class="text-base1 font-bold text-darkblack text-center pt-2 ">
                    ➜
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*tabview*/}

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-8 pt-36 ">
              <div>
                <h1 className="text-5xl text-white font-bold ">About</h1>
                <div className="flex mt-4">
                  <p className="text-sm font-bold text-white ">
                    Industrie - Industry and Engineering WordPress Theme
                  </p>
                  <p className="text-base1 font-bold text-white ml-2  ">›</p>
                  <p className="text-sm font-bold text-white ml-2 ">About</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-12 grotesk container mx-auto px-8 ">
        <div>
          <p className="text-base font-semibold text-center text-yellow">
            ABOUT INDUSTRIE
          </p>
          <h1 className="text-4xl font-bold text-darkblack pt-2 text-center">
            Welcome to Industrie, a leading<br></br> industry innovator with a
            rich<br></br> history of excellence.
          </h1>
        </div>

        <div className="grid grid-cols-2 mt-12">
          <div>
            <div>
              <img className="w-12/12" src={aboutimg1} />
            </div>
          </div>
          <div>
            <div className="rounded-md p-6 bg-newshade33 custom-border-bottom ml-4 mt-12  ">
              <p className="text-darkblack text-lg font-bold">Our Mission</p>
              <p className="text-grey-600 text-base  pt-4">
                Welcome to Industrie, a leading industry<br></br> innovator with
                a rich history of excellence.<br></br> With a passion for
                precision.
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 mt-8">
          <div className="">
            <div className="rounded-md p-6 bg-newshade33 custom-border-bottom ">
              <p className="text-darkblack text-lg font-bold">Our Vision</p>
              <p className="text-grey-600 xl:text-base  pt-4">
                We have been empowering industries and<br></br> driving progress
                for over 30 years. Our diverse<br></br>team of experts brings
                together.
              </p>
            </div>
          </div>

          <div className="ml-4">
            <div>
              <img className="w-12/12" src={aboutimg2} />
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk container mx-auto px-8 bg-newshade33 mt-16">
        <div>
          <div
            style={{
              backgroundImage: `url(${aboutbackground1})`,
              width: "100%",
              height: "610px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="px-8 pt-12">
              <div>
                <p className="text-base font-bold text-yellow text-center">
                  OUR HISTORY
                </p>
                <h1 className="text-4xl text-darkblack font-bold text-center pt-4">
                  Company Journey
                </h1>
                <div className=" mt-8">
                  <div className="bg-gray-100 p-2 min-h-screen">
                    <div className="bg-white p-2 rounded shadow-md">
                      <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
                        {tabs.map((tab) => (
                          <button
                            key={tab}
                            className={`p-2 text-center rounded ${
                              activeTab === tab
                                ? "bg-blue-500 text-white"
                                : "bg-gray-200 text-gray-800 text-lg"
                            }`}
                            onClick={() => setActiveTab(tab)}
                          >
                            {tab}
                            {activeTab === tab && (
                              <span className="relative text-lg -mt-6 text-yellow flex items-center justify-center">
                                {tab}
                              </span>
                            )}
                          </button>
                        ))}
                      </div>
                    </div>
                    <div className="mt-4 p-4 border rounded bg-white">
                      {activeTab === "1990" && (
                        <div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                              <img
                                className="w-full"
                                src={aboutimg3}
                                alt="1990"
                              />
                            </div>
                            <div>
                              <h1 className="text-base1 font-semibold text-darkblack pt-2">
                                Journey Was Started
                              </h1>
                              <p className="text-base1 pt-4">
                                Welcome to Industrie, a leading industry
                                innovator with a rich history of excellence.
                                With a passion for precision and a commitment to
                                quality, we have beenempowering industries and
                                driving progress.
                              </p>
                              <div className="flex">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-3">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-4">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2000" && (
                        <div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                              <img
                                className="w-full"
                                src={aboutimg1}
                                alt="2000"
                              />
                            </div>
                            <div>
                              <h1 className="text-base1 font-semibold text-darkblack pt-2">
                                Journey Was Started
                              </h1>
                              <p className="text-base1 pt-4">
                                Welcome to Industrie, a leading industry
                                innovator with a rich history of excellence.
                                With a passion for precision and a commitment to
                                quality, we have beenempowering industries and
                                driving progress.
                              </p>
                              <div className="flex">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-3">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-4">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2012" && (
                        <div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                              <img
                                className="w-full"
                                src={aboutimg2}
                                alt="2012"
                              />
                            </div>
                            <div>
                              <h1 className="text-base1 font-semibold text-darkblack pt-2">
                                Journey Was Started
                              </h1>
                              <p className="text-base1 pt-4">
                                Welcome to Industrie, a leading industry
                                innovator with a rich history of excellence.
                                With a passion for precision and a commitment to
                                quality, we have beenempowering industries and
                                driving progress.
                              </p>
                              <div className="flex">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-3">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-4">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2020" && (
                        <div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                              <img
                                className="w-full"
                                src={aboutimg3}
                                alt="2020"
                              />
                            </div>
                            <div>
                              <h1 className="text-base1 font-semibold text-darkblack pt-2">
                                Journey Was Started
                              </h1>
                              <p className="text-base1 pt-4">
                                Welcome to Industrie, a leading industry
                                innovator with a rich history of excellence.
                                With a passion for precision and a commitment to
                                quality, we have beenempowering industries and
                                driving progress.
                              </p>
                              <div className="flex">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-3">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-4">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "2024" && (
                        <div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                              <img
                                className="w-full"
                                src={aboutimg1}
                                alt="2024"
                              />
                            </div>
                            <div>
                              <h1 className="text-base1 font-semibold text-darkblack pt-2">
                                Journey Was Started
                              </h1>
                              <p className="text-base1 pt-4">
                                Welcome to Industrie, a leading industry
                                innovator with a rich history of excellence.
                                With a passion for precision and a commitment to
                                quality, we have beenempowering industries and
                                driving progress.
                              </p>
                              <div className="flex">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-3">
                                  Quality Control System
                                </p>
                              </div>
                              <div className="flex -mt-5">
                                <p className="text-5xl text-yellow">–</p>
                                <p className="text-base ml-4 mt-4">
                                  Building Quality Industrial
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8 pb-16">
        <p className="text-base font-semibold text-yellow ">ENGINEER</p>
        <div className=" mt-4">
          <h1 className="text-4xl font-bold text-darkblack">
            The Best industry experts
          </h1>
          <a href="/">
            <button className="text-base1 text-black bg-newshade32 rounded-md px-8 py-2 mt-4">
              Become a Member ➜
            </button>
          </a>
        </div>

        <div className="grid grid-cols-2 mt-12 grotesk">
          <div className="parent-hover">
            <div className=" ">
              <img className="w-11/12 hover-image" src={aboutimg4} />
            </div>
            <div className=" ml-16">
              <div className="bg-white  -mt-12 absolute px-12 shadow-xl hover-border pb-4 ">
                <h1 className="text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Penelopa Miller
                </h1>
                <p className="text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="parent-hover ml-2">
            <div className=" ">
              <img className="w-11/12 hover-image" src={aboutimg5} />
            </div>
            <div className=" ml-20">
              <div className="bg-white  -mt-12 absolute px-12 shadow-xl hover-border pb-4 ">
                <h1 className="text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Peter Hase
                </h1>
                <p className="text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="parent-hover mt-24">
            <div className=" ">
              <img className="w-10/12 hover-image" src={aboutimg6} />
            </div>
            <div className=" ml-10">
              <div className="bg-white  -mt-12 absolute px-12 shadow-xl hover-border pb-4 ">
                <h1 className="text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Lawrence Peter
                </h1>
                <p className="text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${aboutbackground4})`,

              width: "100%",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-16 container mx-auto px-8 ">
              <div>
                <p className="text-base font-bold text-yellow text-center">
                  ACHIEVEMENT
                </p>
                <h1 className="text-4xl text-darkblack font-bold text-center pt-4">
                  Company Achievement
                </h1>

                <div className="">
                  <div className="container relative mx-auto overflow-hidden hometext   px-8">
                    <div className="">
                      <div
                        ref={sectionRef}
                        className="grid grid-cols-4   mt-12 text-animate"
                      >
                        <div className="center-items">
                          <h1 className=" h2 font-bold text-4xl  text-white  ">
                            {count1}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-6 mr-8"></hr>
                          <p className="text-grey-600 pt-6 w-9/12 text-base1  banner-font font-medium">
                            Drilling Fields
                          </p>
                        </div>
                        <div className="center-items ml-4">
                          <h1 className="  h2 font-bold text-4xl text-white ">
                            {count2}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-6 mr-8"></hr>
                          <p className="text-grey-600 pt-6  w-9/12 text-base1  banner-font font-medium">
                            Crane Equipment
                          </p>
                        </div>
                        <div className="center-items ml-4">
                          <h1 className=" h2 font-bold text-4xl text-white">
                            {count3}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-6 mr-8"></hr>
                          <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                            Metal Factory
                          </p>
                        </div>

                        <div className="center-items ml-4">
                          <h1 className=" h2 font-bold text-4xl text-white ">
                            {count4}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-6 mr-8"></hr>
                          <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                            Special Machinery
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk container mx-auto px-8">
        <div className="">
          <div className="">
            <div>
              <div
                style={{
                  backgroundImage: `url(${aboutbackground2})`,

                  width: "100%",
                  height: "300px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-12 ml-6">
                  <p class="text-base font-semibold text-white ">
                    START YOUR PROJECT
                  </p>
                  <h1 className="text-4xl font-bold text-white pt-4">
                    Metallurgical products<br></br>
                    of the highest class
                  </h1>
                  <a href="/">
                    <button className="text-lg text-darkblack px-8 py-2 rounded-md mt-5 bg-newshade32">
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div>
                <img class="w-12/12" src={industrial9} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8">
        <div className="">
          <div>
            <div
              style={{
                backgroundImage: `url(${aboutbackground3})`,
                width: "100%",
                height: "400px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>

          <div className=" bg-black p-8 mt-6">
            <p className="text-base font-bold text-yellow pt-8">ACHIEVEMENT</p>
            <h1 className="text-3xl text-white font-bold  pt-6">
              Company Achievement
            </h1>

            <div className="xl:ml-0 lg:ml-0 ml-8 xl:mt-0 lg:mt-0 mt-8">
              <div className="App">
                <Profile />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 grotesk container mx-auto px-8 pb-8">
        <div className="">
          <div>
            <div className="pt-8">
              <p class="text-base text-yellow font-semibold text-center ">
                COMPANY
              </p>
              <h1 class="text-4xl font-bold text-darkblack text-center">
                Our client and partners
              </h1>
              <p class="text-base text-grey-600 pt-4 text-center">
                Engines prime movers and exhaust gas turbochargers.
              </p>
            </div>
          </div>

          <div>
            <div className="mt-8">
              <div className="grid grid-cols-4">
                <div className="bg-newshade33 p-12">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo11} />
                  </div>
                </div>

                <div className="bg-newshade33 p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-20 " src={logo12} />
                  </div>
                </div>

                <div className="bg-newshade33 p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo13} />
                  </div>
                </div>

                <div className="bg-newshade33 p-12 ml-2">
                  <div className="center-button image-container">
                    <img class="w-32 pt-2 " src={logo14} />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-4 mt-4">
              <div className="bg-newshade33 p-12 ">
                <div className="center-button image-container">
                  <img class="w-24 " src={logo13} />
                </div>
              </div>

              <div className="bg-newshade33 p-12 ml-2">
                <div className="center-button image-container">
                  <img class="w-32 pt-2 " src={logo14} />
                </div>
              </div>

              <div className="bg-newshade33 p-12 ml-2">
                <div className="center-button image-container">
                  <img class="w-24 " src={logo15} />
                </div>
              </div>

              <div className="bg-newshade33 p-12 ml-2">
                <div className="center-button image-container">
                  <img class="w-32 pt-6 ml-4 " src={logo16} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden grotesk bg-newshade33 pb-16 mt-6">
        <div className="">
          <p class="text-base text-yellow font-semibold pt-16 text-center">
            NEWS & BLOG
          </p>
          <h1 class="text-4xl font-bold text-darkblack pt-4 text-center">
            Discover the future of factories<br></br> and industries
          </h1>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden pb-16 grotesk">
        <div className="mt-12 grid grid-cols-2 container mx-auto px-8">
          <div className="bg-newshade33 w-12/12">
            <div className="image-container">
              <img class="w-12/12 " src={aboutimg7} />
            </div>
            <div className="-mt-4  absolute z-50 ml-8">
              <div className="bg-newshade34 p-2 w-12/12 rounded-md ">
                <p className="text-sm text-black text-center ">Company</p>
              </div>
            </div>
            <div className="p-6 article">
              <h1 className="text-base font-bold text-darkblack pt-6 heading highlight-hover">
                {" "}
                Construction of a new high tech plant in washingtons
              </h1>
              <div className="flex mt-4">
                <p class="text-base text-grey-600">By</p>
                <p class="text-base text-grey-600 font-bold ml-2">Istaik</p>
                <p class="text-2xl -mt-2 font-bold text-grey-600 ml-4">.</p>
                <p class="text-base text-grey-600 ml-4">Jan 3,2024</p>
              </div>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-5">
                <p className="text-base text-grey-600 ">22 min read</p>
                <div className="h-10 w-10 arrow-symbol rounded-md">
                  <p class="text-base1 font-bold text-darkblack text-center pt-2 ">
                    ➜
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-newshade33 w-12/12 ml-2">
            <div className="image-container">
              <img class="w-12/12  " src={aboutimg7} />
            </div>
            <div className="-mt-4  absolute z-50 ml-8">
              <div className="bg-newshade34 p-2 w-12/12 rounded-md ">
                <p className="text-sm text-black text-center ">Company</p>
              </div>
            </div>
            <div className="p-8 article">
              <h1 className="text-base font-bold text-darkblack pt-6 heading highlight-hover">
                {" "}
                Construction of a new high tech plant in washingtons
              </h1>
              <div className="flex mt-4">
                <p class="text-base text-grey-600">By</p>
                <p class="text-base text-grey-600 font-bold ml-2">Istaik</p>
                <p class="text-2xl -mt-2 font-bold text-grey-600 ml-4">.</p>
                <p class="text-base text-grey-600 ml-4">Jan 3,2024</p>
              </div>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-5">
                <p className="text-base text-grey-600 ">22 min read</p>
                <div className="h-10 w-10 arrow-symbol rounded-md">
                  <p class="text-base1 font-bold text-darkblack text-center pt-2 ">
                    ➜
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-newshade33 w-12/12 mt-8">
            <div className="image-container">
              <img class="w-12/12 " src={aboutimg7} />
            </div>
            <div className="-mt-4  absolute z-50 ml-8">
              <div className="bg-newshade34 p-2 w-12/12 rounded-md ">
                <p className="text-sm text-black text-center ">Company</p>
              </div>
            </div>
            <div className="p-6 article">
              <h1 className="text-base font-bold text-darkblack pt-6 heading highlight-hover">
                {" "}
                Construction of a new high tech plant in washingtons
              </h1>
              <div className="flex mt-4">
                <p class="text-base text-grey-600">By</p>
                <p class="text-base text-grey-600 font-bold ml-2">Istaik</p>
                <p class="text-2xl -mt-2 font-bold text-grey-600 ml-4">.</p>
                <p class="text-base text-grey-600 ml-4">Jan 3,2024</p>
              </div>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-5">
                <p className="text-base text-grey-600 ">22 min read</p>
                <div className="h-10 w-10 arrow-symbol rounded-md">
                  <p class="text-base1 font-bold text-darkblack text-center pt-2 ">
                    ➜
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*mobileview*/}
      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk">
        <div>
          <div
            style={{
              backgroundImage: `url(${industrialslider2})`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container mx-auto px-4 pt-24 ">
              <div>
                <h1 className="text-5xl text-white font-bold ">About</h1>
                <div className="flex mt-4">
                  <p className="text-base font-bold text-white ">
                    Industrie - Industry and Engineering
                  </p>
                </div>
                <div className="flex">
                  <p className="text-base font-bold text-white  ">
                    {" "}
                    WordPress Theme
                  </p>
                  <p className="text-base1 font-bold text-white ml-2  ">›</p>
                  <p className="text-base font-bold text-white ml-2 ">About</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-12 grotesk container mx-auto px-4 ">
        <div>
          <p className="text-base font-semibold text-center text-yellow">
            ABOUT INDUSTRIE
          </p>
          <h1 className="text-3xl font-bold text-darkblack pt-2 text-center">
            Welcome to Industrie, a leading industry innovator with a rich
            history of excellence.
          </h1>
        </div>

        <div className=" mt-8">
          <div>
            <div>
              <img className="w-12/12" src={aboutimg1} />
            </div>
          </div>
          <div>
            <div className="rounded-md p-6 bg-newshade33 custom-border-bottom mt-4 ">
              <p className="text-darkblack text-lg font-bold">Our Mission</p>
              <p className="text-grey-600 text-base  pt-4">
                Welcome to Industrie, a leading industry<br></br> innovator with
                a rich history of excellence.<br></br> With a passion for
                precision.
              </p>
            </div>
          </div>
        </div>

        <div className="">
          <div className="mt-4">
            <div>
              <img className="w-12/12" src={aboutimg2} />
            </div>
          </div>

          <div className="mt-4">
            <div className="rounded-md p-6 bg-newshade33 custom-border-bottom ">
              <p className="text-darkblack text-lg font-bold">Our Vision</p>
              <p className="text-grey-600 xl:text-base  pt-4">
                We have been empowering industries and<br></br> driving progress
                for over 30 years. Our diverse<br></br>team of experts brings
                together.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk container mx-auto px-4  mt-16 overflow-hidden">
        
            <div className="px-4 pt-12">
              <div>
                <p className="text-base font-bold text-yellow text-center">
                  OUR HISTORY
                </p>
                <h1 className="text-4xl text-darkblack font-bold text-center pt-4">
                  Company Journey
                </h1>

                <div className="mt-8">
                  <div>
                    <div onClick={toggleAccordion} className="cursor-pointer">
                      <div className="bg-newshade33 p-2">
                        <p
                          className={`text-2xl font-bold ml-2 ${
                            isOpen ? "text-yellow-500" : "text-darkblack"
                          }`}
                        >
                          1990
                        </p>
                      </div>
                    </div>
                    {isOpen && (
                      <div className="bg-newshade33 p-6">
                        <div>
                          <img
                            className="w-12/12 mt-8"
                            src={aboutimg1}
                            alt="About"
                          />
                        </div>
                        <h1 className="text-2xl font-bold text-darkblack pt-5">
                          Journey Was Started
                        </h1>
                        <p className="text-base text-grey-600 pt-2">
                          Welcome to Industrie, a leading industry innovator
                          with a rich history of excellence. With a passion for
                          precision and a commitment to quality, we have been
                          empowering industries and driving progress.
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <div onClick={toggleAccordion1} className="cursor-pointer">
                      <div className="bg-newshade33 p-2">
                        <p className="text-2xl font-bold text-darkblack ml-2">
                          2000
                        </p>
                      </div>
                    </div>
                    {isOpen1 && (
                      <div className="bg-newshade33 p-6">
                        <div>
                          <img className="w-12/12 mt-8" src={aboutimg2} />
                        </div>
                        <h1 className="text-2xl font-bold text-darkblack pt-5">
                          Journey Was Started
                        </h1>
                        <p className="text-base text-grey-600 pt-2">
                          Welcome to Industrie, a leading industry innovator
                          with a rich history of excellence. With a passion for
                          precision and a commitment to quality, we have been
                          empowering industries and driving progress.
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <div onClick={toggleAccordion2} className="cursor-pointer">
                      <div className="bg-newshade33 p-2">
                        <p className="text-2xl font-bold text-darkblack ml-2">
                          2012
                        </p>
                      </div>
                    </div>
                    {isOpen2 && (
                      <div className="bg-newshade33 p-6">
                        <div>
                          <img className="w-12/12 mt-8" src={aboutimg2} />
                        </div>
                        <h1 className="text-2xl font-bold text-darkblack pt-5">
                          Journey Was Started
                        </h1>
                        <p className="text-base text-grey-600 pt-2">
                          Welcome to Industrie, a leading industry innovator
                          with a rich history of excellence. With a passion for
                          precision and a commitment to quality, we have been
                          empowering industries and driving progress.
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <div onClick={toggleAccordion3} className="cursor-pointer">
                      <div className="bg-newshade33 p-2">
                        <p className="text-2xl font-bold text-darkblack ml-2">
                          2020
                        </p>
                      </div>
                    </div>
                    {isOpen3 && (
                      <div className="bg-newshade33 p-6">
                        <div>
                          <img className="w-12/12 mt-8" src={aboutimg2} />
                        </div>
                        <h1 className="text-2xl font-bold text-darkblack pt-5">
                          Journey Was Started
                        </h1>
                        <p className="text-base text-grey-600 pt-2">
                          Welcome to Industrie, a leading industry innovator
                          with a rich history of excellence. With a passion for
                          precision and a commitment to quality, we have been
                          empowering industries and driving progress.
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <div onClick={toggleAccordion4} className="cursor-pointer">
                      <div className="bg-newshade33 p-2">
                        <p className="text-2xl font-bold text-darkblack ml-2">
                          2024
                        </p>
                      </div>
                    </div>
                    {isOpen4 && (
                      <div className="bg-newshade33 p-6">
                        <div>
                          <img className="w-12/12 " src={aboutimg2} />
                        </div>
                        <h1 className="text-2xl font-bold text-darkblack pt-5">
                          Journey Was Started
                        </h1>
                        <p className="text-base text-grey-600 pt-2">
                          Welcome to Industrie, a leading industry innovator
                          with a rich history of excellence. With a passion for
                          precision and a commitment to quality, we have been
                          empowering industries and driving progress.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk container mx-auto px-4 pb-16">
        <p className="text-base font-semibold text-yellow ">ENGINEER</p>
        <div className=" mt-4">
          <h1 className="text-4xl font-bold text-darkblack">
            The Best industry experts
          </h1>
          <a href="/">
            <button className="text-base1 text-black bg-newshade32 rounded-md px-8 py-2 mt-4">
              Become a Member ➜
            </button>
          </a>
        </div>

        <div className=" mt-8 grotesk">
          <div className="parent-hover">
            <div className=" ">
              <img className="w-11/12 hover-image" src={aboutimg4} />
            </div>
            <div className=" ml-16">
              <div className="bg-white  -mt-12 absolute px-12 shadow-xl hover-border pb-4 ">
                <h1 className="text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Penelopa Miller
                </h1>
                <p className="text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="parent-hover mt-24">
            <div className=" ">
              <img className="w-11/12 hover-image" src={aboutimg5} />
            </div>
            <div className=" ml-20">
              <div className="bg-white  -mt-12 absolute px-12 shadow-xl hover-border pb-4 ">
                <h1 className="text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Peter Hase
                </h1>
                <p className="text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="parent-hover mt-20">
            <div className=" ">
              <img className="w-12/12 hover-image" src={aboutimg6} />
            </div>
            <div className=" ml-20">
              <div className="bg-white  -mt-12 absolute px-12 shadow-xl hover-border pb-4 ">
                <h1 className="text-base1 font-semibold text-darkblack text-center yellow-on-hover pt-2 ">
                  Lawrence Peter
                </h1>
                <p className="text-sm text-grey-600  text-center pt-1">
                  Sr. Engineer
                </p>
                <div className="flex justify-center mt-2">
                  <button className=" text-black rounded-md text-1xl">
                    <i className="fab fa-facebook"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                  <button className=" text-black rounded-md text-1xl ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk ">
        <div>
          <div
            style={{
              backgroundImage: `url(${aboutbackground4})`,

              width: "100%",
              height: "850px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="pt-12 container mx-auto px-8 ">
              <div>
                <p className="text-base font-bold text-yellow text-center">
                  ACHIEVEMENT
                </p>
                <h1 className="text-4xl text-darkblack font-bold text-center pt-4">
                  Company Achievement
                </h1>

                <div className="">
                  <div className="container relative mx-auto overflow-hidden hometext ">
                    <div className="">
                      <div ref={sectionRef} className=" mt-12 text-animate">
                        <div className="center-items ml-4">
                          <h1 className=" h2 font-bold text-4xl  text-white  ">
                            {count1}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-6 mr-8"></hr>
                          <p className="text-grey-600 pt-6 w-9/12 text-base1  banner-font font-medium">
                            Drilling Fields
                          </p>
                        </div>
                        <div className="center-items ml-4 mt-10">
                          <h1 className="  h2 font-bold text-4xl text-white ">
                            {count2}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-6 mr-8"></hr>
                          <p className="text-grey-600 pt-6  w-9/12 text-base1  banner-font font-medium">
                            Crane Equipment
                          </p>
                        </div>
                        <div className="center-items ml-4 mt-10">
                          <h1 className=" h2 font-bold text-4xl text-white">
                            {count3}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-6 mr-8"></hr>
                          <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                            Metal Factory
                          </p>
                        </div>

                        <div className="center-items ml-4 mt-10">
                          <h1 className=" h2 font-bold text-4xl text-white ">
                            {count4}+<p></p>
                          </h1>
                          <hr className="border-blue-50 mt-6 mr-8"></hr>
                          <p className="text-grey-600 pt-6 text-base1  w-9/12 banner-font font-medium">
                            Special Machinery
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block grotesk container mx-auto px-4">
        <div className="">
          <div className="">
            <div>
              <div
                style={{
                  backgroundImage: `url(${aboutbackground2})`,

                  width: "100%",
                  height: "320px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="pt-12 ml-6">
                  <p class="text-base font-semibold text-white ">
                    START YOUR PROJECT
                  </p>
                  <h1 className="text-4xl font-bold text-white pt-4">
                    Metallurgical products of the highest class
                  </h1>
                  <a href="/">
                    <button className="text-lg text-darkblack px-8 py-2 rounded-md mt-5 bg-newshade32">
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div>
                <img class="w-12/12" src={industrial9} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk container mx-auto px-4  ">
        <div className=" bg-black p-4 pb-12">
          <p className="text-base font-bold text-yellow pt-8">ACHIEVEMENT</p>
          <h1 className="text-3xl text-white font-bold  pt-4">
            Company Achievement
          </h1>

          <div className="mt-8">
            <div>
              <img class="w-20 rounded-full" src={industrialicon1} />
            </div>
            <h1 className="text-lg text-white font-bold pt-6">BEST COMPANY</h1>
            <p class="text-base text-white font-bold  pt-4  pb-4">
              Podcasting operational change management inside of workflows to
              establish a framework seamless key performance indicators.{" "}
            </p>
            <div className="divider "></div>
            <h1 className="text-lg text-yellow font-semibold pt-4 ">
              BM ASHIK TOREN
            </h1>
            <p className="text-sm text-yellow ">Founder</p>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 grotesk container mx-auto px-4 pb-8">
        <div className="">
          <div>
            <div className="">
              <p class="text-base text-yellow font-semibold text-center ">
                COMPANY
              </p>
              <h1 class="text-4xl font-bold text-darkblack text-center">
                Our client and partners
              </h1>
              <p class="text-base text-grey-600 pt-4 text-center">
                Engines prime movers and exhaust gas turbochargers.
              </p>
            </div>
          </div>

          <div>
            <div className="mt-8">
              <div className="grid grid-cols-2">
                <div className="bg-newshade33 p-12">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo11} />
                  </div>
                </div>

                <div className="bg-newshade33 p-12 ml-4">
                  <div className="center-button image-container">
                    <img class="w-20 " src={logo12} />
                  </div>
                </div>
                </div>


                <div className="grid grid-cols-2 mt-4">
                <div className="bg-newshade33 p-12 ">
                  <div className="center-button image-container">
                    <img class="w-24 " src={logo13} />
                  </div>
                </div>

                <div className="bg-newshade33 p-12 ml-4">
                  <div className="center-button image-container">
                    <img class="w-32 pt-2 " src={logo14} />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 mt-4">
              <div className="bg-newshade33 p-12 ">
                <div className="center-button image-container">
                  <img class="w-24 " src={logo13} />
                </div>
              </div>

              <div className="bg-newshade33 p-12 ml-4 ">
                <div className="center-button image-container">
                  <img class="w-32 pt-2 " src={logo14} />
                </div>
              </div>
              </div>

               <div className="grid grid-cols-2 mt-4">
              <div className="bg-newshade33 p-12 ">
                <div className="center-button image-container">
                  <img class="w-24 " src={logo15} />
                </div>
              </div>

              <div className="bg-newshade33 p-12 ml-4">
                <div className="center-button image-container">
                  <img class="w-32 pt-6 ml-4 " src={logo16} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 container mx-auto px-4 pb-16">
      <div className="">
          <p class="text-base text-yellow font-semibold  text-center">
            NEWS & BLOG
          </p>
          <h1 class="text-4xl font-bold text-darkblack pt-4 text-center">
            Discover the future of factories and industries
          </h1>
        </div>

        <div className="bg-newshade33 w-12/12 mt-8">
            <div className="image-container">
              <img class="w-12/12  " src={aboutimg7} />
            </div>
            <div className="-mt-4  absolute z-50 ml-8">
              <div className="bg-newshade34 p-2 w-12/12 rounded-md ">
                <p className="text-sm text-black text-center ">Company</p>
              </div>
            </div>
            <div className="p-8 article">
              <h1 className="text-base font-bold text-darkblack pt-6 heading highlight-hover">
                {" "}
                Construction of a new high tech plant in washingtons
              </h1>
              <div className="flex mt-4">
                <p class="text-base text-grey-600">By</p>
                <p class="text-base text-grey-600 font-bold ml-2">Istaik</p>
                <p class="text-2xl -mt-2 font-bold text-grey-600 ml-4">.</p>
                <p class="text-base text-grey-600 ml-4">Jan 3,2024</p>
              </div>
              <hr className="border-blue-50 mt-8"></hr>
              <div className="flex justify-between mt-5">
                <p className="text-base text-grey-600 ">22 min read</p>
                <div className="h-10 w-10 arrow-symbol rounded-md">
                  <p class="text-base1 font-bold text-darkblack text-center pt-2 ">
                    ➜
                  </p>
                </div>
              </div>
            </div>
          </div>
      </section>


      {/*endd*/}
      <Footer2 />
    </section>
  );
}
