import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import background14 from "assets/img/background14.png";
import industrialslider1 from "assets/img/industrialslider1.png";
import industrialslider2 from "assets/img/industrialslider2.png";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-red-500 cursor-pointer`}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* <span className="xl:pl-24 ">{"<"}</span> */}
      {/* ﹤ */}
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-blue-500 cursor-pointer`}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      ﹥
    </div>
  );
};

const Cloudslider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    afterChange: (current) => setCurrentSlide(current), // Track the current slide
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const slides = [
    { backgroundImage: `url(${background14})`, text: "Gas" },
    { backgroundImage: `url(${industrialslider1})`, text: "Oil" },
    { backgroundImage: `url(${industrialslider2})`, text: "Gas" },
  ];

  return (
    <section className="grotesk">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <div
              style={{
                backgroundImage: slide.backgroundImage,
                width: "100%",
                height: "700px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="container mx-auto xl:px-4 lg:px-16 md:px-12 px-8">
                <div className="flex xl:pt-32 lg:pt-48 md:pt-56 pt-36">
                  <hr className="border-blue-51 w-20"></hr>
                  <p className="xl:text-base font-bold text-white xl:ml-8 xl:-mt-3 lg:ml-2 lg:-mt-3 -mt-3 ml-2">
                    {index + 1}.
                  </p>
                  <p className="xl:text-base font-bold text-white xl:ml-2 xl:-mt-3 lg:ml-2 lg:-mt-3 ml-2 -mt-3">
                    {slide.text}
                  </p>
                </div>

                <div className={`${currentSlide === index ? "fade-in-up" : ""}`}>
                  <h1 className="xl:text-7xl font-bold text-white xl:mt-12 lg:text-4xl lg:mt-12 text-5xl mt-8">
                    WE'RE IN BUSINESS<br /> TO MAKE INDUSTRIAL
                  </h1>
                  <h1 className="xl:text-7xl font-bold text-yellow xl:mt-4 lg:text-4xl lg:mt-4 text-4xl mt-4">GAS FACTORY</h1>
                  <p className="xl:text-base font-semibold text-white xl:mt-6 text-white lg:text-lg lg:mt-4 text-base mt-2">
                    At the heart of the global landscape, the industry stands
                  </p>
                  <p className="xl:text-base font-semibold text-white xl:pt-1 text-white lg:text-lg pt-1 text-base">
                    multidimensional force of progress.
                  </p>
                  <button className="xl:text-base1 bg-newshade32 text-white xl:px-8 xl:py-2 rounded-md xl:mt-8 lg:text-base1 lg:px-8 lg:py-2 lg:mt-4 px-8 py-2 xl:mt-0 lg:mt-0 mt-2">
                    Discover More ➜
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default Cloudslider;
